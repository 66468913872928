import React, { memo, useEffect, useRef, useState } from 'react'
import UserName from '@/pages/comps/userName'
import { useDispatch, useSelector } from 'react-redux'
import VerifyCode from '@/pages/comps/verifyCode'
import UserPassword from '@/pages/comps/userPassword'
import AccountName from '@/pages/comps/account'
import LoginBtn from '@/pages/comps/loginBtn'
import ErrorTip from '@/pages/comps/errorTip'
import rlogoPng from '@/assets/img/login/rlogo.png'
import { getUserByApi, updateLoadImage } from '@/apis/user/index'
import { setUserInfo } from '@/store/actions/user'
import './style.css'
import useVerifyUserSetting from '@/hooks/verify/useVerifyUserSetting'
import useRouter from '@/hooks/router/useRouter'
import { updateUserInfoByApi } from '@/apis/user/index'
import { useSelectFile } from '@/hooks/file/useSelectFile'

import ChatUI, { Avatar } from '@chatui/core'

//eslint-disable-next-line max-lines-per-function
const UserSetting = () => {
  const dispatch = useDispatch()
  const {
    onChangeAvatar,
    onChangeNickName,
    onChangeCurrPassword,
    onChangePassword,
    onChangePasswordConfirm,
    inputDataRef,
    avatar,
    username,
    nickname,
    errTip,
    verifyInputOk,
    setUserFromApi,
    setAvatar,
  } = useVerifyUserSetting()
  const { history, query, historyPush } = useRouter()
  const { userInfo } = useSelector((state: any) => state.user)
  // const avatarImg = userInfo?.user?.avatar || 'http://ivh.hr-soft.cn/api/static/backend_avatar.png'
  // console.log(userInfo)
  // const { username, nickname: orignNickName } = userInfo?.user || {}
  const { File, onOpen: onOpenSelectFile } = useSelectFile({
    fileType: '.jpg,.png',
    multiple: false,
  })

  const setUserInfoToState = (data) => {
    const _data = data || {
      username: '',
      nickname: '',
      avatar: 'http://ivh.hr-soft.cn/api/static/backend_avatar.png',
    }
    setUserFromApi(_data)
  }

  useEffect(() => {
    setUserInfoToState(userInfo?.user)
  }, [userInfo?.user])

  const getUserByForPublicOut = async () => {
    const res = await getUserByApi()
    dispatch(setUserInfo(res.data))
  }

  useEffect(() => {
    getUserByForPublicOut()
  }, [])

  // api/system/admin/upInfo
  const toSettingUser = async () => {
    if (!verifyInputOk()) {
      return
    }

    await updateUserInfoByApi(inputDataRef.current)

    goLogin()
  }

  const goLogin = () => {
    const from = query.from || '/app-list'

    //redgpts
    if (location.href.indexOf('redgpts') > -1) {
      location.href = from
    } else {
      historyPush(from, {})
    }
  }

  const onSelectFile = async (e: File[]) => {
    const file = e[0]
    if (!file) return
    try {
      const res = await updateLoadImage(file)
      const path = res.data.path
      setAvatar(path)
      inputDataRef.current.avatar = path
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <div className="chatgpt-login redgpt-setting-user">
      <div className="chatgpt-login-top">
        <div className="chatgpt-title-nav">
          <img src={rlogoPng} alt="" />
          <span>红海云AI平台</span>
        </div>
      </div>
      <div className="chatgpt-login-main">
        <div className="chatgpt-login-welcome">个人设置</div>
        <div className="chatgpt-login-con">
          {/* <div className={'register-top'}>{getPageTitle()}</div> */}
          <div className="avatar-item">
            <div>头像</div>
            <div onClick={() => onOpenSelectFile()}>
              <Avatar src={avatar} size="sm"></Avatar>
            </div>
          </div>

          <div className={'mt-20'}>
            <AccountName placeholder="账号" disabled value={username} />
          </div>
          <div className={'mt-20'}>
            <AccountName
              placeholder="昵称"
              onChange={onChangeNickName}
              maxLength={11}
              value={nickname}
            />
          </div>
          <div className={'mt-20'}>
            <UserPassword
              placeholder="当前密码，不修改密码时留空"
              onChange={onChangeCurrPassword}
              maxLength={12}
              autoComplete={false}
            />
          </div>
          <div className={'mt-20'}>
            <UserPassword
              placeholder="新密码，不修改密码时留空"
              onChange={onChangePassword}
              maxLength={12}
            />
          </div>
          <div className={'mt-20'}>
            <UserPassword
              placeholder="确认密码，不修改密码时留空"
              onChange={onChangePasswordConfirm}
              maxLength={12}
            />
          </div>

          <div className="mt-20">
            <LoginBtn onClick={toSettingUser} title={'确定'}></LoginBtn>
          </div>
          <div className="h-30">{errTip ? <ErrorTip errTip={errTip} /> : ''}</div>
        </div>
      </div>
      <File onSelect={onSelectFile} />
    </div>
  )
}
export default UserSetting
