import React, { memo, useEffect, useRef, useState } from 'react'
import rlogoPng from '@/assets/img/login/rlogo.png'
import './style.css'
import useRouter from '@/hooks/router/useRouter'
import { getQRCode, getCodeStatus } from '@/apis/wxlogin/wxlogin'

import { ReloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'

let getCodeStatusTimer = null as any
//eslint-disable-next-line max-lines-per-function
const WxLogin = () => {
  const { history, query, historyPush } = useRouter()

  const [codeUrl, setCodeUrl] = useState('')
  const [codeUrlLoading, setCodeLoading] = useState(false)
  const [statusTips, setStatusTips] = useState('')

  const onGetCode = async (isInit?) => {
    if (codeUrlLoading) return
    setCodeLoading(true)
    const res = await getQRCode()
    setCodeUrl(res.msg)
    if (isInit) {
      getCodeStatusTimer = setInterval(() => {
        onGetStatus()
      }, 1000)
    }
    setCodeLoading(false)
  }

  const onGetStatus = async (isInit?) => {
    const res = await getCodeStatus()
    if (res.data.isOnline) {
      getCodeStatusTimer && clearInterval(getCodeStatusTimer)
      historyPush(`/robotLog`, {})
    } else {
      if (isInit) {
        onGetCode(isInit)
      } else {
        setStatusTips(res.data.msg)
      }
    }
  }

  useEffect(() => {
    onGetStatus('init')
    return () => {
      getCodeStatusTimer && clearInterval(getCodeStatusTimer)
    }
  }, [])

  return (
    <div className="chatgpt-wx-login">
      <div className="chatgpt-login-top">
        <div className="chatgpt-title-nav">
          <img src={rlogoPng} alt="" />
          <span>红海云AI平台</span>
        </div>
      </div>
      <div className="chatgpt-wx-login-main">
        <div className="chatgpt-login-welcome">微信机器人登录</div>
        <div className="chatgpt-login-qrcode">
          <div className="qrcode-title">扫码登录</div>
          <div className="qrcode-img-box">
            <Spin spinning={codeUrlLoading} size="large">
              <img className="qrcode-img" src={codeUrl} />
            </Spin>
          </div>
          <Button
            disabled={codeUrlLoading}
            type="link"
            icon={codeUrlLoading ? <LoadingOutlined /> : <ReloadOutlined />}
            onClick={() => onGetCode()}
          >
            刷新二维码
          </Button>
          {!codeUrlLoading && <div className="chatgpt-login-status-tips">{statusTips}</div>}
        </div>
      </div>
    </div>
  )
}
export default WxLogin
