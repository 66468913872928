import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Button, useMessages, Icon, toast, Modal, RichText } from '@chatui/core'
import SpeechConversion from '@/component/speak/SpeechConversion'
import { tokenizerFn, MAXTOKENS, DEFAULT_MODEL } from '@/utils/chatgpt'
import { GPT4V } from '@/utils/chatgpt'
import StopBtn from './comps/StopBtn'
import useSpeak from '@/hooks/useSpeak'
import useTempl from '@/hooks/useTempl'
import useHighLight from '@/hooks/useHighLight'
import useKeyEvent from '@/hooks/useKeyEvent'
import useGlmSuggestion from '@/hooks/useGlmSuggestion'
import useDelQuestion from '@/hooks/useDelQustion'
import useChatGptFunc from '@/hooks/useChatGptFunc'
import useProxyList from '@/hooks/useProxyList'
import useScrollToBottom from '@/hooks/useScrollToBottom'
import useRenderMsgContent from '@/hooks/useRenderMsgContent'
import useUpdateActiveHistory from '@/hooks/useUpdateActiveHistory'
import useHandSendMsg from './hook/useHandSendMsg'
import InputItem from '@/component/input/InputItem'
import { AI_SOURCE_MAP } from '@/store/actions/other'
import { aiAvatar, redAvatar, initialMessages, paragraphsRegex } from './constants'
import { picContentConvertPostContent } from './uts'
import './Chat.css'
//@ts-ignore
import userLogoSvg from '@/assets/img/user.svg'
// 对话组件
//eslint-disable-next-line max-lines-per-function
function ChatComp(props) {
  const dispatch = useDispatch()

  //  ----------------------------------chat会话 start -----------------------

  // Chat组件自带消息实践
  const { messages, appendMsg, deleteMsg, resetList, updateMsg } = useMessages(initialMessages)
  // 版本信息
  const { version, versionAuth, isThemeLight, sourceLibrary } = useSelector(
    (state: any) => state.other
  )

  const aiSource = sourceLibrary[0]

  const isKnowlogAI = !Object.values(AI_SOURCE_MAP)?.includes(aiSource)

  // 用户信息
  const { userInfo } = useSelector((state: any) => state.user)

  const getUserPhoto = () => {
    if (userInfo?.userPhoto) {
      return {
        avatar: `https://vw.hr-soft.cn/uploadfile${userInfo.userPhoto}`,
      }
    }
    if (userInfo?.user?.avatar) {
      return {
        avatar: userInfo.user.avatar,
      }
    }
    return {
      avatar: userLogoSvg,
    }
  }
  const userPhoto = getUserPhoto()

  // 会话列表
  const { activeHistory, historyList } = useSelector((state: any) => state.histroy)
  // 是否需要创建标题
  const needCreateTitle = useRef(false)
  // 记录AI正在输出的会话前的id，防止切换到新的会话，导致更新错误
  const activeHistoryId = useRef('')

  // 是否显示停止生成按钮
  const [stopBtnShow, setStopBtnShow] = useState(false)

  // 提交给gpt的消息数据
  const [queryContext, setQueryContext] = useState([] as any)

  // 设置问题
  const [question, setQuestion] = useState('')

  //语音
  const {
    paragraphs,
    greetings,
    isMobi,
    isSpeak,
    audioRef,
    playAudio,
    setHasShowSpeakModel,
    initAudioData,
    setParagraphs,
    initAudio,
    renderSpeakModal,
    renderSpeakBtn,
  } = useSpeak({
    stopBtnShow,
    paragraphsRegex,
    activeHistory,
    handleSend,
  })

  //代理
  const { renderProxyList, proxy, setProxy, getActiveProxy } = useProxyList({
    stopBtnShow,
  })

  //更新会话
  const { updateActiveHistory, updateHistory } = useUpdateActiveHistory({
    activeHistoryId,
    proxy,
  })

  //聊天会话方法抽离
  const {
    querySendByStream,
    loading,
    setLoading,
    setQuestionLink,
    controller,
    createChatTitle,
    getQuestionReprompt,
  } = useChatGptFunc({
    queryContext,
    question,
    messages,
    historyList,
    activeHistoryId,
    proxy,
    setProxy,
    appendMsg,
    isKnowlogAI,
    setQueryContext,
    setParagraphs,
    paragraphs,
    updateMsg,
    needCreateTitle,
    stopBtnShow,
    setStopBtnShow,
  })

  //GLM3模型提示词
  const { renderGLM3Text, getGlmSuggetionLists } = useGlmSuggestion({
    activeHistory,
    messages,
    handleSend,
  })

  useHighLight({ isThemeLight, stopBtnShow, messages })
  useKeyEvent({ question, handleSend, stopBtnShow })
  useScrollToBottom()

  //模板新增，删除
  const { inputRef, onInputKeyDown, showTemplateDialog, onInputChange, renderTemplComps } =
    useTempl({
      setQuestion,
      handleSend,
    })

  //删除问题
  const { renderDelModal } = useDelQuestion({ deleteMsg, messages, activeHistory, updateHistory })

  //渲染消息体
  const { renderMessageContent } = useRenderMsgContent({
    renderGLM3Text,
    renderDelModal,
    showTemplateDialog,
    greetings,
    getActiveProxy,
    playAudio,
    reSend,
  })

  const { verifyToken, getQueryContext } = useHandSendMsg({})

  const isKnowlogeQuestion = (type) => {
    return isKnowlogAI && type != 'model_msg'
  }

  const handleKnowlogeQuestion = async (trimValue) => {
    let _trimValue = trimValue
    let answer = ''
    try {
      const res: any = await getQuestionReprompt(trimValue)
      // 需要调大模型
      if (res.data.action == 1 || res.data.action == 3) {
        _trimValue = res.data.rePrompt
        setQuestionLink(res.data.articleList)
      } else {
        // 不需要调大模型，直接返回答案
        answer = res.data.answer
      }
    } catch (err) {
      console.log(err)
    }

    return {
      _trimValue,
      _answer: answer,
    }
  }

  const getDefautltSendMsg = (type, needAudio, trimValue) => {
    const _selfMsg: any = {
      type: type || 'self_msg',
      needAudio: !!needAudio,
      content: { text: trimValue },
      sourceLibrary: sourceLibrary,
      modelVersion: version,
    }
    return _selfMsg
  }

  const appMsgsToPage = (noAppendMsg, type, _selfMsg) => {
    // noAppendMsg为false并且消息类型不是心流模式时才插入问题显示在页面上
    if (!noAppendMsg && type != 'model_msg') {
      appendMsg({
        ..._selfMsg,
        class: 'is_self',
        user: userPhoto,
      })
    }
    // 插入loading
    appendMsg({
      type: 'loading',
      user: isKnowlogAI ? redAvatar : aiAvatar,
    })
  }

  const handleKnowlogeResult = (answer, _selfMsg) => {
    setLoading(false)
    appendMsg({
      type: 'html',
      content: { text: answer },
      user: isKnowlogAI ? redAvatar : aiAvatar,
    })
    updateActiveHistory({
      selfMsg: _selfMsg,
      question: answer,
    })
    if (proxy && proxy.agentId != 'chartTool') {
      setProxy(null)
    }
  }

  //eslint-disable-next-line max-lines-per-function
  async function handleSend(opt: any) {
    const { text, noAppendMsg, needAudio, type, picContent } = opt || {}
    const val = text || question
    if (loading) {
      return
    }
    const trimValue = val.trim()
    if (!trimValue && !picContent) {
      return
    }
    // 请求gpt的数据
    const postMsg = {
      role: 'user',
      content: trimValue,
    } as any
    // 计算发送消息的长度
    if (!verifyToken(postMsg)) {
      return
    }

    setLoading(true)
    setQuestionLink([])
    // 保存接口的数据

    const _selfMsg: any = getDefautltSendMsg(type, needAudio, trimValue)

    if (picContent) {
      _selfMsg.picContent = picContent
      if (version == GPT4V) {
        postMsg.content = picContentConvertPostContent(picContent)
      }
    }
    getGlmSuggetionLists(picContent ? picContent : trimValue)
    appMsgsToPage(noAppendMsg, type, _selfMsg)
    setQuestion('')
    initAudioData()
    // 如果是知识库问答则需要整合问题或者直接返回答案
    let answer = ''
    if (isKnowlogeQuestion(type)) {
      const { _trimValue, _answer } = await handleKnowlogeQuestion(trimValue)
      _selfMsg.content.qustion = _trimValue
      postMsg.content = _trimValue
      answer = _answer
    }

    const { _queryContext } = getQueryContext(noAppendMsg, queryContext, postMsg)

    // 知识库问答直接返回答案的时候，不需要调大模型，直接返回答案，并保存数据
    if (isKnowlogeQuestion(type) && answer) {
      handleKnowlogeResult(answer, _selfMsg)
    } else {
      // 调用OpenAI，流式输出

      querySendByStream(_queryContext, _selfMsg)
      if (isKnowlogAI) {
        // 如果是知识库问答，上下文不能用整合的问题，需要重置回用户最初的问题
        _queryContext[_queryContext.length - 1]['content'] = trimValue
      }
    }
    setQueryContext(_queryContext)
  }

  const [oldActiveHistory, setOldActiveHistory] = useState(activeHistory)

  // 初始化当前会话消息列表
  //eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    if (controller) {
      // 取消之前的请求
      controller.abort()
    }
    if (!stopBtnShow) {
      // AI在输出的时候，保留原先的id，用于更新会话
      activeHistoryId.current = activeHistory && activeHistory.id
    }
    setHasShowSpeakModel(true)
    reSetChatList()
    reBuildQueryContext()
    needCreateTitle.current = false
    if (activeHistory && activeHistory.title === '新增会话') {
      needCreateTitle.current = true
      // 已有对话，且标题没生成过，则立即生成标题
      if (
        activeHistory.context &&
        JSON.parse(activeHistory.context).filter((item) => item.type == 'self_msg').length
      ) {
        createChatTitle({
          id: activeHistory.id,
          context: activeHistory.context,
        })
      }
    }
    setOldActiveHistory(activeHistory)
  }, [activeHistory, userInfo])

  // 重置聊天列表
  //eslint-disable-next-line max-lines-per-function
  const reSetChatList = () => {
    // 判断是否切换会话
    const isSameActive =
      (oldActiveHistory && oldActiveHistory.id) == (activeHistory && activeHistory.id)
    if (activeHistory && activeHistory.context) {
      initialMessages[0].createdAt = activeHistory.createTime || initialMessages[0].createdAt
      const activeChat = JSON.parse(activeHistory.context)
      let isRedMsg = false
      // 根据数据渲染会话
      activeChat.forEach((item, index) => {
        if (item.type == 'self_msg' || item.type == 'model_msg') {
          isRedMsg = item.content.question ? true : false
          item.user = userPhoto
          if (item.type == 'model_msg') {
            // 如已经有模式消息，则不再弹出选择模式弹窗
            setHasShowSpeakModel(false)
          }
        } else {
          item.user = isRedMsg ? redAvatar : aiAvatar
        }
        item._id = new Date().getTime() + index
      })
      // 切换会话时需要重置会话消息
      if (!isSameActive) {
        if (activeChat.length && activeChat[0].type == 'html') {
          // 兼容旧数据
          resetList(activeChat)
        } else {
          // 过滤模式模板消息
          resetList(initialMessages.concat(activeChat.filter((item) => item.type != 'model_msg')))
        }
      }
    } else {
      initialMessages[0].createdAt = new Date().getTime()
      resetList(initialMessages)
    }
  }

  // 重置上下文
  //eslint-disable-next-line max-lines-per-function
  const reBuildQueryContext = () => {
    if (activeHistory && activeHistory.context) {
      const activeChat = JSON.parse(activeHistory.context)
      const _queryContext = [] as any
      // 根据数据渲染会话
      activeChat.forEach((item, index) => {
        if (item.type == 'self_msg' || item.type == 'model_msg') {
          const obj = {
            role: 'user',
            content: item.content.text,
          }
          if (item.picContent) {
            const contentArr = picContentConvertPostContent(item.picContent)
            if (version == GPT4V) {
              obj['content'] = contentArr
            } else {
              obj['content'] = contentArr.filter((item) => {
                return item.type == 'text'
              })
            }
          }
          _queryContext.push(obj)
        } else {
          _queryContext.push({
            role: 'assistant',
            content: item.content.text,
          })
        }
      })
      // 重置提交给gpt的消息数据
      setQueryContext(_queryContext)
    } else {
      setQueryContext([])
    }
  }

  const [oldVersion, setOldVersion] = useState(version)

  useEffect(() => {
    if (oldVersion == GPT4V || version == GPT4V) {
      reBuildQueryContext()
    }
    setOldVersion(version)
  }, [version])

  // 移除loading会话
  useEffect(() => {
    if (messages.length) {
      const result = messages.find((item) => {
        return item.type === 'loading'
      })
      if (!loading && result) {
        // 消息回显，移除loading
        deleteMsg(result._id)
      }
    }
  }, [messages, loading])

  // 重新发送
  function reSend(id) {
    const index = messages.findIndex((item) => item._id === id)
    deleteMsg(messages[index]._id)
    const postMsg = messages[index - 1] as any
    const postObj = {
      text: postMsg.content?.text,
      noAppendMsg: true,
    } as any
    if (postMsg.picContent) {
      postObj.picContent = postMsg.picContent
    }
    handleSend(postObj)
  }

  // 终止生成
  function stopResponse(params) {
    console.log('stopResponse')
    initAudio()
    controller && controller.abort()
    console.log('stopResponse done.')
  }

  function Composer() {
    return <></>
  }

  return (
    <div className="chat-box">
      <audio ref={audioRef} autoPlay playsInline></audio>
      {
        //@ts-ignore
        <ChatUI
          messages={messages}
          renderMessageContent={renderMessageContent}
          Composer={Composer}
        />
      }
      <div className="StopChat">
        {
          // 停止生成按钮
          stopBtnShow && <StopBtn stopResponse={stopResponse} />
        }
      </div>
      <div className={`ChatFooter ${isKnowlogAI && 'sourceLibrary'}`}>
        <div className={`Composer`}>
          {renderSpeakModal()}
          {renderSpeakBtn()}
          {isSpeak && isMobi ? (
            <SpeechConversion
              stopBtnShow={stopBtnShow}
              initAudio={initAudio}
              onSendMsg={(text) =>
                handleSend({
                  text,
                  noAppendMsg: false,
                  needAudio: true,
                })
              }
            />
          ) : (
            <div className={`Composer-right`}>
              {renderProxyList()}
              <InputItem
                isKnowlogAI={isKnowlogAI}
                onInputKeyDown={onInputKeyDown}
                question={question}
                inputRef={inputRef}
                onInputChange={onInputChange}
                loading={loading}
                stopBtnShow={stopBtnShow}
                handleSend={handleSend}
              />
            </div>
          )}
        </div>
      </div>
      {renderTemplComps()}
    </div>
  )
}

export default ChatComp
