import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, Modal, Input, toast } from '@chatui/core'
import { MenuOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { setHistroyList, setActiveHistroy } from '@/store/actions/histroy'
import 'react-virtualized/styles.css'
import useRouter from '@/hooks/router/useRouter'
import { List, AutoSizer } from 'react-virtualized'
import './HistoryList.css'
import dayjs from 'dayjs'
import IconMsg from '@/assets/img/icon-massage.png'
import NoramlIconMsg from '@/assets/img/normal-msg.png'
import RedseaIconMsg from '@/assets/img/blue-msg.png'
import { reBuildListFnUts } from '../uts'
import PubSub from 'pubsub-js'
import { isWeixinBrowser } from '@/pages/chatgpt/uts'
import { AI_SOURCE_MAP } from '@/store/actions/other'

import http from '@/http/fetch'

//eslint-disable-next-line max-lines-per-function
function HistoryListComp(props) {
  const dispatch = useDispatch()
  const { historyList, activeHistory } = useSelector((state: any) => state.histroy)
  const { history, query, historyPush } = useRouter()

  const addHistory = (list?) => {
    const _historyList = [...(list || historyList)]
    const first = {
      title: '新增会话',
      context: '',
      id: new Date().getTime(),
      isNew: true,
    }
    _historyList.unshift(first)
    dispatch(setHistroyList(_historyList))
    dispatch(setActiveHistroy(first))
    PubSub.publish('resetSuggstionSetting')
  }

  const [delConfirmOpen, setDelConfirmOpen] = useState(false)
  const [delHistoryItem, setDelHistoryItem] = useState(null as any)

  const setDelHistory = (e, item) => {
    setDelHistoryItem(item)
    setDelConfirmOpen(true)
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  const delHistory = () => {
    setDelConfirmOpen(false)
    http.post('/fastdev/chatgpt/delete?id=' + delHistoryItem.id, {}).then((res) => {
      getHistoryList('isFirst')
    })
  }

  const [editHistory, setEditHistory] = useState(null as any)
  const [historyName, setHistoryName] = useState('')

  const setEditHistoryItem = (e, item) => {
    setHistoryName(item.title)
    setEditHistory(item)
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  const updateHistoryNameKeyUp = (val) => {
    if (val.keyCode === 13) {
      updateHistoryName()
    } else if (val.keyCode === 27) {
      setHistoryName('')
      setEditHistory(null)
    }
  }

  const updateHistoryName = () => {
    if (historyName && historyName !== editHistory.title) {
      http
        .post('/fastdev/chatgpt/saveOrUpdate', {
          ...editHistory,
          title: historyName,
        })
        .then((res) => {
          setHistoryName('')
          setEditHistory(null)
          const _historyList = [...historyList]
          for (let index = 0; index < _historyList.length; index++) {
            const element = _historyList[index]
            if (element.id == res.data) {
              element.title = historyName
              break
            }
          }
          dispatch(setHistroyList(_historyList))
        })
    } else {
      setHistoryName('')
      setEditHistory(null)
    }
  }

  const getHistoryList = (type) => {
    http.get('/fastdev/chatgpt/titlelist', {}).then((res) => {
      if (res.data && res.data.length) {
        const list = res.data

        if (type == 'isFirst') {
          addHistory(list)
        } else {
          dispatch(setHistroyList(list))
        }
        if (type === 'init') {
          queryContextById(res.data[0])
        }
      } else {
        addHistory([])
      }
    })
  }

  useEffect(() => {
    if (!activeHistory) {
      return
    }
    const _activeIndex = historyList.findIndex((item) => {
      return item.id == activeHistory.id
    })
    if (_activeIndex != -1) {
      if (!historyList[_activeIndex].context) {
        const _historyList = [...historyList]
        _historyList[_activeIndex] = activeHistory
        dispatch(setHistroyList(_historyList))
      }
      if (activeHistory.scroll) {
        const _rebuildIndex = rebuildList.findIndex((item: any) => {
          return item.id == activeHistory.id
        })
        listRef && listRef.current.scrollToRow(_rebuildIndex)
      }
    } else {
      if (activeHistory && activeHistory.scroll) {
        const _historyList = [...historyList]
        _historyList.push(activeHistory)
        dispatch(setHistroyList(_historyList))
        setTimeout(() => {
          listRef && listRef.current.scrollToRow(rebuildList.length - 1)
        }, 300)
      }
    }
  }, [activeHistory])

  const queryContextById = (item) => {
    const id = item.id
    if (id == (activeHistory && activeHistory.id)) return
    if (item.isNew) {
      dispatch(setActiveHistroy(item))
    } else {
      http
        .get('/fastdev/chatgpt/queryContextById', {
          id,
        })
        .then((res) => {
          dispatch(setActiveHistroy(res.data))
        })
    }
  }

  const onClickItem = (item) => {
    PubSub.publish('resetSuggstionSetting')
    queryContextById(item)
  }

  useEffect(() => {
    if (editHistory) {
      //@ts-ignore
      document.getElementsByClassName('editNameInput')[0].focus()
    }
  }, [editHistory])

  const isWxScanLogin = () => {
    if (query.openId && query.token == 'null') {
      return true
    }
    return false
  }
  const isScanHasToken = () => {
    if (!query.openId && query.token && query.token != 'null') {
      return true
    }
    return false
  }

  useEffect(() => {
    if (isWeixinBrowser()) {
      if (!isWxScanLogin() && !isScanHasToken()) {
        return
      }
    }

    if (isWxScanLogin()) {
      return
    }
    if (isWxScanLogin()) {
      return
    }
    getHistoryList('isFirst')
  }, [])

  const [showList, setShowList] = useState(false)
  const listRef = useRef(null as any)

  const [rebuildList, setRebuildList] = useState([] as any)
  useEffect(() => {
    reBuildListFn(historyList)
  }, [historyList])

  const reBuildListFn = (historyList) => {
    const list = reBuildListFnUts(historyList)
    setRebuildList(list)
  }

  const showRedSeaIcon = (item) => {
    const contextType = JSON.parse(item.contextType || '[]')
    // console.log('contextType', contextType)

    const _contextType = contextType.filter((item) => {
      if (item) {
        return item
      }
    })
    let isAllOpenAi = true
    if (_contextType && _contextType.length > 0) {
      _contextType.forEach((item) => {
        const values = Object.values(AI_SOURCE_MAP)
        if (!values.includes(item)) {
          isAllOpenAi = false
        }
      })
    }
    return !isAllOpenAi
  }

  const getIconSrc = (item) => {
    const isRedSea = showRedSeaIcon(item)
    return isRedSea ? RedseaIconMsg : NoramlIconMsg
  }

  //eslint-disable-next-line max-lines-per-function
  const rowRenderer = ({ index, key, style }) => {
    const rowItem = rebuildList[index]
    return (
      <>
        {rowItem.time ? (
          <div style={style} key={rowItem.key} className="time-bucket">
            {rowItem.time}
          </div>
        ) : (
          <div
            style={style}
            key={rowItem.id}
            className={`history-list-item ${
              activeHistory && rowItem.id === activeHistory.id ? 'active' : ''
            }`}
            onClick={() => onClickItem(rowItem)}
          >
            {editHistory && editHistory.id === rowItem.id ? (
              <Input
                maxLength={20}
                className="editNameInput"
                value={historyName}
                onChange={(val) => setHistoryName(val)}
                onBlur={updateHistoryName}
                onKeyUp={(val) => {
                  updateHistoryNameKeyUp(val)
                }}
                placeholder="请输入..."
              />
            ) : (
              <>
                <div className="name-box">
                  <img className="icon-msg" src={getIconSrc(rowItem)} alt="" />
                  <div className="history-name">{rowItem.title}</div>
                </div>
                {!rowItem.isNew && (
                  <div className="anticon-box">
                    <EditOutlined
                      title="编辑名称"
                      onClick={(e) => setEditHistoryItem(e, rowItem)}
                    />
                    {rebuildList.length > 1 && (
                      <DeleteOutlined title="删除会话" onClick={(e) => setDelHistory(e, rowItem)} />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    )
  }
  const [nowShowItem, setNowShowItem] = useState(null as any)
  const onRowsRendered = ({ startIndex }) => {
    if (rebuildList[startIndex + 1].time) {
      setNowShowItem(null)
    } else {
      setNowShowItem(rebuildList[startIndex])
    }
  }

  return (
    <>
      <div className="history-nav">
        <MenuOutlined onClick={() => setShowList(true)} />
        <span className="history-name">{(activeHistory && activeHistory.title) || 'RedGPT'}</span>
        {/* <PlusOutlined onClick={addHistory}/> */}
      </div>
      <div
        className={`history-list-mb-mask  ${showList ? 'mb-show' : ''}`}
        onClick={() => setShowList(false)}
      >
        <div className="history-list-box">
          <div className="history-list-title" onClick={() => addHistory()}>
            <PlusOutlined />
            新增会话
          </div>
          <div className={`history-list self-def-scroll-style`}>
            {nowShowItem && (
              <div className="show-time-text time-bucket">
                {nowShowItem.time || nowShowItem.timeText}
              </div>
            )}
            <AutoSizer>
              {({ width, height }) => (
                <List
                  ref={listRef}
                  className="self-def-scroll-style"
                  height={height} // 列表的高度
                  width={width} // 列表的宽度
                  rowCount={rebuildList.length} // 列表的行数
                  rowHeight={36} // 每一行的高度
                  rowRenderer={rowRenderer} // 渲染每一行
                  onRowsRendered={onRowsRendered}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
      <Modal
        active={delConfirmOpen}
        title="是否删除该会话"
        showClose={false}
        onClose={() => setDelConfirmOpen(false)}
        vertical={false}
        btnVariant="outline"
        actions={[
          {
            label: '确定',
            color: 'primary',
            onClick: delHistory,
          },
          {
            label: '取消',
            onClick: () => setDelConfirmOpen(false),
          },
        ]}
      ></Modal>
    </>
  )
}

export default HistoryListComp
