import React, { memo } from 'react'
import { Input } from 'antd'
import './style.css'

const UserPassword = (props) => {
  const { placeholder = '密码', onChange, maxLength } = props
  return (
    <div className="cp-user-password-input">
      <Input.Password placeholder={placeholder} onChange={onChange} maxLength={maxLength} />
    </div>
  )
}

export default UserPassword
