import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './Theme.css'

import { setIsThemeLight } from '../../../store/actions/other'
const isPubHuman = process.env.REACT_APP_FOR_HUMAN == '1'

function ThemeComp() {
  const dispatch = useDispatch()
  const { isThemeLight } = useSelector((state) => state.other)
  useEffect(() => {
    let storeTheme = window.localStorage.getItem('chatgpt-theme-light') == 'true'
    if (isPubHuman) {
      let storeThemeVal = window.localStorage.getItem('chatgpt-theme-light')
      if (!storeThemeVal) {
        storeTheme = true
      }
    }
    dispatch(setIsThemeLight(storeTheme))
  }, [])

  const switchTheme = () => {
    window.localStorage.setItem('chatgpt-theme-light', !isThemeLight)
    dispatch(setIsThemeLight(!isThemeLight))
  }

  return (
    <div className="btns-box-switch-theme" title="切换主题">
      <div className="chatflow-switch-theme" onClick={switchTheme}>
        <i
          className={`redsea-icon-font ${
            isThemeLight ? 'redsea-icon-theme-light' : 'redsea-icon-theme-dark'
          }`}
        ></i>
      </div>
    </div>
  )
}

export default ThemeComp
