export const verifyPhone = (phone) => {
  // const reg=/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  const reg = /^1[3-9]\d{9}$/
  if (!phone) {
    return '请输入手机号'
  }
  if (!reg.test(phone)) {
    return '请输入正确的手机号码'
  }
  return ''
}

const verifyCode = (code) => {
  if (!code) {
    return '请输入验证码'
  }
  if (code.length < 4) {
    return '请输入正确的验证码'
  }
  return ''
}

const verifyCheck = (isChecked) => {
  if (!isChecked) {
    return '请阅读并同意《服务协议与隐私条款》'
  }
  return ''
}

export const verifyInputCurrPassword = (currPassword, password, passwordConfirm) => {
  if (!password && !passwordConfirm) {
    return ''
  }
  if (!currPassword) {
    return '请输入当前密码'
  }
  return ''
}

export const verifyNewPassword = (password, passwordConfirm) => {
  if (!password && !passwordConfirm) {
    return ''
  }
  if (!password) {
    return '请输入新密码'
  }
  return ''
}

export const verifyNewConfirmPassword = (password, passwordConfirm) => {
  if (password && !passwordConfirm) {
    return '请再次输入新密码'
  }
  return ''
}

export const verifyPassword = (password, isConfrim?) => {
  if (!password) {
    if (isConfrim) {
      return '请再次输入密码'
    }
    return '请输入密码'
  }
  // abc123456@
  // (?=.?[?!&￥$%^#,./@";:><[]}{-=+_\|》《。，、？’‘“”~ `])表示要有特殊字符。
  // const reg =
  //   /^(?=.*?[a-zA-Z])(?=.*?\d)(?=.*?[@$!%?&,])[a-zA-Z\d@$!%*?&,]{8,12}$/;
  // const reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&+=])(?!.*\s).{8,12}$/
  // if (!reg.test(password)) {
  //   return '密码为8-12位,包含字母、数字及特殊符号'
  // }
  return ''
}
const verifyPwd = (pwd) => {
  return verifyPassword(pwd)
}
const verifyPwd2 = (pwd2) => {
  return verifyPassword(pwd2, true)
}

const verifyInviteCode = (invitCode) => {
  if (!invitCode) {
    return '请输入邀请码'
  }
  return ''
}
export const verifyPwdSame = (pwd, pwd2) => {
  if (pwd != pwd2) {
    return '两次密码不一致'
  }
  return ''
}
export const verifyRegisterStep1 = ({ phone, code, pwd, pwd2, invitCode }) => {
  let tip = verifyInviteCode(invitCode)
  if (!tip) {
    tip = verifyPhone(phone)
  }
  if (!tip) {
    tip = verifyPwd(pwd)
  }
  if (!tip) {
    tip = verifyPwd2(pwd2)
  }
  if (!tip) {
    tip = verifyPwdSame(pwd, pwd2)
  }
  if (!tip) {
    tip = verifyCode(code)
  }

  return tip
}

export const verifyBind = ({ phone, pwd }) => {
  let tip = verifyPhone(phone)
  if (!tip) {
    tip = verifyPwd(pwd)
  }
  return tip
}

export const verifyUniBind = ({ phone, code }) => {
  let tip = verifyPhone(phone)
  if (!tip) {
    tip = verifyCode(code)
  }
  return tip
}

export const verifyRegisterRowInput = (opt) => {
  const { phone, code, isChecked, name } = opt
  if (name == 'phone') {
    return verifyPhone(phone)
  }
  if (name == 'code') {
    return verifyCode(code)
  }
  if (name == 'isChecked') {
    return verifyCheck(isChecked)
  }
  return ''
}
export const verifyLogin = (phone, password, isChecked) => {
  let tip = verifyPhone(phone)
  if (!tip) {
    tip = verifyPassword(password)
  }
  // if (!tip) {
  //   tip = verifyCheck(isChecked)
  // }
  return tip
}

export const verifyLoginAI = (phone, password, code) => {
  let tip = verifyPhone(phone)
  if (!tip) {
    tip = verifyPassword(password)
  }
  if (!tip) {
    tip = verifyCode(code)
  }
  return tip
}

export const verifyLoginRowInput = (opt) => {
  const { phone, password, isChecked, name } = opt
  if (name == 'phone') {
    return verifyPhone(phone)
  }
  if (name == 'password') {
    return verifyPassword(password)
  }
  if (name == 'isChecked') {
    return verifyCheck(isChecked)
  }
  return ''
}

export const verifyForGot = (phone, code) => {
  let tip = verifyPhone(phone)
  if (!tip) {
    tip = verifyCode(code)
  }
  return tip
}

export const verifyForGotRowInput = (opt) => {
  const { phone, code, name } = opt
  if (name == 'phone') {
    return verifyPhone(phone)
  }
  if (name == 'code') {
    return verifyCode(code)
  }
  return ''
}
export const verifySetPwd = (pwd, pwd2) => {
  let tip = verifyPwd(pwd)
  if (!tip) {
    tip = verifyPwd2(pwd2)
  }
  if (!tip) {
    tip = verifyPwdSame(pwd, pwd2)
  }
  return tip
}

export const verifySetPwdInput = (opt) => {
  const { pwd, pwd2, name } = opt
  if (name == 'pwd') {
    return verifyPwd(pwd)
  }
  if (name == 'pwd2') {
    return verifyPwd2(pwd2)
  }
  return ''
}
