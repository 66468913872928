import http from '../../http/fetch'
import { getAuthSign } from '../../http/utils'
import { getUserInfoFromRedTeams } from '../common/index'
import { DEFAULT_MODEL } from '../../utils/chatgpt'

// 获取模板列表
// http://10.20.20.66:8085/
// fastdev/gptapi/list?pageNo=1&pageSize=2&user_id=8f11MTIzyR3SMW&user_name=0311c3U=FY5P

const isDev = !!window.location.href.includes('localhost')
// const baseUrl = isDev ? `http://192.168.101.186:8199` : ''
// const baseUrl = isDev ? `http://10.20.20.66:8085` : "";
const baseUrl = 'https://vw.hr-soft.cn'

export const getTempList = async (pageNumber, pageSize) => {
  const url = `${baseUrl}/fastdev/gptapi/list`
  const timestamp = new Date().getTime()
  const userinfo: any = await getUserInfoFromRedTeams()

  const { encodeUserId, encodeUserName } = userinfo
  // const _encodeId = encodeURIComponent(encodeUserId);
  // const _encodeName = encodeURIComponent(encodeUserName);
  const _authParams = {
    user_id: encodeUserId,
    user_name: encodeUserName,
    timestamp: timestamp,
    pageNo: pageNumber,
    pageSize: pageSize,
  }

  const auth = getAuthSign(_authParams)

  const params = {
    ..._authParams,
    passAuth: auth,
  }

  const res = await http.get(url, params, {
    // hideLoading: !!isDev
  })

  return res
}

//eslint-disable-next-line max-lines-per-function
export const createTempApi = async (formData) => {
  const timestamp = new Date().getTime()
  const userinfo: any = await getUserInfoFromRedTeams()

  const { encodeUserId, encodeUserName } = userinfo
  const _encodeId = encodeURIComponent(encodeUserId)
  const _encodeName = encodeURIComponent(encodeUserName)
  const url = `${baseUrl}/fastdev/gptapi/saveOrUpdate?user_id=${_encodeId}&user_name=${_encodeName}&timestamp=${timestamp}`
  const _authParams = {
    user_id: encodeUserId,
    user_name: encodeUserName,
    timestamp: timestamp,
  }
  const auth = getAuthSign(_authParams)

  const defaultOpt = {
    engine: 'ai',
    classType: 'common',
    codeNo: `ai_${timestamp}`,
    codeText: '', // 腾讯会议内容摘要
    message: '', // 请对以下内容进行重点提炼和内容摘要:\n  ${content}
    inParams: '${content}',
    inUse: 1, // 状态|0:停用;1:启用
    ask: 0,
    model: DEFAULT_MODEL,
    id: '',
    parentCodeNo: 'ai_self_root',
  }

  const params = {
    ...defaultOpt,
    ...formData,
  }
  const res = await http.post(url, params, {
    dataType: 'json',
    hasPassAuth: auth,
  })

  return res
}

export const deleteTempl = async (id) => {
  const timestamp = new Date().getTime()
  const userinfo: any = await getUserInfoFromRedTeams()

  const { encodeUserId, encodeUserName } = userinfo
  const _encodeId = encodeURIComponent(encodeUserId)
  const _encodeName = encodeURIComponent(encodeUserName)

  const url = `${baseUrl}/fastdev/gptapi/delete?id=${id}&user_id=${_encodeId}&user_name=${_encodeName}&timestamp=${timestamp}`
  const _authParams = {
    id: id,
    user_id: encodeUserId,
    user_name: encodeUserName,
    timestamp: timestamp,
  }
  const auth = getAuthSign(_authParams)

  const params = {}
  const res = await http.get(url, params, {
    hasPassAuth: auth,
  })
  return res
}

// 暂未用到
export const templDetail = async (id) => {
  const timestamp = new Date().getTime()
  const userinfo: any = await getUserInfoFromRedTeams()

  const { encodeUserId, encodeUserName } = userinfo
  const _encodeId = encodeURIComponent(encodeUserId)
  const _encodeName = encodeURIComponent(encodeUserName)

  const url = `${baseUrl}/fastdev/gptapi/detail?id=${id}&user_id=${_encodeId}&user_name=${_encodeName}&timestamp=${timestamp}`
  const params = {}
  const res = await http.get(url, params)
  return res
}
