import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { useMessages, toast } from '@chatui/core'
import { tokenizerFn, MAXTOKENS } from '@/utils/chatgpt'
import useHighLight from '@/hooks/useHighLight'
import useKeyEvent from '@/hooks/useKeyEvent'
import useTempl from '@/hooks/useTempl'
import useRenderMsgContent from '@/pages/multiwindow/hooks/useRenderMsgContent'
import useDelQuestion from '@/pages/multiwindow/hooks/useDelQustion'
import useChatGptFunc from '@/pages/multiwindow/hooks/useChatGptFunc'
import InputItem from '@/component/input/InputItem'
import { aiAvatar, redAvatar, initialMessages } from './constants'
import './Chat.css'
//@ts-ignore
import userLogoSvg from '@/assets/img/user.svg'

// 对话组件
//eslint-disable-next-line max-lines-per-function
function ChatComp(props) {
  useEffect(() => {
    if (props.question) {
      handleSend({ text: props.question })
    }
  }, [props.question])

  //  ----------------------------------chat会话 start -----------------------

  // Chat组件自带消息实践
  const { messages, appendMsg, deleteMsg, resetList, updateMsg } = useMessages(initialMessages)
  // 版本信息
  const { version, temperature, maxTokens, versionAuth, isThemeLight, sourceLibrary } = useSelector(
    (state: any) => state.other
  )
  const isOpenAI = sourceLibrary.join(',') == 'OpenAI'
  // 用户信息
  const { userInfo } = useSelector((state: any) => state.user)

  const getUserPhoto = () => {
    if (userInfo?.userPhoto) {
      return {
        avatar: `https://vw.hr-soft.cn/uploadfile${userInfo.userPhoto}`,
      }
    }
    if (userInfo?.user?.avatar) {
      return {
        avatar: userInfo.user.avatar,
      }
    }
    return {
      avatar: userLogoSvg,
    }
  }
  const userPhoto = getUserPhoto()

  // 提交给gpt的消息数据
  const [queryContext, setQueryContext] = useState([] as any)

  // 设置问题
  const [question, setQuestion] = useState('')

  // 是否显示停止生成按钮
  const [stopBtnShow, setStopBtnShow] = useState(false)

  //聊天会话方法抽离
  const { querySendByStream, loading, setLoading, setQuestionLink, controller } = useChatGptFunc({
    queryContext,
    question,
    messages,
    appendMsg,
    isOpenAI,
    setQueryContext,
    updateMsg,
    stopBtnShow,
    setStopBtnShow,
  })

  useHighLight({ isThemeLight, messages })
  useKeyEvent({ question, handleSend })

  //模板新增，删除
  const { inputRef, onInputKeyDown, showTemplateDialog, onInputChange, renderTemplComps } =
    useTempl({
      setQuestion,
      handleSend,
    })

  //删除问题
  const { renderDelModal, setDelMsg, delQA } = useDelQuestion({
    deleteMsg,
    messages,
    queryContext,
    setQueryContext,
  })

  //渲染消息体
  const { renderMessageContent } = useRenderMsgContent({
    renderDelModal,
    showTemplateDialog,
    reSend,
  })

  //eslint-disable-next-line max-lines-per-function
  async function handleSend(opt: any) {
    const { text, noAppendMsg, needAudio, type, picContent } = opt || {}
    const val = text || question
    if (loading) {
      return
    }
    const trimValue = val.trim()
    if (!trimValue) {
      return
    }
    const postMsg = {
      role: 'user',
      content: trimValue,
    } as any
    // 计算发送消息的长度
    const msgTokens = tokenizerFn(JSON.stringify(postMsg))
    const _maxTokens = MAXTOKENS[version]
    if (_maxTokens - msgTokens < 0) {
      toast.show('输入内容过长，请重新输入。', 'error')
      return
    }
    setLoading(true)
    setQuestionLink([])
    let _selfMsg = null as any
    _selfMsg = {
      type: type || 'self_msg',
      needAudio: !!needAudio,
      content: { text: trimValue },
      sourceLibrary: sourceLibrary,
      modelVersion: version,
    }
    if (picContent) {
      _selfMsg.picContent = picContent
    }
    // 消息类型是心流模板时不显示在页面上
    if (!noAppendMsg) {
      // 插入问题
      appendMsg({
        ..._selfMsg,
        class: 'is_self',
        user: userPhoto,
      })
    }
    // 插入loading
    appendMsg({
      type: 'loading',
      user: isOpenAI ? aiAvatar : redAvatar,
    })
    setQuestion('')
    setTimeout(() => {
      const messagesRef = document.getElementsByClassName('PullToRefresh')
      if (messagesRef.length) {
        for (let index = 0; index < messagesRef.length; index++) {
          const element = messagesRef[index]
          element.scrollTop = element.scrollHeight + 40
        }
      }
    }, 100)
    const _queryContext = [] as any
    let _msgStr = ''
    // 用于追问过长时，保留第一个问题
    if (queryContext.length) {
      _queryContext.push(queryContext[0])
      _msgStr = JSON.stringify(queryContext[0])
    }
    // 重发等情况不需要再问一遍
    if (!noAppendMsg) {
      _msgStr += JSON.stringify(postMsg)
      _queryContext.push(postMsg)
    }
    // 有system前置背景时，需要插入第一个
    if (props.systemContext) {
      const systemMsg = {
        role: 'system',
        content: props.systemContext,
      }
      _queryContext.unshift(systemMsg)
      _msgStr = JSON.stringify(systemMsg) + _msgStr
    }
    let _msgStrTokens = tokenizerFn(_msgStr)
    // 判断提问数组tokens长度是否超过模型tokens长度，从最后一个问题往前判断截取
    for (let index = queryContext.length - 1; index > 0; index--) {
      const itemStr = JSON.stringify(queryContext[index])
      const itemStrTokens = tokenizerFn(itemStr)
      const nowMsgTokens = _msgStrTokens + itemStrTokens
      if (nowMsgTokens < _maxTokens) {
        _msgStr += itemStr
        _msgStrTokens = nowMsgTokens
        if (_queryContext.length) {
          const startIndex = props.systemContext ? 2 : 1
          _queryContext.splice(startIndex, 0, queryContext[index])
        } else {
          _queryContext.unshift(queryContext[index])
        }
      }
    }

    querySendByStream(_queryContext, _selfMsg, {
      temperature: temperature ? Number(temperature) : null,
      maxTokens: maxTokens ? Number(maxTokens) : null,
    })
    // 将上下文重置回最初的问题
    _queryContext[_queryContext.length - 1]['content'] = trimValue
    if (props.systemContext) {
      _queryContext.shift()
    }
    setQueryContext(_queryContext)
  }

  // 移除loading会话
  useEffect(() => {
    if (messages.length) {
      const result = messages.find((item) => {
        return item.type === 'loading'
      })
      if (!loading && result) {
        // 消息回显，移除loading
        deleteMsg(result._id)
      }
    }
  }, [messages, loading])

  // 重新发送
  function reSend(id) {
    const index = messages.findIndex((item) => item._id === id)
    deleteMsg(messages[index]._id)
    handleSend({
      text: messages[index - 1].content?.text,
      noAppendMsg: true,
    })
  }

  // 终止生成
  function stopResponse(params) {
    console.log('stopResponse')
    // initAudio()
    controller && controller.abort()
    console.log('stopResponse done.')
  }

  useEffect(() => {
    if (messages.length) {
      // 解决MD渲染器不重新渲染问题
      const lastMessgae = messages[messages.length - 1]
      if (lastMessgae.type == 'html') {
        deleteMsg(lastMessgae._id)
        setTimeout(() => {
          appendMsg(lastMessgae)
        }, 10)
      }
    }
  }, [stopBtnShow])

  function Composer() {
    return <></>
  }

  return (
    <div className="chat-box">
      {
        //@ts-ignore
        <ChatUI
          messages={messages}
          renderMessageContent={renderMessageContent}
          Composer={Composer}
        />
      }
      {/* <div className="StopChat">
        {
          // 停止生成按钮
          stopBtnShow && <StopBtn stopResponse={stopResponse} />
        }
      </div> */}
      <div className={`ChatFooter ${!isOpenAI && 'sourceLibrary'}`}>
        <div className={`Composer`}>
          <div className={`Composer-right`}>
            <InputItem
              onInputKeyDown={onInputKeyDown}
              question={question}
              inputRef={inputRef}
              onInputChange={onInputChange}
              loading={loading}
              stopBtnShow={stopBtnShow}
              handleSend={handleSend}
            />
          </div>
        </div>
      </div>
      {renderTemplComps()}
    </div>
  )
}

export default ChatComp
