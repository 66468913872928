import dayjs from 'dayjs'

//eslint-disable-next-line max-lines-per-function
export const reBuildListFnUts = (historyList) => {
  const transformedData = [] as any
  const today = dayjs()
  const weekAgo = dayjs().subtract(7, 'day')
  const monthAgo = dayjs().subtract(1, 'month')
  const threeMonthAgo = dayjs().subtract(3, 'month')
  const halfYearAgo = dayjs().subtract(6, 'month')
  const oneYearAgo = dayjs().subtract(1, 'year')
  let hasToday = false
  let hasWeekAgo = false
  let hasMonthAgo = false
  let hasThreeMonthAgo = false
  let hasHalfYearAgo = false
  let hasOneYearAgo = false
  let hasBeforeYearAgo = false
  //eslint-disable-next-line max-lines-per-function
  historyList.forEach((item) => {
    const operateTime = dayjs(item.operateTime)
    if (operateTime.isSame(today, 'day')) {
      if (!hasToday) {
        transformedData.push({ time: '今天', key: 'today' })
        hasToday = true
      }
      item.timeText = '今天'
    } else if (operateTime.isAfter(weekAgo)) {
      if (!hasWeekAgo) {
        transformedData.push({ time: '最近一周', key: 'afterWeekAgo' })
        hasWeekAgo = true
      }
      item.timeText = '最近一周'
    } else if (operateTime.isAfter(monthAgo)) {
      if (!hasMonthAgo) {
        transformedData.push({ time: '最近一个月', key: 'afterMonthAgo' })
        hasMonthAgo = true
      }
      item.timeText = '最近一个月'
    } else if (operateTime.isAfter(threeMonthAgo)) {
      if (!hasThreeMonthAgo) {
        transformedData.push({ time: '最近三个月', key: 'afterThreeMonthAgo' })
        hasThreeMonthAgo = true
      }
      item.timeText = '最近三个月'
    } else if (operateTime.isAfter(halfYearAgo)) {
      if (!hasHalfYearAgo) {
        transformedData.push({ time: '最近半年', key: 'afterHalfYearAgo' })
        hasHalfYearAgo = true
      }
      item.timeText = '最近半年'
    } else if (operateTime.isAfter(oneYearAgo)) {
      if (!hasOneYearAgo) {
        transformedData.push({ time: '最近一年', key: 'afterOneYearAgo' })
        hasOneYearAgo = true
      }
      item.timeText = '最近一年'
    } else if (operateTime.isBefore(oneYearAgo)) {
      if (!hasBeforeYearAgo) {
        transformedData.push({ time: '一年以前', key: 'beforeOneYearAgo' })
        hasBeforeYearAgo = true
      }
      item.timeText = '一年以前'
    }
    transformedData.push(item)
  })
  return transformedData
}

// AI输出时解码
export const base64Decode = (base64Str) => {
  try {
    return decodeURIComponent(window.atob(base64Str))
  } catch (error) {
    console.log(base64Str)
    console.error('Failed to decode the string:', error)
    return null
  }
}

// AI输出时解码
export const decodeBase64 = (base64String) => {
  // 对 base64 字符串进行逆向替换
  base64String = base64String.replace(/-/g, '+').replace(/_/g, '/')
  // 解码字符串
  return decodeURIComponent(
    atob(base64String)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}
