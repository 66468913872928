import {
  SET_VERSION,
  SET_IS_THEME_LIGHT,
  SET_SOURCE_LIBRARY,
  SET_TEMPERATURE,
  SET_MAXTOKENS,
  SET_VERSION_LIST,
  SET_SOURCE_AI_TYPE,
  AI_SOURCE_MAP,
  gptOptions,
  baiduOptions,
  zhipuOptions,
} from '../actions/other'

const getOptionsByType = (type) => {
  switch (type) {
    case AI_SOURCE_MAP.OpenAI:
      return gptOptions
    case AI_SOURCE_MAP.Baidu:
      return baiduOptions
    case AI_SOURCE_MAP.Zhipu:
      return zhipuOptions
    default:
      return gptOptions
  }
}

const initState = {
  temperature: null, // OpenAI温度
  version: 'gpt-3.5-turbo-0125',
  maxTokens: null,
  isThemeLight: false,
  versionAuth: [
    '9f68c8a6-78cb-11ec-8e03-14187749a8c0', // 凌鹏
    '9f647ccf-78cb-11ec-8e03-14187749a8c0', // 梁伟思
    '9f63894a-78cb-11ec-8e03-14187749a8c0', // 孙总
    '9f63b01e-78cb-11ec-8e03-14187749a8c0', // 潘镇洪
    'b270f248-9c2d-11ec-8e03-14187749a8c0', // 王灿楷
    'e91661f1-3abb-11ec-8e03-14187749a8c0', // 谢春为
    '9142f341-28e9-11ec-8e03-14187749a8c0', // 曹海兵
    '9f6470ac-78cb-11ec-8e03-14187749a8c0', // 谭梦珠
    '9f683d67-78cb-11ec-8e03-14187749a8c0', // 谭文斌
    '9f639181-78cb-11ec-8e03-14187749a8c0', // 凌光明
    '9f6481c0-78cb-11ec-8e03-14187749a8c0', // 张志强
    '5f5bb56f-7584-11eb-959c-14187749a8c0', // 钟晓云
    '8e211885-b622-11ec-8e03-14187749a8c0', // 柳纯
    '9f64369d-78cb-11ec-8e03-14187749a8c0', // 董盈华
    '11798309-a168-11ed-b96c-2cea7fe9319c', // 梁心弦
  ],
  sourceLibrary: ['OpenAI'],
  testVersionAuth: [
    '9f68c8a6-78cb-11ec-8e03-14187749a8c0', // 凌鹏
    '8e211885-b622-11ec-8e03-14187749a8c0', // 柳纯
    '5f5bb56f-7584-11eb-959c-14187749a8c0', // 钟晓云
    'b270f248-9c2d-11ec-8e03-14187749a8c0', // 王灿楷
    '9142f341-28e9-11ec-8e03-14187749a8c0', // 曹海兵
  ], //用于正式发布前的测试
  versionList: gptOptions,
}
//eslint-disable-next-line max-lines-per-function
const otherReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_VERSION:
      return {
        ...state,
        version: action.payload,
      }
    case SET_IS_THEME_LIGHT:
      return {
        ...state,
        isThemeLight: action.payload,
      }
    case SET_SOURCE_LIBRARY:
      return {
        ...state,
        sourceLibrary: action.payload,
      }
    case SET_TEMPERATURE:
      return {
        ...state,
        temperature: action.payload,
      }
    case SET_MAXTOKENS:
      return {
        ...state,
        maxTokens: action.payload,
      }
    case SET_VERSION_LIST:
      return {
        ...state,
        versionList: action.payload,
      }
    case SET_SOURCE_AI_TYPE:
      //eslint-disable-next-line no-case-declarations
      const versionList = getOptionsByType(action.payload)
      return {
        ...state,
        versionList: versionList,
      }

    default:
      return state
  }
}

export default otherReducer
