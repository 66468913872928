import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Button, useMessages, Icon, toast, Modal } from '@chatui/core'
import { DeleteOutlined, LinkOutlined, EllipsisOutlined } from '@ant-design/icons'
import PubSub from 'pubsub-js'

//eslint-disable-next-line max-lines-per-function
const useDelQuestion = (props: any) => {
  const { deleteMsg, messages, queryContext, setQueryContext } = props
  const copyMessages = useRef(messages)
  const copyQueryContext = useRef(queryContext)
  useEffect(() => {
    copyMessages.current = messages
  }, [messages])
  useEffect(() => {
    copyQueryContext.current = queryContext
  }, [queryContext])
  // 多窗口模式下，主窗口删除消息，通知其他子窗口删除
  useEffect(() => {
    const token = PubSub.subscribe('delMsg', (e, { delIndex }) => {
      delQA(delIndex, copyMessages.current, copyQueryContext.current)
    })
    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  const [delConfirmOpen, setDelConfirmOpen] = useState(false)
  const [delMsg, setDelMsg] = useState(null)
  const showDelConfirm = (delMsg) => {
    setDelMsg(delMsg)
    setDelConfirmOpen(true)
  }
  const searchDelIndex = () => {
    const msg: any = delMsg
    let delIndex = -1
    messages.forEach((item, index) => {
      if (item._id == msg._id) {
        delIndex = index
      }
    })
    PubSub.publish('delMsg', {
      delIndex,
    })
  }
  const delQA = (delIndex, messages, queryContext) => {
    const msg = messages[delIndex]
    deleteMsg(msg._id)
    let nextMsg: any = null
    if (messages[delIndex + 1] && messages[delIndex + 1].type == 'html') {
      nextMsg = messages[delIndex + 1]
    }
    if (nextMsg) {
      deleteMsg(nextMsg._id)
      queryContext.splice(delIndex, 2)
    } else {
      queryContext.splice(delIndex, 1)
    }
    setQueryContext(queryContext)
    setDelConfirmOpen(false)
  }

  const renderDelModal = (msg) => {
    return (
      <>
        <DeleteOutlined title="删除该问答" onClick={() => showDelConfirm(msg)} />
        <Modal
          active={delConfirmOpen}
          title="是否删除该问答"
          showClose={false}
          onClose={() => setDelConfirmOpen(false)}
          vertical={false}
          btnVariant="outline"
          actions={[
            {
              label: '确定',
              color: 'primary',
              onClick: () => searchDelIndex(),
            },
            {
              label: '取消',
              onClick: () => setDelConfirmOpen(false),
            },
          ]}
        ></Modal>
      </>
    )
  }

  return {
    renderDelModal,
    setDelMsg,
    delQA,
  }
}

export default useDelQuestion
