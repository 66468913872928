import React, { useState, useEffect, useRef } from 'react'
import { register, smsSend } from '@/apis/login/login'

import {
  verifyRegisterStep1,
  verifyRegisterRowInput,
  verifyPhone,
  verifyBind,
  verifyUniBind,
} from './verify'

//eslint-disable-next-line max-lines-per-function
export default function useVerifyRegister() {
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [pwd, setPwd] = useState('')
  const [pwd2, setPwd2] = useState('')
  const [invitCode, setInvitCode] = useState('')

  const [isChecked, setIsChecked] = useState(false)
  const inputDataRef = useRef({
    phone: '',
    pwd: '',
    pwd2: '',
    code: '',
    invitCode: '',
  })
  const [errTip, setErrTip] = useState('')
  const [isBtnActive, setisBtnActive] = useState(false)

  const verifyInputOk = () => {
    const { phone, code, pwd, pwd2, invitCode } = inputDataRef.current
    const tip = verifyRegisterStep1({ phone, code, pwd, pwd2, invitCode })
    if (tip) {
      setErrTip(tip)
      return false
    }
    setErrTip('')
    return true
  }

  const verifyBindLogin = () => {
    const { phone, pwd } = inputDataRef.current
    const tip = verifyBind({ phone, pwd })
    if (tip) {
      setErrTip(tip)
      return false
    }
    setErrTip('')
    return true
  }

  const verifyUniBindLogin = () => {
    const { phone, code } = inputDataRef.current
    const tip = verifyUniBind({ phone, code })
    if (tip) {
      setErrTip(tip)
      return false
    }
    setErrTip('')
    return true
  }

  const verifyRowInput = (name) => {
    const { phone, code } = inputDataRef.current
    const tip = verifyRegisterRowInput({
      phone,
      code,
      isChecked,
      name,
    })
    if (tip) {
      setErrTip(tip)
      return
    }
    setErrTip('')
  }

  const verifyInputIng = (name) => {
    if (errTip) {
      verifyRowInput(name)
    }
    isSubmitBtnCanClick()
  }
  const isSubmitBtnCanClick = () => {
    // const { phone, code, isChecked } = inputDataRef.current
    // const tip = verifyRegisterStep1(phone, code, isChecked)
    // if (tip) {
    //   setisBtnActive(false)
    // } else {
    //   setisBtnActive(true)
    // }
  }

  const getLoginMsgCode = async () => {
    const scene = '101'
    await smsSend(phone, scene)
  }
  const getRegMsgCode = async () => {
    const scene = '102'
    await smsSend(phone, scene)
  }

  const getWxBindMsgCode = async () => {
    const scene = '199'
    await smsSend(phone, scene)
  }

  const getMsgCodeTip = (tip) => {
    if (tip) {
      setErrTip(tip)
    }
  }

  const setReadState = () => {
    // inputDataRef.current.isChecked = !isChecked
    setIsChecked(!isChecked)
    verifyInputIng('isChecked')
  }

  const onChangePhone = (e) => {
    const phone = e.target.value
    inputDataRef.current.phone = phone
    setPhone(phone)
    verifyInputIng('phone')
  }
  const onChangeCode = (e) => {
    const code = e.target.value
    inputDataRef.current.code = code
    setCode(code)
    verifyInputIng('code')
  }

  const onChangePwd = (e) => {
    const value = e.target.value
    inputDataRef.current.pwd = value
    setPwd(value)
  }
  const onChangePwd2 = (e) => {
    const value = e.target.value
    inputDataRef.current.pwd2 = value
    setPwd2(value)
  }

  const onChangeInviteCode = (e) => {
    const value = e.target.value
    inputDataRef.current.invitCode = value
    setInvitCode(value)
  }

  return {
    pwd2,
    pwd,
    invitCode,
    phone,
    setPhone,
    code,
    setCode,
    isChecked,
    setIsChecked,
    inputDataRef,
    errTip,
    setErrTip,
    isBtnActive,
    setisBtnActive,
    verifyInputOk,
    verifyBindLogin,
    verifyRowInput,
    isSubmitBtnCanClick,
    getRegMsgCode,
    getLoginMsgCode,
    getMsgCodeTip,
    setReadState,
    onChangePhone,
    onChangeCode,
    onChangePwd,
    onChangePwd2,
    onChangeInviteCode,
    verifyUniBindLogin,
    getWxBindMsgCode,
  }
}
