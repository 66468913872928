import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Input, Button, Spin, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
import { MilkDown } from '@/component/MilkDown'
import { toast } from '@chatui/core'
import http from '@/http/fetch'
import { extractImageUrl, filterImageText } from '@/utils/common'
import PubSub from 'pubsub-js'

import '@/component/tools/temp/modal/TemplateModal.css'

let mdIsInitTimer: any = null
let setValueIdTimer: any = null
//eslint-disable-next-line max-lines-per-function
function usePicModal(props) {
  const { handleSend } = props
  const { version, isThemeLight } = useSelector((state: any) => state.other)
  const [dialogVisit, setDialogVisit] = useState(false)
  const [mdHasShown, setMdHasShown] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)

  const uploadImgsRef = useRef(null)
  const mdInputRef = useRef(null)

  const onCloseModal = () => {
    setDialogVisit(false)
  }

  const getImgBlob = (urls) => {
    const params = {}
    const config = {
      responseType: 'blob',
    }
    const requests: any = []
    urls?.forEach((url) => {
      const promise = http.get(url, params, config)
      requests.push(promise)
    })

    return requests
  }

  const uploadToOcr = async () => {
    const imgs: any = uploadImgsRef.current
    if (!imgs) {
      toast.show('请上传图片', 'error')
      return
    }
    if (imgs?.length == 0) {
      toast.show('请上传图片', 'error')
      return
    }
    const requestArr: any = getImgBlob(imgs)
    const resList: any = await axios.all(requestArr)
    const formData = new FormData()
    resList.forEach((fileObj, idx) => {
      const splitArr = imgs[idx].split('/')
      const fileName = splitArr[splitArr.length - 1]
      formData.append('files', fileObj, fileName)
    })

    const url = `/redapi/sp/tech/chatgpt/answer/ocr`
    const res = await http.post(url, formData, {
      dataType: 'formData',
    })

    const data = res.data
    return data
  }

  const submitSend = async () => {
    try {
      setIsSubmiting(true)
      let question = ''
      if (version != 'gpt-4-vision-preview') {
        const res = await uploadToOcr()
        const testQ = filterImageText(mdInputRef.current)
        question = res.replace('$content', testQ)
      }
      handleSend({
        text: question,
        picContent: mdInputRef.current,
      })
      setIsSubmiting(false)
      onCloseModal()
      window.milkDownVue.setValue('')
    } catch (err) {
      setIsSubmiting(false)
    }
  }

  useEffect(() => {
    isMarkdownInit()
    mdIsInitTimer = setInterval(() => {
      isMarkdownInit()
    }, 500)
    return () => {
      mdIsInitTimer && clearInterval(mdIsInitTimer)
    }
  }, [])

  useEffect(() => {
    global.isPicModalShow = dialogVisit
  }, [dialogVisit])

  useEffect(() => {
    const token = PubSub.subscribe('fastSendMsgInPicModal', () => {
      submitSend()
    })
    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  const isMarkdownInit = () => {
    if (document.querySelector('.milkdown .editor')) {
      setMdHasShown(true)
      mdIsInitTimer && clearInterval(mdIsInitTimer)
    }
  }

  const ModalFooter = [
    <Button key="cancel" onClick={onCloseModal}>
      取消
    </Button>,
    <Button key="submit" type="primary" onClick={submitSend} loading={isSubmiting}>
      发送
    </Button>,
  ]

  const onChange = (htmlStr) => {
    mdInputRef.current = htmlStr.replace('"image.png")', ')')

    const imgs = extractImageUrl(mdInputRef.current)

    uploadImgsRef.current = imgs
  }

  const setValueInter = (htmlStr) => {
    setValueIdTimer = setInterval(() => {
      const editorEle = document.querySelector('.milkdown .editor')
      if (editorEle) {
        window.milkDownVue.setValue(htmlStr)
        onChange(htmlStr)
        setValueIdTimer && clearInterval(setValueIdTimer)
      }
    }, 500)
  }

  const onInputPaste = (herfUrl) => {
    const htmlStr = `![image](/uploadfile${herfUrl})`
    const editorEle = document.querySelector('.milkdown .editor')
    if (editorEle) {
      window.milkDownVue.setValue(htmlStr)
      onChange(htmlStr)
    } else {
      setValueInter(htmlStr)
    }
  }

  //eslint-disable-next-line max-lines-per-function
  const renderPicModal = () => {
    return (
      <Modal
        wrapClassName={
          isThemeLight ? 'pic-input-modal light-modal-wrap' : 'pic-input-modal dark-modal-wrap'
        }
        title={'编辑'}
        width="1000px"
        open={dialogVisit}
        onCancel={onCloseModal}
        footer={ModalFooter}
        maskClosable={false}
      >
        <Spin spinning={!mdHasShown}>
          <MilkDown
            theme={isThemeLight ? 'light' : 'dark'}
            isSimple={true}
            onChange={onChange}
            milkDownId="pic-input-markdown-id"
            action="/fastdev/tech/ptfjk/upload"
            platformMode="fastdev"
            uploadImgUrlKey="herfUrl"
          ></MilkDown>
        </Spin>
      </Modal>
    )
  }

  return {
    renderPicModal,
    setDialogVisit,
    onInputPaste,
    picModalSubmitSend: submitSend,
  }
}

export default usePicModal
