import React, { memo, useState, useRef, useEffect } from 'react'
import { Tabs } from 'antd'
import rlogoPng from '@/assets/img/login/rlogo.png'
import PLogin from './plogin'
import './style.css'

//eslint-disable-next-line max-lines-per-function
const Login = () => {
  const [isWxLoginTxt, setWxLoginTxt] = useState(false)

  const refreshLoginTxt = () => {
    setWxLoginTxt(!isWxLoginTxt)
  }
  const loginTxt = isWxLoginTxt ? '微信扫码登录' : '登录'

  const menuItems: any = [
    {
      label: loginTxt,
      key: '1',
      children: (
        <div className="login-tab">
          <PLogin refreshLoginTxt={refreshLoginTxt} />
        </div>
      ),
    },
  ]

  return (
    <div className="chatgpt-login">
      <div className="chatgpt-login-top">
        <div className="chatgpt-title-nav">
          <img src={rlogoPng} alt="" />
          <span>红海云AI平台</span>
        </div>
      </div>
      <div className="chatgpt-login-main">
        <div className="chatgpt-login-welcome">欢迎来到红海云AI平台</div>
        <div className="chatgpt-login-con">
          <Tabs
            defaultActiveKey="1"
            centered
            items={menuItems.map((item, i) => {
              return item
            })}
          />
        </div>
      </div>
      <div className="chatgpt-login-bottom">
        <span>
          <em></em>
          <p>红海云AI平台</p>
          <em></em>
        </span>
        <span>©2024 红海云 保留一切权利</span>
      </div>
    </div>
  )
}
export default Login
