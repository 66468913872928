import React, { memo, useState } from 'react'
import { Input } from 'antd'
import './style.css'

const UserName = (props) => {
  const { placeholder = '请输入', onChange, maxLength = 20 } = props

  return (
    <div className="cp-user-name-input">
      <Input placeholder={placeholder} onChange={onChange} maxLength={maxLength} />
    </div>
  )
}

export default UserName
