import React, { useEffect, useState } from 'react'
import Chat, { Bubble, useMessages } from '@chatui/core'
import Charts from '@/component/echarts/comps/charts'
import './test.css'

const demo =
  '```RedChart\nBar({\n  "xAxis":{\n    "data":["张三","李四","王五","赵六","刘七","陈八","杨九","周十","许十一","吴十二"]\n  },\n  "series":[{\n    "name": "语文",\n    "data":[90,88,95,91,87,89,86,92,84,93],\n    "type":"bar"\n  }, {\n    "name": "数学",\n    "data":[85,92,78,89,86,90,84,88,87,91],\n    "type":"bar"\n  }]\n})\n```'

const initialMessages = [
  {
    type: 'text',
    content: { text: '主人好，我是智能助理，你的贴心小助手~' },
    user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
  },
]
//eslint-disable-next-line max-lines-per-function
const Test = function () {
  // 消息列表
  const { messages, appendMsg, setTyping, resetList } = useMessages(initialMessages)
  const [msgList, setMsgList] = useState(initialMessages as any)

  // 发送回调
  //eslint-disable-next-line max-lines-per-function
  function handleSend(type, val) {
    if (type === 'text' && val.trim()) {
      const list = [...msgList]
      // TODO: 发送请求
      appendMsg({
        type: 'text',
        content: { text: val },
        position: 'right',
      })
      list.push({
        type: 'text',
        content: { text: val },
        position: 'right',
      })

      setTyping(true)

      // 模拟回复消息
      setTimeout(() => {
        appendMsg({
          type: 'chart',
          content: { text: demo },
        })
        list.push({
          type: 'chart',
          content: { text: demo },
        })
        setTimeout(() => {
          setMsgList(list)
        }, 1000)
      }, 1000)
    }
  }

  useEffect(() => {
    resetList(msgList)
  }, [msgList])

  function renderMessageContent(msg) {
    console.log(msg)
    const { type, content } = msg

    // 根据消息类型来渲染
    switch (type) {
      case 'text':
        return <Bubble content={content.text} />
      case 'chart':
        return <Charts chartContent={content.text} chartId={new Date().getTime()}></Charts>
      default:
        return null
    }
  }

  return (
    <Chat
      navbar={{ title: '智能助理' }}
      messages={messages}
      renderMessageContent={renderMessageContent}
      onSend={handleSend}
    />
  )
}

export default Test
