import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Icon, toast, Modal, RichText } from '@chatui/core'
import { tokenizerFn, MAXTOKENS, DEFAULT_MODEL } from '@/utils/chatgpt'

//eslint-disable-next-line max-lines-per-function
const useHandSendMsg = (props: any) => {
  // const {} = props
  const { version, versionAuth, isThemeLight, sourceLibrary } = useSelector(
    (state: any) => state.other
  )

  const verifyToken = (postMsg) => {
    const msgTokens = tokenizerFn(JSON.stringify(postMsg))

    const maxTokens = MAXTOKENS[version]
    if (maxTokens - msgTokens < 0) {
      toast.show('输入内容过长，请重新输入。', 'error')
      return false
    }
    return true
  }

  //eslint-disable-next-line max-lines-per-function
  const getQueryContext = (noAppendMsg, queryContext, postMsg) => {
    // 拼接上下文
    const _queryContext = [] as any
    let _msgStr = ''
    // 用于追问过长时，保留第一个问题
    if (queryContext.length) {
      _queryContext.push(queryContext[0])
      _msgStr = JSON.stringify(queryContext[0])
    }
    // 重发等情况不需要再问一遍
    if (!noAppendMsg || !queryContext.length) {
      _msgStr += JSON.stringify(postMsg)
      _queryContext.push(postMsg)
    }
    let _msgStrTokens = tokenizerFn(_msgStr)
    // 判断上下文数组tokens长度是否超过模型tokens长度，从最后一个问题往前判断截取
    for (let index = queryContext.length - 1; index > 0; index--) {
      const itemStr = JSON.stringify(queryContext[index])
      const itemStrTokens = tokenizerFn(itemStr)
      const nowMsgTokens = _msgStrTokens + itemStrTokens
      const maxTokens = MAXTOKENS[version]

      console.log('nowMsgTokens', nowMsgTokens, maxTokens)

      if (nowMsgTokens < maxTokens) {
        _msgStr += itemStr
        _msgStrTokens = nowMsgTokens
        if (_queryContext.length) {
          _queryContext.splice(1, 0, queryContext[index])
        } else {
          _queryContext.unshift(queryContext[index])
        }
      }
    }
    return {
      _queryContext,
      _msgStr,
    }
  }

  return {
    verifyToken,
    getQueryContext,
  }
}

export default useHandSendMsg
