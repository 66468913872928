import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Button, useMessages, Icon, toast, Modal } from '@chatui/core'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Button as AntdButton, Tag } from 'antd'
import { DeleteOutlined, LinkOutlined, EllipsisOutlined } from '@ant-design/icons'
import { filterContext } from '@/utils/common'
import Echarts from '@/component/echarts/Echarts'
import { echartKeyword } from '@/component/echarts/systemContext'

//eslint-disable-next-line max-lines-per-function
const useRenderMsgContent = (props) => {
  const {
    renderGLM3Text,
    showTemplateDialog,
    renderDelModal,
    greetings,
    getActiveProxy,
    playAudio,
    reSend,
  } = props
  // 版本信息
  const { version, versionAuth, isThemeLight, sourceLibrary } = useSelector(
    (state: any) => state.other
  )
  const [activeMoreLink, setActiveMoreLink] = useState('')

  // 根据消息类型来渲染消息体
  //eslint-disable-next-line max-lines-per-function
  const renderMessageContent = (msg) => {
    const {
      type,
      content,
      _id,
      needAudio,
      isInit,
      questionLink: msgQuestionLink,
      agentId,
      agentLoading,
      picContent,
    } = msg
    let _msgQuestionLink = msgQuestionLink
    if (msgQuestionLink && activeMoreLink != _id) {
      _msgQuestionLink = msgQuestionLink.slice(0, 5)
    }
    const hasChart = content && content.text.includes(echartKeyword)

    switch (type) {
      case 'text':
      case 'self_msg':
        // 我的发出去的消息，text兼容旧数据
        return (
          <div className="self-msg-box">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {filterContext(content.text, picContent)}
            </ReactMarkdown>
            {renderGLM3Text(msg)}
            <div className="msg-btn-box">
              {picContent ? null : (
                <img
                  onClick={() => showTemplateDialog(content.text)}
                  title="另存为模板"
                  src={isThemeLight ? './save-icon-black.png' : './save-icon.png'}
                />
              )}

              {renderDelModal(msg)}
            </div>
          </div>
        )
      case 'loading':
        // 等待AI输出
        return <div className="loading"></div>
      case 'html':
        if (isInit) {
          // 问候语
          return <div id={_id}>{greetings}</div>
        } else {
          // 渲染MD消息体
          return (
            <div className="ai-msg-box">
              {agentId && (
                <Tag className={`agent-tag ${agentLoading && 'tag-loading'}`}>
                  {getActiveProxy(agentId, agentLoading).icon}
                  {getActiveProxy(agentId).name}
                </Tag>
              )}
              {hasChart ? (
                <Echarts chartId={_id} content={content.text}></Echarts>
              ) : (
                <div id={_id}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {filterContext(content.text)}
                  </ReactMarkdown>
                </div>
              )}
              {content.text.indexOf('企业知识库中没有包含您提问的相关资料信息') == -1 &&
                msgQuestionLink &&
                msgQuestionLink.length > 0 && (
                  <div className="link-box">
                    {_msgQuestionLink.map((link) => {
                      return (
                        <AntdButton
                          title={link.article_name}
                          size="small"
                          key={link.id}
                          icon={<LinkOutlined />}
                          ghost
                          href={link.article_address}
                          target="_blank"
                        >
                          {link.article_name}
                        </AntdButton>
                      )
                    })}
                    {activeMoreLink != _id && msgQuestionLink.length > 5 && (
                      <AntdButton
                        title="更多"
                        size="small"
                        icon={<EllipsisOutlined />}
                        ghost
                        onClick={() => setActiveMoreLink(_id)}
                      ></AntdButton>
                    )}
                  </div>
                )}
              {needAudio && (
                <div className="bullhorn-btn-box" onClick={() => playAudio(_id, content.text)}>
                  <Icon type="bullhorn" />
                </div>
              )}
            </div>
          )
        }
      case 'error':
        // 报错消息
        return (
          <div>
            <span className="red-tips">{content.text}</span>
            {_id && <Icon onClick={() => reSend(_id)} type="refresh" className="btn-refresh" />}
          </div>
        )
      default:
        return null
    }
  }

  // 自定义发送组件

  return {
    renderMessageContent,
  }
}

export default useRenderMsgContent
