import http from '../../http/fetch'

export const getUserByApi = async () => {
  const url = '/api/system/admin/self'
  const params = {}
  const res = await http.get(url, params)
  return res as any
}

export const updateUserInfoByApi = async (data) => {
  const url = '/api/system/admin/upInfo'
  const params = data
  const res = await http.post(url, params)
  return res as any
}

export const updateLoadImage = async (file) => {
  const url = '/api/upload/image'
  const formData = new FormData()
  formData.append('file', file)

  const res = await http.post(url, formData, {
    dataType: 'formData',
  })
  return res as any
}
