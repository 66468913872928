import { encode, encodeChat } from 'gpt-tokenizer'

export const tokenizerFn = (str) => {
  return encode(str).length
}

// 不同模型的最大tokens长度
export const MAXTOKENS = {
  'gpt-3.5-turbo': 4096,
  'gpt-3.5-turbo-0125': 16385,
  'gpt-4': 8192,
  'gpt-4-0125-preview': 128000,
  'gpt-4-vision-preview': 128000,
  GLM3: 32768,
  completions_pro: 5000, //文心一言
  'glm-3-turbo': 8192, //ZHIPU_GLM3
  'glm-4': 8192,
  'glm-4v': 8192,
}

export const GPT4V = 'gpt-4-vision-preview'

export const DEFAULT_MODEL = 'gpt-3.5-turbo-0125'
