import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Divider, Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Modal, toast } from '@chatui/core'
import http from '../../http/fetch'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import TemplateModal from './TemplateModal'
import { createTempApi, getTempList, deleteTempl } from '../../apis/chatflow/template'
import ThemeComp from '../../component/header/theme/Theme'

import { doSetMyMenuList } from './uts'

import './template.css'
import { useRef } from 'react'

//eslint-disable-next-line max-lines-per-function
function Template() {
  document.title = 'ChatFlow模板管理'

  const { userInfo } = useSelector((state: any) => state.user)
  const { isThemeLight } = useSelector((state: any) => state.other)

  const [delConfirmOpen, setDelConfirmOpen] = useState(false)

  const userPhoto = userInfo ? `https://vw.hr-soft.cn/uploadfile${userInfo.userPhoto}` : ''

  const [total, setTotal] = useState(0)
  const [list, setList] = useState([])

  const pageRef = useRef({
    pageNo: 1,
    singlePageSize: 12,
    pageSize: 12,
    isLoading: false,
  })

  const [activeTemplate, setActiveTemplate] = useState('')
  const [templateDialogVisit, setTemplateDialogVisit] = useState(false)

  const nextPage = () => {
    const { singlePageSize, pageNo } = pageRef.current
    pageRef.current.pageNo = pageNo + 1
    pageRef.current.pageSize = pageRef.current.pageNo * singlePageSize
    getTemplate()
  }

  const getTemplate = async () => {
    const { pageSize, isLoading } = pageRef.current
    if (isLoading) {
      return
    }
    pageRef.current.isLoading = true
    const res: any = await getTempList(1, pageSize)
    pageRef.current.isLoading = false
    if (res.code == 200) {
      setList(res.data.records)
      setTotal(res.data.total)
    }
  }

  useEffect(() => {
    getTemplate()
  }, [])

  const showTemplateDialog = (item) => {
    setActiveTemplate(item)
    setTemplateDialogVisit(true)
  }
  const closeTemplateDialog = () => {
    setActiveTemplate('')
    setTemplateDialogVisit(false)
    // getTemplate();
  }

  const showDelTemplateDialog = (item) => {
    setActiveTemplate(item)
    setDelConfirmOpen(true)
  }

  const delTemplate = async () => {
    setDelConfirmOpen(false)
    //@ts-ignore
    const res: any = await deleteTempl(activeTemplate.id)
    if (res.code == 200) {
      toast.success('删除成功')
      refreshList()
      doSetMyMenuList()
    }
  }

  const refreshList = () => {
    getTemplate()
  }

  return (
    <div
      id="scrollableDiv"
      className={`chatflow-templ-page self-def-scroll-style ${
        isThemeLight ? 'chatgpt-theme-light' : 'chatgpt-theme-dark'
      }`}
    >
      <div className="chatflow-templ-page-header">
        <div className="chatflow-templ-page-title">ChatFlow模板管理</div>
        <div className="user-box">
          <img className="user-photo" src={userPhoto} alt="" />
        </div>
      </div>
      {
        <div style={{ display: 'none' }}>
          <ThemeComp />
        </div>
      }
      <div className="chatflow-templ-add-btn" onClick={() => setTemplateDialogVisit(true)}>
        <PlusOutlined />
      </div>
      <InfiniteScroll
        dataLength={list.length}
        next={nextPage}
        hasMore={list.length < total}
        loader={
          <Divider plain>
            <Spin />
          </Divider>
        }
        endMessage={total > 8 && <Divider plain>没有更多了~</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <div className="chatflow-templ-list">
          {list?.map((item: any) => {
            return (
              <div className="chatflow-templ-items" key={item.id}>
                <div className="chatflow-templ-items-title" title={item.codeText}>
                  {item.codeText}
                </div>
                <div className="chatflow-templ-items-context">{item.message}</div>
                <div className="chatflow-templ-items-btn-box">
                  <EditOutlined title="编辑" onClick={() => showTemplateDialog(item)} />
                  <DeleteOutlined title="删除" onClick={() => showDelTemplateDialog(item)} />
                </div>
              </div>
            )
          })}
        </div>
      </InfiniteScroll>
      {templateDialogVisit && (
        <TemplateModal
          templateDialogVisit={templateDialogVisit}
          onCloseDialog={closeTemplateDialog}
          activeTemplate={activeTemplate}
          refreshList={refreshList}
        />
      )}
      <Modal
        active={delConfirmOpen}
        title="是否删除该模板"
        showClose={false}
        onClose={() => setDelConfirmOpen(false)}
        vertical={false}
        btnVariant="outline"
        actions={[
          {
            label: '确定',
            color: 'primary',
            onClick: delTemplate,
          },
          {
            label: '取消',
            onClick: () => setDelConfirmOpen(false),
          },
        ]}
      ></Modal>
    </div>
  )
}

export default Template
