export const getAllSource = (postData) => {
  const contextList = JSON.parse(postData.context || '[]')
  let sourceLists: any = []
  contextList.forEach((item) => {
    if (item.sourceLibrary) {
      sourceLists = [].concat(sourceLists, item.sourceLibrary)
    }
  })
  //@ts-ignore
  const _sourceList = [...new Set(sourceLists)]

  return JSON.stringify(_sourceList)
}

// 图片MD内容转成上下文
export const picContentConvertPostContent = (picContent) => {
  // 用于匹配文本和图像的正则表达式
  const imageRegex = /!\[image.*?\]\(([^)]+)\)/g
  const textRegex = /(?:^|\n)([^!\n][^\n]*)/g

  // 结果数组
  const result = [] as any

  // 索引用于帮助跟踪当前文本片段或图片的位置
  let lastIndex = 0

  // 遍历所有的图像匹配项
  picContent.replace(imageRegex, (match, imageUrl, index) => {
    // 提取文本内容
    const text = picContent.slice(lastIndex, index).match(textRegex)
    if (text) {
      // 将文本内容添加到结果数组
      text.forEach((t) => {
        if (t.trim()) {
          result.push({ type: 'text', text: t.trim() })
        }
      })
    }

    // 将图像URL添加到结果数组
    result.push({
      type: 'image_url',
      image_url: { url: 'https://vw.hr-soft.cn' + imageUrl.trim() },
    })

    // 更新上次匹配的索引位置
    lastIndex = index + match.length
  })

  // 处理最后一个图片后的剩余文本（如果有）
  const remainingText = picContent.slice(lastIndex).match(textRegex)
  if (remainingText) {
    remainingText.forEach((t) => {
      if (t.trim()) {
        result.push({ type: 'text', text: t.trim() })
      }
    })
  }
  return result
}
