import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Button, useMessages, Icon, toast, Modal } from '@chatui/core'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Button as AntdButton, Tag } from 'antd'
import { DeleteOutlined, LinkOutlined, EllipsisOutlined } from '@ant-design/icons'
import { filterContext } from '@/utils/common'

//eslint-disable-next-line max-lines-per-function
const useRenderMsgContent = (props) => {
  const { showTemplateDialog, renderDelModal, reSend } = props
  // 版本信息
  const { version, versionAuth, isThemeLight, sourceLibrary } = useSelector(
    (state: any) => state.other
  )
  const [activeMoreLink, setActiveMoreLink] = useState('')

  // 根据消息类型来渲染消息体
  //eslint-disable-next-line max-lines-per-function
  const renderMessageContent = (msg) => {
    const {
      type,
      content,
      _id,
      needAudio,
      isInit,
      questionLink: msgQuestionLink,
      agentId,
      agentLoading,
      picContent,
    } = msg
    let _msgQuestionLink = msgQuestionLink
    if (msgQuestionLink && activeMoreLink != _id) {
      _msgQuestionLink = msgQuestionLink.slice(0, 5)
    }
    switch (type) {
      case 'text':
      case 'self_msg':
        // 我的发出去的消息，text兼容旧数据
        return (
          <div className="self-msg-box">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {filterContext(content.text, picContent)}
            </ReactMarkdown>
            <div className="msg-btn-box">{renderDelModal(msg)}</div>
          </div>
        )
      case 'loading':
        // 等待AI输出
        return <div className="loading"></div>
      case 'html':
        // 渲染MD消息体
        return (
          <div className="ai-msg-box">
            <div id={_id}>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {filterContext(content.text)}
              </ReactMarkdown>
            </div>
          </div>
        )
      case 'error':
        // 报错消息
        return (
          <div>
            <span className="red-tips">{content.text}</span>
            {_id && <Icon onClick={() => reSend(_id)} type="refresh" className="btn-refresh" />}
          </div>
        )
      default:
        return null
    }
  }

  // 自定义发送组件

  return {
    renderMessageContent,
  }
}

export default useRenderMsgContent
