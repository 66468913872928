import React, { memo } from 'react'

import './style.css'

const LoginBtn = (props) => {
  const { onClick, title, isBtnActive: isActive } = props
  const cls = isActive ? 'cp-login-btn cp-login-btn-active' : 'cp-login-btn'
  return (
    <div className="cp-login-btn-box" onClick={onClick}>
      <div className={cls}>{title}</div>
    </div>
  )
}

export default LoginBtn
