import http from '../../http/fetch'

export const login = async ({ phone, password, code }) => {
  const url = '/api/system/login/ai'
  const params = {
    username: phone,
    password: password,
    smsCode: code,
  }
  const res = await http.postJson(url, params)
  return res as any
}

export const register = async ({ phone, code, pwd, pwd2, invitCode }) => {
  const url = '/api/system/register'
  const params = {
    username: phone,
    password: pwd,
    passwordConfirm: pwd2,
    smsCode: code,
    invitationCode: invitCode,
  }
  const res = await http.postJson(url, params)
  return res as any
}

/**
 *
 * @param mobile
 * @param scene 场景 [101=登录验证码, 102=注册/绑定手机验证码, 103=变更手机验证码, 104=找回登录密码验证码]
 */
export const smsSend = async (mobile, scene) => {
  const url = '/api/sms/send'
  const params = {
    mobile,
    scene,
  }
  const res = await http.postJson(url, params)
  return res
}

export const forgotPwd = async (phone, code, pwd) => {
  const url = '/api/login/forgotPassword'
  const params = {
    mobile: phone,
    code: code,
    password: pwd,
  }
  const res = await http.postJson(url, params)
  return res as any
}

export const getInviteCode = async () => {
  const url = 'http://localhost:8082/api/gpt/genCode'
  const params = {}
  const res = await http.postJson(url, params)
  return res as any
}
