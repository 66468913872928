import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useWxScan from './useWxScan'
const isPublicOut = process.env.REACT_APP_FOR_OUT == '1'
import { isWeixinBrowser } from '../uts'
import { getWxAccess } from '@/apis/wx-bind/index'

//eslint-disable-next-line max-lines-per-function
const useWxBrowserLogin = () => {
  const { isWxScanLogin, isScanHasToken } = useWxScan()
  const [showSpin, setShowSpin] = useState(false)
  const jumpAccessPage = async () => {
    if (isWeixinBrowser()) {
      if (!isWxScanLogin() && !isScanHasToken()) {
        setShowSpin(true)
        // const res = await getWxAccess()
        // window.location.href = res.data
        window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9c7f9194d6f339dd&redirect_uri=https%3A%2F%2Fai.hr-soft.cn%2Fapi%2Fsystem%2Fsso%2Flogin&response_type=code&scope=snsapi_userinfo&state=https%3A%2F%2Fai.hr-soft.cn&connect_redirect=1#wechat_redirect'
      } else {
        setShowSpin(false)
      }
    }
  }

  useEffect(() => {
    jumpAccessPage()
  }, [])

  return {
    showSpin,
    setShowSpin,
  }
}

export default useWxBrowserLogin
