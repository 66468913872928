import { SET_HISTROY_LIST, SET_ACTIVE_HISTROY } from '../actions/histroy'

const initState = {
  historyList: [],
  activeHistory: null,
}

const histroyReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_HISTROY_LIST:
      return {
        ...state,
        historyList: action.payload,
      }
    case SET_ACTIVE_HISTROY:
      return {
        ...state,
        activeHistory: action.payload,
      }
    default:
      return state
  }
}

export default histroyReducer
