import React, { useRef, useCallback } from 'react'
import './style.css'

//eslint-disable-next-line max-lines-per-function
export const useSelectFile = (props?: {
  fileType?: string
  multiple?: boolean
  maxCount?: number
}) => {
  const { fileType = '*', multiple = false, maxCount = 10 } = props || {}

  const SelectFileDom = useRef<HTMLInputElement>(null)
  const openSign = useRef<any>()

  const File = useCallback(
    ({ onSelect }: { onSelect: (e: File[], sign?: any) => void }) => (
      <div className="select-file-hook">
        <input
          ref={SelectFileDom}
          type="file"
          accept={fileType}
          multiple={multiple}
          onChange={(e) => {
            console.log('e.target.files', e)

            if (!e.target.files || e.target.files?.length === 0) return

            onSelect(Array.from(e.target.files), openSign.current)
          }}
        />
      </div>
    ),
    [fileType, maxCount, multiple]
  )

  const onOpen = useCallback((sign?: any) => {
    openSign.current = sign
    SelectFileDom.current && SelectFileDom.current.click()
  }, [])

  return {
    File,
    onOpen,
  }
}
