import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Button, useMessages, Icon, toast, Modal } from '@chatui/core'
import Proxy from '@/component/proxy/Proxy'
import { echartProxy } from '@/component/echarts/systemContext'
import * as Icons from '@ant-design/icons'

//eslint-disable-next-line max-lines-per-function
const useProxyList = (props: any) => {
  const { stopBtnShow } = props
  // 用户信息
  const { version, versionAuth, isThemeLight, sourceLibrary } = useSelector(
    (state: any) => state.other
  )
  const { userInfo } = useSelector((state: any) => state.user)
  // 会话列表
  const { activeHistory, historyList } = useSelector((state: any) => state.histroy)

  const hasProxyAuth = userInfo && versionAuth.indexOf(userInfo.userId) !== -1

  const [proxy, setProxy] = useState(null as any)
  const [proxyList, setProxyList] = useState([] as any)

  const updateProxy = (_proxy) => {
    setProxy(_proxy)
  }
  const updateProxyList = (list) => {
    setProxyList(list)
  }
  useEffect(() => {
    if (activeHistory) {
      const msgList = (activeHistory.context && JSON.parse(activeHistory.context)) || []
      const lastMsg = msgList.length && msgList[msgList.length - 1]
      if (lastMsg && lastMsg.agentId == 'chartTool') {
        setProxy(echartProxy)
      } else {
        setProxy(null)
      }
    }
  }, [activeHistory])

  const renderProxyList = () => {
    if (!hasProxyAuth) {
      return null
    }
    return (
      <Proxy
        stopBtnShow={stopBtnShow}
        proxy={proxy}
        onUpdateProxy={updateProxy}
        onUpdateProxyList={updateProxyList}
      />
    )
  }

  const getActiveProxy = (agentId, agentLoading?) => {
    let proxy = null as any
    proxyList.forEach((item) => {
      if (item.agentId == agentId) {
        proxy = item
      }
    })
    return {
      icon: agentLoading
        ? React.createElement(Icons['SettingOutlined'])
        : React.createElement(Icons[proxy.icon]),
      name: proxy.name,
    }
  }

  return {
    renderProxyList,
    proxy,
    setProxy,
    getActiveProxy,
  }
}

export default useProxyList
