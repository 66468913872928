import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as echarts from 'echarts'
import { echartKeyword } from '@/component/echarts/systemContext'
import './charts.css'

//eslint-disable-next-line max-lines-per-function
const Charts = (props: any) => {
  const { isThemeLight } = useSelector((state: any) => state.other)
  const { chartContent, chartId } = props
  const [pieType, setPieType] = useState('pie')
  const [funnelSort, setFunnelSort] = useState('descending')
  const [activeEchart, setActiveEchart] = useState('')
  const myChart = useRef(null as any)

  useEffect(() => {
    setActiveEchart(chartId)
  }, [])

  useEffect(() => {
    initChart()
  }, [activeEchart])

  useEffect(() => {
    if (myChart.current) {
      myChart.current.dispose()
      initChart()
    }
  }, [isThemeLight, pieType, funnelSort])

  // 预处理gpt返回的消息数据
  const findContent = (str) => {
    const matches = str.match(/(\w+)\(([^)]+)\)/)
    if (matches) {
      const functionName = matches[1]
      const content = matches[2]
      try {
        return {
          chartType: functionName.toLowerCase(),
          chartData: JSON.parse(content),
        }
      } catch (err) {
        return {
          chartType: null,
          chartData: null,
        }
      }
    } else {
      return {
        chartType: null,
        chartData: null,
      }
    }
  }

  //eslint-disable-next-line max-lines-per-function
  const initChart = () => {
    const chartDom = document.getElementById(activeEchart)
    if (!chartDom) return
    const { chartType, chartData } = findContent(chartContent) as any
    if (chartType && chartData) {
      // 基于准备好的dom，初始化echarts实例
      myChart.current = echarts.init(chartDom, isThemeLight ? '' : 'dark')
      // myChart.current = echarts.init(chartDom)
      if (chartType == 'line' || chartType == 'bar') {
        initLineChart(chartData, chartType)
      } else if (chartType == 'pie') {
        initPieChart(chartData)
      } else if (chartType == 'funnel') {
        initFunnelChart(chartData)
      } else if (chartType == 'treemap') {
        initTreemapChart(chartData)
      }
    }
  }

  // 初始化折线图/柱状图
  //eslint-disable-next-line max-lines-per-function
  const initLineChart = (chartData, chartType) => {
    const magicType = [chartType == 'line' ? 'bar' : 'line']
    const { xAxis, series } = transformLineData(chartData, chartType)
    if (series.length > 1) {
      magicType.push('stack')
    }
    myChart.current.setOption({
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        top: 'bottom',
      },
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          magicType: { type: magicType },
          restore: {},
          saveAsImage: {},
        },
      },
      yAxis: {
        type: 'value',
      },
      xAxis,
      series,
    })
  }
  // 处理折线图/柱状图数据
  //eslint-disable-next-line max-lines-per-function
  const transformLineData = (data: any, type) => {
    const xAxis = data.xAxis || {}
    const series = data.series || []
    try {
      if (series.length > 1) {
        series.forEach((seriesItem) => {
          seriesItem['label'] = { show: true }
        })
      } else {
        series.forEach((seriesItem) => {
          seriesItem['label'] = { show: true }
          seriesItem['itemStyle'] = {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#188df0' },
              { offset: 1, color: '#83bff6' },
            ]),
          }
        })
      }
    } catch (err) {
      console.log(err)
    }
    return { xAxis, series }
  }
  // 初始化饼图
  //eslint-disable-next-line max-lines-per-function
  const initPieChart = (chartData) => {
    const pieData = chartData.data || []
    const feature = {
      myTool1: {
        show: true,
        title: '饼图',
        iconStyle: {
          borderWidth: 0.6,
        },
        icon: 'path://M512 98.304C282.624 98.304 98.304 282.624 98.304 512s184.32 413.696 413.696 413.696c229.376 0 413.696-184.32 413.696-413.696S741.376 98.304 512 98.304zM888.832 491.52l-331.776 0 233.472-233.472C847.872 323.584 884.736 401.408 888.832 491.52zM512 888.832c-208.896 0-376.832-167.936-376.832-376.832 0-208.896 167.936-376.832 376.832-376.832 98.304 0 184.32 36.864 253.952 98.304l-266.24 266.24c-4.096 4.096-4.096 8.192-4.096 12.288 0 12.288 8.192 20.48 20.48 20.48l376.832 0C876.544 729.088 712.704 888.832 512 888.832z',
        onclick: function () {
          setPieType('pie')
        },
      },
      myTool2: {
        show: true,
        title: '环形图',
        iconStyle: {
          borderWidth: 0.6,
        },
        icon: 'path://M302.72 189.984A383.712 383.712 0 0 0 128.32 496h128.16a255.84 255.84 0 0 1 114.944-197.984L302.72 189.984z m27.52-16.32l68.992 108.48A254.944 254.944 0 0 1 512 256c73.056 0 138.976 30.592 185.6 79.68l91.2-89.824A382.88 382.88 0 0 0 512 128c-65.76 0-127.648 16.512-181.76 45.632zM128.32 528C136.704 732.672 305.28 896 512 896v-128a256 256 0 0 1-255.52-240H128.32z m681.728-258.144l-91.84 90.432A256.032 256.032 0 0 1 544 766.048v128.64C741.12 878.4 896 713.28 896 512a382.4 382.4 0 0 0-85.952-242.144zM512 928C282.24 928 96 741.76 96 512S282.24 96 512 96s416 186.24 416 416-186.24 416-416 416z m0-192a224 224 0 1 0 0-448 224 224 0 0 0 0 448z',
        onclick: function () {
          setPieType('loop')
        },
      },
      myTool3: {
        show: true,
        title: '南丁格尔玫瑰图',
        iconStyle: {
          borderWidth: 0.6,
        },
        icon: 'path://M578.52 480.17H821V470a264.79 264.79 0 0 0-71.15-181.32L847.56 191l-7.19-7.19C763.86 107.41 662.15 65.31 554 65.31h-10.18v67.15a335.52 335.52 0 0 0-221.52 91.69l-99.77-99.69a10.17 10.17 0 0 0-14.37 0C115.8 216.76 64.93 339.48 64.93 470a10.16 10.16 0 0 0 10.16 10.16h74c2.55 104.3 44.41 202 118.5 276l7.18 7.18 47.52-47.48a335.53 335.53 0 0 0 221.52 91.68v151.15H554c130.63 0 253.43-50.83 345.81-143.13l7.19-7.18z m221.91-20.32h-221.9l43-42.94 113.91-113.85a244.39 244.39 0 0 1 65 156.79zM564.14 85.76a382 382 0 0 1 254.57 105.36L564.14 445.49V85.76z m-20.32 67v292.73L379.56 281.36l-42.89-42.86a315.34 315.34 0 0 1 207.15-85.72z m-328.39-6.69l92.38 92.31L501.58 432l27.85 27.83H85.36a464.73 464.73 0 0 1 130.07-313.75z m-46 334.09h360l-45.55 45.51L307.8 701.62l-32.93 32.91a381.38 381.38 0 0 1-105.45-254.36z m167.24 221.36l207.15-207v292.72a315.34 315.34 0 0 1-207.15-85.72z m227.47 236.74V494.53l314 313.77a465.48 465.48 0 0 1-314 129.96z',
        onclick: function () {
          setPieType('rose')
        },
      },
      iconStyle: {
        borderWidth: 0,
      },
      mark: { show: true },
      restore: { show: true },
      saveAsImage: { show: true },
    }
    if (pieType == 'pie') {
      feature.myTool1.iconStyle['borderColor'] = '#3D97C4'
    } else if (pieType == 'loop') {
      feature.myTool2.iconStyle['borderColor'] = '#3D97C4'
    } else if (pieType == 'rose') {
      feature.myTool3.iconStyle['borderColor'] = '#3D97C4'
    }
    myChart.current.setOption({
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: 'bottom',
      },
      toolbox: {
        show: true,
        feature,
      },
      series: [
        {
          type: 'pie',
          radius: pieType == 'pie' ? '50%' : pieType == 'loop' ? ['30%', '50%'] : ['15%', '50%'],
          roseType: pieType == 'rose' ? 'area' : '',
          data: pieData,
        },
      ],
    })
  }
  // 初始化漏斗图
  //eslint-disable-next-line max-lines-per-function
  const initFunnelChart = (chartData) => {
    const funnelData = chartData.data || []
    const iconStyle = {
      borderWidth: 0.6,
    }
    if (funnelSort == 'ascending') {
      iconStyle['borderColor'] = '#3D97C4'
    }
    myChart.current.setOption({
      tooltip: {
        trigger: 'item',
      },
      toolbox: {
        show: true,
        feature: {
          myTool1: {
            show: true,
            title: funnelSort == 'descending' ? '升序' : '降序',
            icon: 'path://M320 938.666667a21.333333 21.333333 0 0 1-21.333333-21.333334V158.166667L164.42 292.42a21.333333 21.333333 0 0 1-30.173333-30.173333l170.666666-170.666667A21.333333 21.333333 0 0 1 341.333333 106.666667v810.666666a21.333333 21.333333 0 0 1-21.333333 21.333334z m399.086667-6.246667l170.666666-170.666667a21.333333 21.333333 0 0 0-30.173333-30.173333L725.333333 865.833333V106.666667a21.333333 21.333333 0 0 0-42.666666 0v810.666666a21.333333 21.333333 0 0 0 36.42 15.086667z',
            iconStyle,
            onclick: function () {
              setFunnelSort(funnelSort == 'descending' ? 'ascending' : 'descending')
            },
          },
          mark: { show: true },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        top: 'bottom',
      },
      series: [
        {
          type: 'funnel',
          left: '10%',
          top: 60,
          bottom: 60,
          width: '80%',
          sort: funnelSort, //  ascending/descending
          label: {
            show: true,
            color: '#000',
            position: 'inside',
            formatter: '{b} : {c}',
          },
          emphasis: {
            label: {
              fontSize: 16,
            },
          },
          data: funnelData,
        },
      ],
    })
  }
  // 初始矩形树图
  //eslint-disable-next-line max-lines-per-function
  const initTreemapChart = (chartData) => {
    const treemapData = chartData.data || []
    myChart.current.setOption({
      tooltip: {
        trigger: 'item',
        formatter: function (info) {
          const value = info.value
          const treePathInfo = info.treePathInfo
          const treePath = [] as any
          for (let i = 1; i < treePathInfo.length; i++) {
            treePath.push(treePathInfo[i].name)
          }
          return (
            '<div class="tooltip-title">' +
            echarts.format.encodeHTML(treePath.join(' / ')) +
            '：' +
            echarts.format.addCommas(value) +
            '</div>'
          )
        },
      },
      toolbox: {
        show: true,
        feature: {
          mark: { show: true },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      series: [
        {
          name: '全部',
          type: 'treemap',
          roam: 'move',
          visibleMin: 300,
          label: {
            show: true,
            formatter: '{b}',
          },
          upperLabel: {
            show: true,
            height: 30,
          },
          itemStyle: {
            borderColor: '#fff',
          },
          levels: getLevelOption(),
          data: treemapData,
        },
      ],
    })
  }
  //eslint-disable-next-line max-lines-per-function
  function getLevelOption() {
    return [
      {
        itemStyle: {
          borderColor: '#777',
          borderWidth: 0,
          gapWidth: 1,
        },
        upperLabel: {
          show: false,
        },
      },
      {
        itemStyle: {
          borderColor: '#555',
          borderWidth: 5,
          gapWidth: 1,
        },
        emphasis: {
          itemStyle: {
            borderColor: '#ddd',
          },
        },
      },
      {
        colorSaturation: [0.35, 0.5],
        itemStyle: {
          borderWidth: 5,
          gapWidth: 1,
          borderColorSaturation: 0.6,
        },
      },
    ]
  }

  const resize = () => {
    myChart.current.resize()
  }

  useEffect(() => {
    window.addEventListener('resize', resize) // 添加全局事件
    return () => {
      window.removeEventListener('resize', resize) // 销毁
    }
  }, [])

  return <div className="chat-echart-box" id={activeEchart}></div>
}

export default Charts
