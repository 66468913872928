import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Button, useMessages, Icon, toast, Modal } from '@chatui/core'
import { DeleteOutlined, LinkOutlined, EllipsisOutlined } from '@ant-design/icons'
import PubSub from 'pubsub-js'

//eslint-disable-next-line max-lines-per-function
const useDelQuestion = (props: any) => {
  const { deleteMsg, messages, activeHistory, updateHistory } = props
  const [delConfirmOpen, setDelConfirmOpen] = useState(false)
  const [delMsg, setDelMsg] = useState(null)
  const showDelConfirm = (delMsg) => {
    setDelMsg(delMsg)
    setDelConfirmOpen(true)
  }
  const delQA = () => {
    const msg: any = delMsg
    deleteMsg(msg._id)
    let delIndex = -1
    let nextMsg: any = null
    messages.forEach((item, index) => {
      if (item._id == msg._id) {
        delIndex = index
      }
    })
    if (
      messages[delIndex + 1] &&
      (messages[delIndex + 1].type == 'html' || messages[delIndex + 1].type == 'error')
    ) {
      nextMsg = messages[delIndex + 1]
    }
    const _context = JSON.parse(activeHistory.context)
    // context不包含欢迎语，需再减1
    if (nextMsg) {
      deleteMsg(nextMsg._id)
      _context.splice(delIndex - 1, 2)
    } else {
      _context.splice(delIndex - 1, 1)
    }
    updateHistory({
      id: activeHistory.id,
      context: JSON.stringify(_context),
    })
    setDelConfirmOpen(false)
    PubSub.publish('resetSuggstionSetting')
  }

  const renderDelModal = (msg) => {
    return (
      <>
        <DeleteOutlined title="删除该问答" onClick={() => showDelConfirm(msg)} />
        <Modal
          active={delConfirmOpen}
          title="是否删除该问答"
          showClose={false}
          onClose={() => setDelConfirmOpen(false)}
          vertical={false}
          btnVariant="outline"
          actions={[
            {
              label: '确定',
              color: 'primary',
              onClick: () => delQA(),
            },
            {
              label: '取消',
              onClick: () => setDelConfirmOpen(false),
            },
          ]}
        ></Modal>
      </>
    )
  }

  return {
    renderDelModal,
  }
}

export default useDelQuestion
