// 微信扫码登录
const loginWxQrCodeCss = `
.impowerBox .qrcode {width: 260px;}
.impowerBox .title {display: none;}
.impowerBox .info {width: 260px;}
.status_icon {display: none}
.impowerBox .status {display: none !important;}
`
export const loginBase64Css = window.btoa(loginWxQrCodeCss)

export const wxLoginConfig = {
  appid: 'wx53d5101043ed9e20',
  scope: 'snsapi_login',
  state: 'c99f4d8a40c84dd4a2df1ed431fb5ee9',
}

export const getRedirectUrl = () => {
  // const isLocal = location.href.includes('localhost') || location.href.includes('nat.51hrc.cn');
  const isLocal = false
  const baseUrl = isLocal ? `http://liuchun.nat.51hrc.cn:18008` : `https://ai.hr-soft.cn`
  const redirect_uri = `${baseUrl}/api/system/scanLogin`

  const _url = encodeURIComponent(redirect_uri)
  return _url
}
