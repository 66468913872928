import { useDispatch, useSelector } from 'react-redux'
import { setHistroyList, setActiveHistroy } from '@/store/actions/histroy'
import http from '@/http/fetch'

//eslint-disable-next-line max-lines-per-function
const useUpdateActiveHistory = (props: any) => {
  const { activeHistoryId, questionLink, proxy } = props

  const dispatch = useDispatch()
  const { activeHistory, historyList } = useSelector((state: any) => state.histroy)
  const { sourceLibrary } = useSelector((state: any) => state.other)

  const getAllSource = (postData) => {
    const contextList = JSON.parse(postData.context || '[]')

    let sourceLists: any = []
    contextList.forEach((item) => {
      if (item.sourceLibrary) {
        sourceLists = [].concat(sourceLists, item.sourceLibrary)
      }
    })
    //@ts-ignore
    const _sourceList = [...new Set(sourceLists)]

    return JSON.stringify(_sourceList)
  }

  const queryContextById = (id) => {
    return http.get('/fastdev/chatgpt/queryContextById', {
      id,
    })
  }

  // 更新会话
  //eslint-disable-next-line max-lines-per-function
  const updateHistory = (postData) => {
    const sourceLists = getAllSource(postData)
    postData.contextType = sourceLists
    //eslint-disable-next-line max-lines-per-function
    http.post('/fastdev/chatgpt/saveOrUpdate', postData).then((res) => {
      // 新增历史记录

      if (!postData.id && res.data) {
        const _historyList = JSON.parse(JSON.stringify(historyList))
        const updateIndex = historyList.findIndex((item) => {
          return item.id == activeHistoryId.current
        })
        _historyList[updateIndex].id = res.data
        _historyList[updateIndex].context = postData.context
        _historyList[updateIndex].isNew = null
        dispatch(setHistroyList(_historyList))
      }
      // 删除或更新内容要同步activeHistory，如果已经切换窗口则不需要
      if (postData.context && activeHistoryId.current == activeHistory.id) {
        const _activeHistory = {
          ...activeHistory,
          context: postData.context,
        }
        if (!postData.id) {
          _activeHistory.id = res.data
        }
        dispatch(setActiveHistroy(_activeHistory))
      }
      // 更新标题
      if (postData.id && postData.title && !postData.context) {
        const _historyList = [...historyList]
        const updateIndex = historyList.findIndex((item) => {
          return item.id == postData.id
        })
        _historyList[updateIndex].title = postData.title
        dispatch(setHistroyList(_historyList))
        if (activeHistoryId.current == activeHistory.id) {
          const _activeHistory = {
            ...activeHistory,
            title: postData.title,
          }
          dispatch(setActiveHistroy(_activeHistory))
        }
      }
      activeHistoryId.current = activeHistory && activeHistory.id
    })
  }

  //eslint-disable-next-line max-lines-per-function
  const updateActiveHistory = async (obj: any) => {
    const { selfMsg, question } = obj
    const _activeIndex = historyList.findIndex((item) => {
      return item.id == activeHistoryId.current
    })
    let _activeHistory = historyList[_activeIndex]
    // 列表无数据，需要重新获取上一个会话的内容
    if (_activeHistory && !_activeHistory.isNew) {
      const res = await queryContextById(activeHistoryId.current)
      _activeHistory = res.data
    }
    const _context = (_activeHistory?.context || '[]') as any
    const _queryContext = JSON.parse(_context)

    _queryContext.push(selfMsg)
    const contextItem = {
      type: 'html',
      content: {
        text: question,
      },
      needAudio: selfMsg.needAudio,
      sourceLibrary: sourceLibrary,
      agentId: proxy ? proxy.agentId : '',
    }
    if (questionLink) {
      contextItem['questionLink'] = questionLink
    }
    _queryContext.push(contextItem)
    if (_activeHistory) {
      updateHistory({
        id: _activeHistory.isNew ? null : _activeHistory.id,
        title: _activeHistory.title,
        context: JSON.stringify(_queryContext),
      })
    }
  }
  return {
    updateHistory,
    updateActiveHistory,
  }
}

export default useUpdateActiveHistory
