import React, { memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserName from '@/pages/comps/userName'
import VerifyCode from '@/pages/comps/verifyCode'
import UserPassword from '@/pages/comps/userPassword'
import LoginBtn from '@/pages/comps/loginBtn'
import ErrorTip from '@/pages/comps/errorTip'
import useVerifyLoign from '@/hooks/verify/useVerifyLogin'
import useRouter from '@/hooks/router/useRouter'
import './style.css'
import { login } from '@/apis/login/login'

//@ts-ignore
import wxLoginSvg from '@/assets/img/wx-login.svg'
//@ts-ignore
import accountLoginSvg from '@/assets/img/pwd-login.svg'
import { loginBase64Css, getRedirectUrl, wxLoginConfig } from './wx-login-config'
import Cookies from 'js-cookie'

//eslint-disable-next-line max-lines-per-function
const PLogin = (props: any) => {
  const {
    phone,
    password,
    code,
    isChecked,
    errTip,
    isBtnActive,
    onChangePassWord,
    verifyInputOk,
    onChangePhone,
    getMsgCodeTip,
    getLoginMsgCode,
    onChangeCode,
  } = useVerifyLoign()
  const { history, query, historyPush } = useRouter()
  const dispatch = useDispatch()
  const [isWxloginType, setLoginType] = useState(false)
  const { refreshLoginTxt } = props

  const toLogin = async () => {
    if (!verifyInputOk()) {
      return
    }

    const res = await login({
      code,
      phone,
      password,
    })
    const token = res.data.token
    Cookies.set('REDSEA-TOKEN', token, {
      expires: 30,
    })
    historyPush(`/app-list`, {})
  }

  const goRegister = () => {
    historyPush(`/register`, {})
  }

  const toggleToWxLogin = () => {
    setLoginType(!isWxloginType)
    refreshLoginTxt()
  }

  useEffect(() => {
    if (isWxloginType) {
      genWxLogin()
    }
  }, [isWxloginType])

  const genWxLogin = () => {
    const _url = getRedirectUrl()
    const { appid, scope, state } = wxLoginConfig
    //@ts-ignore
    new WxLogin({
      id: 'login_container',
      appid,
      scope,
      redirect_uri: _url,
      state,
      style: 'black',
      href: `data:text/css;base64,${loginBase64Css}`,
    })
  }

  const renderAccountLogin = () => {
    return (
      <>
        <div className={''}>
          <UserName placeholder="手机号码" onChange={onChangePhone} maxLength={11} />
        </div>
        <div className={'mt-20'}>
          <UserPassword placeholder="密码" onChange={onChangePassWord} maxLength={12} />
        </div>
        <div className={'mt-20'}>
          <VerifyCode
            placeholder="验证码"
            phone={phone}
            callbackTip={getMsgCodeTip}
            getMsgCode={getLoginMsgCode}
            onChange={onChangeCode}
            maxLength={6}
          />
        </div>

        {errTip ? <ErrorTip errTip={errTip} /> : ''}

        <div className="mt-20">
          <LoginBtn onClick={toLogin} title="登录" isBtnActive={isBtnActive}></LoginBtn>
        </div>
      </>
    )
  }

  const renderWxLogin = () => {
    return (
      <div className="wx-scan">
        <div id="login_container"></div>
      </div>
    )
  }

  return (
    <div className={'chatgpt-phone-login'}>
      {isWxloginType ? renderWxLogin() : renderAccountLogin()}
      {isWxloginType ? (
        <div className="cp-to-wx-login" onClick={toggleToWxLogin}>
          <span>账号密码登录</span>
          <span>
            <img src={accountLoginSvg} alt="" className="wx-login-logo" />
          </span>
        </div>
      ) : (
        <div className="cp-to-wx-login" onClick={toggleToWxLogin}>
          <span>微信扫码登录</span>
          <span>
            <img src={wxLoginSvg} alt="" className="wx-login-logo" />
          </span>
        </div>
      )}
      <div className="cp-to-login">
        <span>还没有账号？</span>
        <span className="do-login" onClick={goRegister}>
          立即注册
        </span>
      </div>
    </div>
  )
}
export default PLogin
