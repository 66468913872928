import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import mermaid from 'mermaid'
import { copyFn } from '@/utils/common'

//eslint-disable-next-line max-lines-per-function
export default function useHighLight(props) {
  const { isThemeLight, stopBtnShow, messages } = props
  // 设置mermaid主题
  useEffect(() => {
    if (isThemeLight) {
      mermaid.initialize({
        startOnLoad: true,
        gantt: {
          axisFormat: '%Y-%m-%d',
        },
      })
    } else {
      mermaid.initialize({
        startOnLoad: true,
        theme: 'dark',
        gantt: {
          axisFormat: '%Y-%m-%d',
        },
      })
    }
  }, [isThemeLight])

  useEffect(() => {
    if (!stopBtnShow) {
      highLightCode(mermaid)
    }
  }, [messages])

  //eslint-disable-next-line max-lines-per-function
  const highLightCode = (mermaid) => {
    // 高亮代码
    //@ts-ignore
    document
      .querySelectorAll('.chat-box pre code:not([class*="language-mermaid"])')
      //@ts-ignore
      .forEach((el) => hljs.highlightElement(el)) // eslint-disable-line no-undef
    // 遍历pre添加复制按钮
    // const preListAll = document.querySelectorAll('.chat-box pre:has(code)')
    const preBlocks = document.querySelectorAll('.chat-box pre')

    const preList = [] as any
    for (let i = 0; i < preBlocks.length; i++) {
      if (preBlocks[i].querySelector('code')) {
        // 添加样式规则
        preList.push(preBlocks[i])
      }
    }
    preList.forEach((el: any) => {
      if (el.querySelector('.copy-btn') || el.querySelector('code.language-mermaid')) {
        return
      }
      const divDom = document.createElement('div')
      divDom.title = '复制'
      divDom.className = 'btn copy-btn'
      divDom.innerHTML = '<img src="./copy.png">'
      divDom.addEventListener('click', (e) => {
        const code = el.firstChild.innerHTML // 获取代码标签内的HTML字符串
        const pureCode = code.replace(/<\/?[^>]+>/gi, '') // 去除所有HTML标签
        const preEl = document.createElement('pre') // 创建pre标签元素
        preEl.innerHTML = pureCode // 将纯代码字符串赋值给pre标签的innerHTML属性
        const decodedCode = preEl.innerText
        copyFn(decodedCode)
      })
      el.append(divDom)
    })
    mermaidDomFn(mermaid)
  }

  // 替换mermaid语法
  //eslint-disable-next-line max-lines-per-function
  const mermaidDomFn = (mermaid) => {
    const codeTags = document.querySelectorAll('code.language-mermaid') as any
    async function renderData() {
      const svgList = [] as any
      for (let i = 0; i < codeTags.length; i++) {
        const svg = await new Promise((resolve, reject) => {
          const codeEl = codeTags[i]
          const code = codeEl.textContent
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&amp/g, '&')
          mermaid
            .render('mermaid-svg', code)
            .then((res) => {
              resolve(res.svg)
            })
            .catch((err) => {
              console.log(err)
              resolve('')
            })
        })
        svgList.push(svg)
      }
      if (svgList.length) {
        for (let i = 0; i < codeTags.length; i++) {
          const parentNode = codeTags[i].parentNode
          if (parentNode && svgList[i]) {
            parentNode.innerHTML = svgList[i]
          }
        }
      }
    }
    renderData()
  }
  return {}
}
