import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, Input, Button } from '@chatui/core'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import 'react-virtualized/styles.css'
import { List, AutoSizer } from 'react-virtualized'
import './GlobalSearch.css'
import { Avatar } from '@chatui/core'
import { setHistroyList, setActiveHistroy } from '@/store/actions/histroy'
import { filterContext } from '@/utils/common'

import http from '@/http/fetch'
import dayjs from 'dayjs'

const isPublicHuman = process.env.REACT_APP_FOR_HUMAN == '1'

let searchTimer = null as any
const baseUrl = location.href.includes('localhost') ? 'http://192.168.101.186:8199' : ''
//eslint-disable-next-line max-lines-per-function
function GlobalSearchComp(props) {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state: any) => state.user)
  const userPhoto = userInfo
    ? `https://vw.hr-soft.cn/uploadfile${userInfo.userPhoto}`
    : 'https://vw.hr-soft.cn/uploadfile/tech/2022/11/13/1a79b7d1b3dc4d5fb1e3a8d5f4f45f26.jpeg'
  const [searchModelShow, setSearchModalShow] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const [resultList, setResultList] = useState([] as any)
  const [activeRecult, setActiveRecult] = useState(null as any)
  const [activeIndex, setActiveIndex] = useState(-1)

  useEffect(() => {
    searchTimer && clearTimeout(searchTimer)
    searchTimer = setTimeout(function () {
      if (searchVal) {
        getResultList()
      } else {
        setResultList([])
      }
    }, 300)
  }, [searchVal])

  useEffect(() => {
    setSearchModalShow(props.showModal)
  }, [props.showModal])

  useEffect(() => {
    if (!searchModelShow) {
      props.onHideModal()
    }
  }, [searchModelShow])

  const getResultList = async () => {
    setSearchLoading(true)
    setActiveIndex(-1)

    const _url = isPublicHuman
      ? '/redapi/sp/tech/ivh/IvhChatgptDialogSearch'
      : '/redapi/sp/tech/chatgpt/search/ChatgptDialogSearch'

    const url = baseUrl + _url
    http
      .get(url, {
        keyword: searchVal,
      })
      .then((res) => {
        setActiveIndex(0)
        setResultList(res.data)
      })
      .finally(() => {
        setSearchLoading(false)
      })
  }

  const replaceKeyword = (result) => {
    let _resultStr = result + ''
    if (_resultStr && _resultStr.includes(searchVal)) {
      // 使用 span 标签包裹关键字
      _resultStr = _resultStr.replace(new RegExp(searchVal, 'g'), "<span class='keyword'>$&</span>")
    }
    return _resultStr
  }

  const handleClick = (index) => {
    if (activeIndex == index) return
    setActiveIndex(index)
  }

  useEffect(() => {
    if (!resultList.length || !resultList[activeIndex]) return
    getResultDetails()
  }, [activeIndex])

  const handleDoubleClick = (index) => {
    http
      .get('/fastdev/chatgpt/queryContextById', {
        id: resultList[index].context_id,
      })
      .then((res) => {
        setSearchModalShow(false)
        dispatch(
          setActiveHistroy({
            scroll: true,
            ...res.data,
          })
        )
      })
  }

  const getResultDetails = () => {
    const activeItem = resultList[activeIndex]
    const _url = isPublicHuman
      ? '/redapi/sp/tech/ivh/IvhChatgptDialogPreview'
      : '/redapi/sp/tech/chatgpt/search/ChatgptDialogPreview'
    const url = baseUrl + _url
    http
      .get(url, {
        id: activeItem.context_id,
        index_no: activeItem.index_no,
      })
      .then((res) => {
        setActiveRecult(res.data)
      })
  }

  const formatDate = (operateTime) => {
    const today = dayjs()
    if (dayjs(operateTime).isSame(today, 'day')) {
      return '今天'
    } else {
      return operateTime.split(' ')[0]
    }
  }

  const rowRenderer = ({ index, style }) => {
    return (
      <div
        key={resultList[index].id}
        style={style}
        className={`${activeIndex == index ? 'active' : ''} result-items`}
        onClick={() => handleClick(index)}
        onDoubleClick={() => handleDoubleClick(index)}
      >
        <div
          className="result-item-left"
          dangerouslySetInnerHTML={{
            __html: replaceKeyword(resultList[index].kw_context),
          }}
        ></div>
        <div className="result-item-right">{formatDate(resultList[index].operate_time)}</div>
      </div>
    )
  }

  useEffect(() => {
    //@ts-ignore
    document
      .querySelectorAll('.active-result-content pre code:not(.language-mermaid)')
      //@ts-ignore
      .forEach((el) => hljs.highlightElement(el)) // eslint-disable-line no-undef
  }, [activeRecult])

  const listRef = useRef(null)

  const searchValChange = (val) => {
    setActiveRecult(null)
    setResultList([])
    setSearchVal(val)
  }

  return (
    <div className="global-search-box">
      <div className="btns-box-switch-theme" title="全局搜索">
        <div
          className="chatflow-switch-theme"
          onClick={() => {
            setSearchModalShow(true)
          }}
        >
          <SearchOutlined />
        </div>
      </div>
      <div
        className="gpt-modal global-search-modal"
        style={{ display: searchModelShow ? 'block' : 'none' }}
      >
        <div className="modal-header">
          <div className="modal-title">
            {/* <SearchOutlined className="modal-title-img" /> */}
            <img className="modal-title-img" src="./ai.png" alt="" />
            全局搜索
          </div>
          <CloseOutlined
            className="modal-icon"
            onClick={() => {
              setSearchModalShow(false)
            }}
          />
        </div>
        <div className="modal-container">
          <Input value={searchVal} onChange={searchValChange} placeholder="请输入关键字" />
          {searchLoading ? (
            <div className="no-data-box">
              <Icon type="spinner" spin />
              加载中...
            </div>
          ) : resultList.length ? (
            <>
              <div className="result-list self-def-scroll-style">
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      ref={listRef}
                      className="self-def-scroll-style"
                      width={width}
                      height={height}
                      rowCount={resultList.length}
                      rowHeight={25}
                      rowRenderer={rowRenderer}
                    />
                  )}
                </AutoSizer>
              </div>
              <div className="active-result-title">
                {(resultList[activeIndex] && resultList[activeIndex].title) || ''}
              </div>
              <div className="Message-content active-result-content self-def-scroll-style">
                {activeRecult && (
                  <>
                    <Avatar
                      src={activeRecult.type == 'self_msg' ? userPhoto : './ai.png'}
                      size="sm"
                      shape="square"
                    />
                    <div>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {filterContext(activeRecult.content.text) || ''}
                      </ReactMarkdown>
                    </div>
                  </>
                )}
              </div>
              <div className="jump-btns">
                <Button size="sm" color="primary" onClick={() => handleDoubleClick(activeIndex)}>
                  进入会话
                </Button>
              </div>
            </>
          ) : (
            <div className="no-data-box">暂无数据</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GlobalSearchComp
