import { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSourceLibrary,
  setVersion,
  setSourceAiType,
  AI_SOURCE_MAP,
  gptOptions,
  baiduOptions,
  zhipuOptions,
} from '../../../store/actions/other'
import { Select, Space } from 'antd'
import http from '../../../http/fetch'
import './SourceLibrary.css'
//@ts-ignore
import openAIlogo from '../../../assets/img/OpenAI.svg'
//@ts-ignore
import baiduAIlogo from '../../../assets/img/baidu.svg'
//@ts-ignore
import zhipuAIlogo from '../../../assets/img/zhipu.svg'

const isPublicOut = process.env.REACT_APP_FOR_OUT == '1'
// const openAIlogo = `https://oa.hr-soft.cn/RedseaPlatform/jsp/outwork/workbench/img/teamwork-icon/chatgpt.png`

const getDeafaultOptions = (hasVersionAuth) => {
  const deafaultOptions = [
    {
      label: 'RedGPT',
      options: hasVersionAuth
        ? [
            { icon: 'gpt', value: AI_SOURCE_MAP.OpenAI, label: 'GPT' },
            { icon: 'baidu', value: AI_SOURCE_MAP.Baidu, label: '文心一言' },
            { icon: 'zhipu', value: AI_SOURCE_MAP.Zhipu, label: '智谱AI' },
          ]
        : [{ icon: 'gpt', value: AI_SOURCE_MAP.OpenAI, label: 'GPT' }],
    },
    {
      label: '知识库',
      options: [
        { value: '内容平台', label: '内容平台' },
        { value: '客服', label: '客服' },
        { value: '咨询', label: '咨询' },
        { value: '知识库', label: '知识库' },
        { value: '配置', label: '配置' },
      ],
    },
  ]
  return deafaultOptions
}

//eslint-disable-next-line max-lines-per-function
function SourceLibraryComp() {
  const labelIconMap = {
    gpt: openAIlogo,
    baidu: baiduAIlogo,
    zhipu: zhipuAIlogo,
  }
  const dispatch = useDispatch()
  const { sourceLibrary, isThemeLight, versionAuth } = useSelector((state: any) => state.other)
  const { userInfo } = useSelector((state: any) => state.user)
  const hasVersionAuth = userInfo && versionAuth.indexOf(userInfo.userId) !== -1
  const [options, setOptions] = useState([] as any)

  useEffect(() => {
    const deafaultOptions = getDeafaultOptions(hasVersionAuth)

    setOptions(deafaultOptions)
  }, [hasVersionAuth])

  const gptValueLists = options[0]?.options.map((item) => item.value)
  const knowledgeValueLists = options[1]?.options.map((item) => item.value)

  const [sourceLibraryVal, setSourceLibraryVal] = useState(sourceLibrary)

  const getSourceNewValue = (value) => {
    if (value.length == 1) {
      return value
    }
    const lastChoose = value[value.length - 1]
    if (gptValueLists.includes(lastChoose)) {
      return [lastChoose]
    } else {
      return value.filter((item) => knowledgeValueLists.includes(item))
    }
  }

  const handleAISourceChange = (choose) => {
    if (choose == AI_SOURCE_MAP.OpenAI) {
      dispatch(setSourceAiType(AI_SOURCE_MAP.OpenAI))
      dispatch(setVersion('gpt-3.5-turbo-0125'))
    } else if (choose == AI_SOURCE_MAP.Baidu) {
      dispatch(setSourceAiType(AI_SOURCE_MAP.Baidu))
      dispatch(setVersion('completions_pro'))
    } else if (choose == AI_SOURCE_MAP.Zhipu) {
      dispatch(setSourceAiType(AI_SOURCE_MAP.Zhipu))
      dispatch(setVersion('glm-3-turbo'))
    }
  }

  const dispathGptVersion = (newVals) => {
    const lastChoose = newVals[0]
    if (gptValueLists.includes(lastChoose)) {
      if (!hasVersionAuth) {
        dispatch(setSourceAiType(AI_SOURCE_MAP.OpenAI))
        dispatch(setVersion('gpt-3.5-turbo-0125'))
      } else {
        handleAISourceChange(lastChoose)
      }
    } else {
      dispatch(setSourceAiType(AI_SOURCE_MAP.OpenAI))
      dispatch(setVersion('gpt-4-0125-preview'))
    }
  }

  const selectChange = (value) => {
    if (!value.length) {
      return
    }
    const _val = getSourceNewValue(value)

    dispathGptVersion(_val)
    setSourceLibraryVal(_val)
    dispatch(setSourceLibrary(_val))
  }

  const handleResOptions = (list) => {
    const redseaOpts = list.map((item) => {
      return {
        ...item,
        value: item.code,
        label: item.name,
      }
    })
    const deafaultOptions = getDeafaultOptions(hasVersionAuth)
    const copyOpts = JSON.parse(JSON.stringify(deafaultOptions))
    copyOpts[1].options = redseaOpts
    return copyOpts
  }

  const getCategorysIcons = async () => {
    if (isPublicOut) {
      return
    }
    try {
      const res: any = await http.post(
        '/redapi/sp/tech/chatgpt/vector/v1vector/getCategorysIcons',
        {},
        { hideErr: true }
      )
      if (res.code == 200) {
        const list = res.data
        const _list = handleResOptions(list)
        setOptions(_list)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (hasVersionAuth != null) {
      getCategorysIcons()
    }
  }, [hasVersionAuth])

  const rednerLabelIcon = (option) => {
    const { icon } = option.data || {}
    const icons = ['gpt', 'baidu', 'zhipu']

    if (icons.includes(icon)) {
      const imgSrc = labelIconMap[icon]
      return <img src={imgSrc} alt="" className="source-library-opt-gpt-logo" />
    }
    return <i className={`source-library-opt-icon ${icon}`} />
  }

  return (
    <div className="source-library-box">
      {/* <span className="title">模型：</span> */}
      <Select
        className={sourceLibraryVal != 'OpenAI' ? 'active-select' : ''}
        popupClassName={isThemeLight ? '' : 'ant-select-dropdown-dark'}
        mode="multiple"
        maxTagCount="responsive"
        size="small"
        value={sourceLibraryVal}
        onChange={selectChange}
        options={options}
        showSearch={false}
        optionRender={(option) => (
          <Space>
            <span>{rednerLabelIcon(option)}</span>
            <span>{option.label}</span>
          </Space>
        )}
      ></Select>
    </div>
  )
}

export default SourceLibraryComp
