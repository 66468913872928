import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Input, Button, Select, Checkbox, Radio } from 'antd'
import { RightOutlined, DownOutlined } from '@ant-design/icons'
import { toast } from '@chatui/core'
import { createTempApi } from '../../apis/chatflow/template'

import { doSetMyMenuList } from './uts'
import './TemplateModal.css'
import { sleep } from '../../utils/common'

const { TextArea } = Input

// let timerId = null;
//eslint-disable-next-line max-lines-per-function
function TemplateModal(props) {
  const { templateDialogVisit, onCloseDialog, activeTemplate, refreshList } = props
  const { isThemeLight } = useSelector((state: any) => state.other)
  const [lastShow, setLastShow] = useState(false)
  const formRef: any = useRef(null)

  const getModalTitle = () => {
    const modalTitle = activeTemplate ? '编辑模板' : '新增模板'
    return modalTitle
  }

  const getInitVal = () => {
    let initialValues = {
      codeText: '',
      message: '',
      model: 'gpt-3.5-turbo-0125',
      ask: 0,
    }
    if (activeTemplate) {
      const { message, codeText, model, ask } = activeTemplate
      initialValues = {
        message,
        codeText,
        model,
        ask,
      }
    }
    return initialValues
  }

  const saveTemplate = async () => {
    try {
      const values = await formRef.current.validateFields()

      const postData = values
      if (activeTemplate) {
        postData.id = activeTemplate.id
        postData.codeNo = activeTemplate.codeNo
      }
      await createTempApi(postData)

      const tip = activeTemplate ? '修改成功' : '新增成功'
      toast.success(tip)
      onCloseDialog()
      refreshList()
      setMyMenuList()
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }

  const setMyMenuList = async () => {
    await doSetMyMenuList()
  }

  const goToDemo = () => {
    const url =
      'https://workorder.hr-soft.cn/workorder/vwoffice/vwoffice_md_detail.html?shareId=edfd32d1ec8b463d85e7369b150fe135'
    window.open(url)
  }

  const ModalFooter = [
    <Button key="demo" onClick={goToDemo}>
      示例
    </Button>,
    <Button key="cancel" onClick={onCloseDialog}>
      取消
    </Button>,
    <Button key="submit" type="primary" onClick={saveTemplate}>
      确定
    </Button>,
  ]

  const lastTwoItemStyle = () => {
    return {
      display: lastShow ? 'block' : 'none',
    }
  }

  const showMoreClick = () => {
    setLastShow(!lastShow)
  }
  const msgTip = '模板内容中应包含占位符$ {content}'

  const handleTipEmpty = async () => {
    setTimeout(() => {
      const ele: any = document.querySelector('.col-msg-input .ant-form-item-explain-error')
      const text = ele.innerText
      if (ele && text == msgTip) {
        const _text = text.replace(' ', '')
        ele.innerHTML = _text
      }
    }, 600)
  }

  return (
    <Modal
      wrapClassName={
        isThemeLight
          ? 'chatflow-temp-add-modal'
          : 'chatflow-dark-modal-wrap chatflow-temp-add-modal'
      }
      title={getModalTitle()}
      width="1000px"
      open={templateDialogVisit}
      onCancel={onCloseDialog}
      footer={ModalFooter}
    >
      <Form
        layout="vertical"
        // layout="horizontal"
        labelAlign="right"
        // labelCol={{ span: 3 }}
        ref={formRef}
        initialValues={getInitVal()}
        autoComplete="off"
      >
        <Form.Item
          label="标题"
          name="codeText"
          rules={[{ required: true, message: '请输入标题!' }]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="模板内容"
          name="message"
          className="col-msg-input"
          rules={[
            { required: true, message: '' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error('请输入模板内容!'))
                } else if (value) {
                  if (!value.includes('${content}')) {
                    handleTipEmpty()
                    return Promise.reject(new Error(msgTip))
                  }
                  return Promise.resolve()
                }
              },
            }),
          ]}
        >
          <TextArea
            spellCheck="false"
            className="self-def-scroll-style"
            placeholder="请输入"
            autoSize={{ minRows: 10, maxRows: 10 }}
          />
        </Form.Item>

        <div className="chatflow-modal-item-last">
          <Form.Item
            label="AI模型"
            name="model"
            rules={[{ required: true, message: '请选择AI模型' }]}
          >
            <Radio.Group>
              <Radio value={'gpt-3.5-turbo-0125'}>gpt-3.5</Radio>
              <Radio value={'gpt-4-0125-preview'}>gpt-4</Radio>
            </Radio.Group>
            {/* <Checkbox onChange={onChangeCheckBox}>gpt-3.5-turbo</Checkbox>
            <Checkbox onChange={onChangeCheckBox}>gpt-4</Checkbox> */}
            {/* <Select
              popupClassName={isThemeLight ? "" : "chatflow-templ-popup-dark"}
              style={{ width: 120 }}
              options={[
                { value: "gpt-3.5-turbo", label: "gpt-3.5-turbo" },
                { value: "gpt-4", label: "gpt-4" },
              ]}
            /> */}
          </Form.Item>
          <Form.Item
            label="是否允许追问"
            name="ask"
            rules={[{ required: true, message: '请选择是否允许追问' }]}
          >
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
            {/* <Select
              popupClassName={isThemeLight ? "" : "chatflow-templ-popup-dark"}
              style={{ width: 120 }}
              options={[
                { value: 1, label: "是" },
                { value: 0, label: "否" },
              ]}
            /> */}
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default TemplateModal
