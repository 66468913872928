import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import VersionComp from '../version/Version'
import TemperatureComp from '../temperature/Temperature'
import MaxtokensComp from '../maxtokens/Maxtokens'
import SourceLibrary from '../source-list/SourceLibrary'
import './NavBar.css'

//eslint-disable-next-line max-lines-per-function
function NavBarComp() {
  return (
    <div className="navbar-box">
      <div className="navbar-items">
        <TemperatureComp />
      </div>
      <div className="navbar-items">
        <MaxtokensComp />
      </div>
      <div className="navbar-items">
        <VersionComp />
      </div>
      <div className="navbar-items">
        <SourceLibrary />
      </div>
    </div>
  )
}

export default NavBarComp
