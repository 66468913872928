import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Button, useMessages, Icon, toast, Modal, RichText } from '@chatui/core'
import usePicModal from './usePicModal'
import http from '@/http/fetch'

const isPublicHuman = process.env.REACT_APP_FOR_HUMAN == '1'

//eslint-disable-next-line max-lines-per-function
const InputItem = (props) => {
  const {
    onInputKeyDown,
    inputRef,
    question,
    onInputChange,
    loading,
    stopBtnShow,
    handleSend,
    isKnowlogAI,
  } = props
  const { version, versionList, versionAuth, isThemeLight, testVersionAuth } = useSelector(
    (state: any) => state.other
  )
  const { userInfo } = useSelector((state: any) => state.user)
  // const hasAuth = userInfo && testVersionAuth.indexOf(userInfo.userId) !== -1

  const { renderPicModal, setDialogVisit, onInputPaste } = usePicModal({
    handleSend,
  })
  const openPicModal = (imageUrl?) => {
    if (stopBtnShow) {
      return
    }
    if (isPublicHuman) {
      return
    }
    setDialogVisit(true)

    if (imageUrl) {
      uploadImgAfterPaste(imageUrl)
    }
  }

  const needShowPicIcon = () => {
    if (isKnowlogAI) {
      return false
    }
    if (isPublicHuman) {
      return false
    }
    if (version.includes('gpt')) {
      return true
    }
    return false
  }

  const uploadImgAfterPaste = async (blob) => {
    const res = await http.uploadFileFastDev(blob)
    const herfUrl = res.data?.[0]?.herfUrl
    onInputPaste(herfUrl)
  }

  const renderPicIcon = () => {
    const show = needShowPicIcon()
    if (!show) {
      return null
    }
    return (
      <div
        className={stopBtnShow ? 'input-image-icon disable-image-icon' : 'input-image-icon'}
        onClick={() => openPicModal()}
      >
        <Icon type="image" />
      </div>
    )
  }

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData
    for (let i = 0; i < clipboardData.items.length; i++) {
      const item = clipboardData.items[i]
      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile()
        openPicModal(blob)
        // uploadImgAfterPaste(blob)
      } else {
        // 如果不是图片，则获取文本内容
        const text = clipboardData.getData('text')
        // console.log('Pasted text: ', text)
      }
    }
  }

  return (
    <div className="input-box">
      {renderPicIcon()}
      <Input
        onKeyDown={onInputKeyDown}
        onPaste={handlePaste}
        ref={inputRef}
        autoSize
        maxRows={15}
        value={question}
        onChange={(val) => onInputChange(val)}
        className={`Composer-input self-def-scroll-style ${needShowPicIcon() ? 'pad-left-35' : ''}`}
        placeholder={loading ? '请稍后...' : '输入 " / " 可调用模板'}
      ></Input>
      <Button
        size="sm"
        color="primary"
        disabled={stopBtnShow || !question}
        onClick={() => handleSend()}
      >
        发送(S)
      </Button>
      {renderPicModal()}
    </div>
  )
}

export default InputItem
