import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Icons from '@ant-design/icons'
import { Icon } from '@chatui/core'
import http from '@/http/fetch'
import { echartProxy } from '@/component/echarts/systemContext'
import './Proxy.css'
//eslint-disable-next-line max-lines-per-function
function Proxy(props) {
  const [proxyList, setProxyList] = useState([] as any)
  const searchTemplate = () => {
    http.get('/fastdev/chatgpt/agentlist', {}).then((res) => {
      res.data.unshift(echartProxy)
      setProxyList(res.data)
      props.onUpdateProxyList(res.data)
    })
  }
  useEffect(() => {
    setProxyType(props.proxy)
  }, [props.proxy])
  useEffect(() => {
    searchTemplate()
  }, [])
  const [proxyType, setProxyType] = useState(null as any)
  const setProxyTypeFn = (val) => {
    if (proxyType && val.agentId == proxyType.agentId) {
      setProxyType(null)
    } else {
      setProxyType(val)
    }
    setModelShow(false)
  }
  useEffect(() => {
    props.onUpdateProxy(proxyType)
  }, [proxyType])
  const [modelShow, setModelShow] = useState(false)
  const toggleModel = () => {
    if (props.stopBtnShow) return
    setModelShow(!modelShow)
  }
  const documentOnClick = (event) => {
    if (!event.target.closest('.proxy-box')) {
      setModelShow(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', documentOnClick)
    return () => {
      window.removeEventListener('click', documentOnClick) // 销毁
    }
  }, [])

  const iconRender = (icon) => {
    return React.createElement(Icons[icon])
  }
  return (
    <div className="proxy-box">
      {proxyType && proxyType.agentId ? (
        <span
          className={`proxy-icon ${proxyType && proxyType.agentId && 'active'}`}
          onClick={toggleModel}
        >
          {iconRender(proxyType.icon)}
        </span>
      ) : (
        <Icon className="proxy-icon" type="plus-circle" onClick={toggleModel} />
      )}
      {modelShow && (
        <div className="proxy-list">
          {proxyList.map((item) => {
            return (
              <div
                key={item.agentId}
                className={`proxy-items ${
                  proxyType && proxyType.agentId == item.agentId ? 'active' : ''
                }`}
                onClick={() => setProxyTypeFn(item)}
                title={item.desc}
              >
                <span className="title">
                  {iconRender(item.icon)}
                  {item.name}
                </span>
                {/* <span className="template">{item.desc}</span> */}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Proxy
