import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Button, useMessages, Icon, toast, Modal } from '@chatui/core'
import { DeleteOutlined, LinkOutlined, EllipsisOutlined } from '@ant-design/icons'
import { tokenizerFn, MAXTOKENS, DEFAULT_MODEL } from '@/utils/chatgpt'
import { base64Decode, decodeBase64 } from '@/component/uts'
import { aiAvatar, redAvatar, initialMessages, paragraphsRegex } from '@/component/chat/constants'
import useUpdateActiveHistory from '@/hooks/useUpdateActiveHistory'
import http from '@/http/fetch'
// http请求终止控制器
let controller = null as any

//eslint-disable-next-line max-lines-per-function
const useChatGptFunc = (props: any) => {
  const { version, temperature, maxTokens, versionAuth, isThemeLight, sourceLibrary } = useSelector(
    (state: any) => state.other
  )
  // 会话列表
  const { activeHistory, historyList } = useSelector((state: any) => state.histroy)
  const {
    setParagraphs,
    setQueryContext,
    isOpenAI,
    appendMsg,
    queryContext,
    question,
    messages,
    activeHistoryId,
    proxy,
    updateHistory,
    paragraphs,
    updateMsg,
    needCreateTitle,
    stopBtnShow,
    setStopBtnShow,
  } = props

  const [questionLink, setQuestionLink] = useState([] as any)
  // 等待AI回复
  const [loading, setLoading] = useState(false)

  //更新会话
  const { updateActiveHistory } = useUpdateActiveHistory({
    activeHistoryId,
    questionLink,
    proxy,
  })

  // 接口响应流
  const dataRef = useRef({ reader: null } as any)

  useEffect(() => {
    if (!dataRef.current.reader) {
      return
    }
    if (stopBtnShow) {
      console.log('开始请求数据！')
    } else {
      requestDone()
    }
  }, [stopBtnShow])

  useEffect(() => {
    handleDataPushOneByOne(messages)
  }, [messages])
  //eslint-disable-next-line max-lines-per-function
  const requestDone = async () => {
    dataRef.current.reader = null
    // console.log('请求完成！', messages)
    // console.log('activeHistoryId', activeHistoryId.current)
    // console.log('historyList', historyList)
    updateActiveHistory({
      selfMsg,
      question: saveMsgItem.content.text,
    })
    setLastId('')
  }

  async function getQuestionReprompt(trimValue) {
    return http.post(
      '/redapi/sp/tech/chatgpt/vector/questionReprompt',
      {
        params: JSON.stringify({
          question: trimValue,
          question_type: 'DocumentSearch',
          category: sourceLibrary.join(','),
          top_k: 20,
        }),
        context: JSON.stringify(queryContext),
      },
      {
        dataType: 'urlencoded',
        noAuth: true,
      }
    )
  }

  // 保存当前问题
  const [selfMsg, setSelfMsg] = useState({} as any)
  // 流式输出
  //eslint-disable-next-line max-lines-per-function
  async function querySendByStream(_queryContext, _selfMsg, otherData = {}) {
    setSelfMsg(_selfMsg)
    controller = new AbortController()
    const signal = controller.signal

    setStopBtnShow(true)

    try {
      const postBody = {
        messages: _queryContext,
        model: version,
        // id: activeHistory.id,
        chatStream: '1',
        ...otherData,
      }
      if (proxy && proxy.agentId) {
        postBody['agentMode'] = 'enable'
        postBody['agentId'] = proxy.agentId
      }
      const response = (await fetch('/fastdev/chatgpt/answer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Accept': 'text/event-stream; charset=utf-8', // 添加Accept头，确保接受UTF-8编码
        },
        body: JSON.stringify(postBody),
        signal,
      })) as any

      setLoading(false)

      if (!response.ok) {
        setStopBtnShow(false)
        if (response.status !== 500) {
          appendErrorMsg('接口错误，请重试。', _selfMsg)
          return
        }
        try {
          // console.log('response.json()', response.text());
          response.json().then((res) => {
            if (!res.result) {
              appendErrorMsg('接口错误，请重试。', _selfMsg)
              return
            }
            const _err = decodeBase64(res.result)
            appendErrorMsg(_err, _selfMsg)
          })
          return
        } catch (error) {
          appendErrorMsg('接口错误，请重试。', _selfMsg)
          return
        }
      }

      const msgItem = {
        type: 'html',
        content: { text: '' },
      }
      appendMsg({
        ...msgItem,
        user: isOpenAI ? aiAvatar : redAvatar,
      })

      // console.log('response ... ', response)
      dataRef.current.reader = response.body.getReader()
    } catch (error) {
      // setLoading(false)
      setStopBtnShow(false)

      console.log('Error in POST request:', error)
    } finally {
      setLoading(false)
    }

    return controller
  }
  // 记录AI输出的内容，用于中断时，可保存消息
  const [saveMsgItem, setSaveMsgItem] = useState(null as any)
  // AI流式输出时，用于记录最后一条会话消息id，实时更新最后一条消息
  const [lastId, setLastId] = useState('')

  // 流式输出更新消息
  //eslint-disable-next-line max-lines-per-function
  const handleDataPushOneByOne = async (messages) => {
    const reader = dataRef.current.reader
    if (!reader) {
      return
    }
    // 记录最后一条会话消息id
    const lastIndex = messages.length - 1
    const _msg = messages[lastIndex]
    const _lastId = _msg._id
    setLastId(_lastId)
    const decoder = new TextDecoder('utf-8')
    const prefix = 'data: '
    try {
      let chunk = await reader.read()
      while (!chunk.done && chunk.value) {
        const chunkData = decoder.decode(chunk.value, { stream: true })
        if (!chunkData.startsWith(prefix)) {
          const errorInfo = JSON.parse(chunkData)
          console.error(errorInfo.result)
          appendErrorMsg('接口错误，请重试。', selfMsg)
          setStopBtnShow(false)
          setLoading(false)
          break
        }
        const lines = chunkData.split('\n')
        for (const line of lines) {
          const [label, base64Str] = line.split(': ')
          if (label === 'data') {
            const decodedStr = base64Decode(base64Str) as any
            if (decodedStr.includes('DONE')) {
              const htmlStr = _msg.content.text
              _msg.content.text = htmlStr
              _msg.needAudio = selfMsg.needAudio
              _msg.agentId = proxy && proxy.agentId
              _msg.agentLoading = false
              setQueryContext([
                ...queryContext,
                {
                  role: 'assistant',
                  content: htmlStr,
                },
              ])
              setStopBtnShow(false)
              // 心流模式根据paragraphsRegex剪切段落
              if (selfMsg.needAudio) {
                const _paragraphs = _msg.content.text
                  .split(paragraphsRegex)
                  .filter((item) => item != '')
                // AI输出时，不读最后一个段落。所以完成后，需要插入一个空格符，才会读最后一个段落
                _paragraphs.push('')
                setParagraphs(_paragraphs)
              }
            } else if (decodedStr) {
              _msg.content.text += decodedStr
              _msg.agentId = proxy && proxy.agentId
              _msg.agentLoading = true
              // 心流模式根据paragraphsRegex剪切段落
              if (selfMsg.needAudio) {
                if (paragraphsRegex.test(_msg.content.text)) {
                  const _paragraphs = _msg.content.text
                    .split(paragraphsRegex)
                    .filter((item) => item != '')
                  // AI输出时，最后一个段落还未完成，不需要阅读
                  if (_paragraphs.length - paragraphs.length > 1) {
                    setParagraphs(_paragraphs)
                  }
                }
              }
            }
            updateMsg(_lastId, _msg)
            setSaveMsgItem(_msg)
          }
        }
        chunk = await reader.read() // 读取下一个数据块
      }
    } catch (error) {
      setStopBtnShow(false)
      setLoading(false)
    }
  }

  // 添加错误信息
  function appendErrorMsg(text, _selfMsg) {
    const _msgItem = {
      type: 'error',
      content: { text },
    }
    appendMsg({
      ..._msgItem,
      user: isOpenAI ? aiAvatar : redAvatar,
    })

    const _context = activeHistory.context || '[]'
    const _contextList = JSON.parse(_context)
    _contextList.push(_selfMsg)
    _contextList.push(_msgItem)
    // console.log('appendErrorMsg', _contextList);

    updateHistory({
      id: activeHistory.id,
      context: JSON.stringify(_contextList),
    })
  }

  // 生成标题
  //eslint-disable-next-line max-lines-per-function
  function createChatTitle(postData) {
    // console.log('createChatTitle', JSON.parse(postData.context || '[]'))
    try {
      const _firstMsg = JSON.parse(postData.context).filter((item) => item.type == 'self_msg')[0]
      const _msgContent = _firstMsg.content.text
      const _msgSlice = _msgContent.slice(0, 2000)
      http
        .post(
          '/fastdev/chatgpt/answer',
          {
            messages: [
              {
                role: 'user',
                content:
                  '请将下列文本生成一个主题，字符串长度控制在20以内，不含标点符号，不含空格和换行符\n\n' +
                  _msgSlice,
              },
            ],
            model: DEFAULT_MODEL,
          },
          {
            hideErr: true,
            dataType: 'json',
          }
        )
        .then((res: any) => {
          if ((res.status === 200 && res.data && res.data.code == 'ok') || res.code == 'ok') {
            const result = (res.data && res.data.result) || res.result
            const _activeHistory = {
              id: postData.id,
              title: result.replace(/\r|\n/gi, '').substring(0, 20),
            }
            updateHistory(_activeHistory)
          }
        })
        .finally(() => {
          needCreateTitle.current = false
        })
    } catch (err) {
      console.log(err)
    }
  }

  return {
    controller,
    createChatTitle,
    setLoading,
    setQuestionLink,
    loading,
    getQuestionReprompt,
    querySendByStream,
  }
}

export default useChatGptFunc
