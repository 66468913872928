import React, { useState, useEffect, useRef } from 'react'
import { register, smsSend } from '@/apis/login/login'

import {
  verifyRegisterStep1,
  verifyRegisterRowInput,
  verifyPhone,
  verifyLogin,
  verifyLoginRowInput,
  verifyLoginAI,
} from './verify'
//eslint-disable-next-line max-lines-per-function
export default function useVerifyLoign() {
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const inputDataRef = useRef({
    phone: '',
    password: '',
    code: '',
    isChecked: false,
  })
  const [errTip, setErrTip] = useState('')
  const [isBtnActive, setisBtnActive] = useState(false)
  const verifyInputOk = () => {
    const { phone, password, isChecked, code } = inputDataRef.current
    const tip = verifyLoginAI(phone, password, code)
    if (tip) {
      setErrTip(tip)
      return false
    }
    setErrTip('')
    return true
  }
  const verifyRowInput = (name) => {
    const { phone, password, isChecked } = inputDataRef.current
    const tip = verifyLoginRowInput({
      phone,
      password,
      isChecked,
      name,
    })
    if (tip) {
      setErrTip(tip)
      return
    }
    setErrTip('')
  }

  const verifyInputIng = (name) => {
    if (errTip) {
      verifyRowInput(name)
    }
    isSubmitBtnCanClick()
  }
  const isSubmitBtnCanClick = () => {
    const { phone, password, isChecked } = inputDataRef.current
    const tip = verifyLogin(phone, password, isChecked)
    if (tip) {
      setisBtnActive(false)
    } else {
      setisBtnActive(true)
    }
  }

  const setReadState = () => {
    inputDataRef.current.isChecked = !isChecked
    setIsChecked(!isChecked)
    verifyInputIng('isChecked')
  }

  const onChangePhone = (e) => {
    const phone = e.target.value
    inputDataRef.current.phone = phone
    setPhone(phone)
    verifyInputIng('phone')
  }

  const onChangePassWord = (e) => {
    const password = e.target.value
    inputDataRef.current.password = password
    setPassword(password)
    verifyInputIng('password')
  }

  const onChangeCode = (e) => {
    const code = e.target.value
    inputDataRef.current.code = code
    setCode(code)
    verifyInputIng('code')
  }

  const getMsgCodeTip = (tip) => {
    if (tip) {
      setErrTip(tip)
    }
  }

  const getLoginMsgCode = async () => {
    const scene = '199'
    await smsSend(phone, scene)
  }

  return {
    code,
    phone,
    setPhone,
    password,
    setPassword,
    isChecked,
    setIsChecked,
    inputDataRef,
    errTip,
    setErrTip,
    isBtnActive,
    setisBtnActive,
    verifyInputOk,
    verifyRowInput,
    isSubmitBtnCanClick,
    setReadState,
    onChangePhone,
    onChangePassWord,
    onChangeCode,
    getMsgCodeTip,
    getLoginMsgCode,
  }
}
