import { useMemo } from 'react'
import queryString from 'query-string'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

const getSearch = (params) => {
  let str = ''
  Object.keys(params)?.forEach((key) => {
    const value = params[key]
    str += `${key}=${value}&`
  })
  return str.substring(0, str.length - 1)
}

const useRouter = () => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const hashPush = (...args) => {
    const url = args[0]
    const serachParams = args[1]
    if (serachParams) {
      const str = getSearch(serachParams)
      const _url = `${url}?${str}`
      navigate(_url)
    } else {
      navigate(url)
    }
  }
  return useMemo(() => {
    return {
      historyPush: hashPush,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      } as any,
      location,
      history,
    }
  }, [params, location, history])
}

export default useRouter
