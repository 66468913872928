import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import http from '@/http/fetch'
import Cookies from 'js-cookie'
import useRouter from '@/hooks/router/useRouter'
import { setHistroyList } from '@/store/actions/histroy'
import { getUserByApi } from '@/apis/user/index'
import { setUserInfo } from '@/store/actions/user'
const isPublicOut = process.env.REACT_APP_FOR_OUT == '1'
import { isWeixinBrowser } from '../uts'

//eslint-disable-next-line max-lines-per-function
const useWxScan = () => {
  const dispatch = useDispatch()
  const { history, query, historyPush } = useRouter()
  const openId = query.openId
  const token: any = query.token

  const wxScanLoginJump = () => {
    if (isWxScanLogin()) {
      historyPush(`/wx-bind`, {
        openId: query.openId,
      })
    }
  }

  const isWxScanLogin = () => {
    if (query.openId && query.token == 'null') {
      return true
    }
    return false
  }

  useEffect(() => {
    wxScanLoginJump()
  }, [])

  const getUserByForPublicOut = async () => {
    const res = await getUserByApi()
    dispatch(setUserInfo(res.data))
  }

  function getUserInfo() {
    http.post('/RedseaPlatform/PtUsers.mc?method=getCurUserInfo', {}).then((res) => {
      dispatch(setUserInfo(res))
    })
  }

  const hrefContain = () => {
    const href = window.location.href
    const somePages = ['login', 'register', 'wx-bind', 'wxlogin', 'robotLog']
    let isContain = false
    somePages.forEach((it) => {
      if (href.includes(it)) {
        isContain = true
      }
    })
    return isContain
  }
  const somePageNoNeedUserInfo = () => {
    if (hrefContain()) {
      return true
    }
    return false
  }

  const isScanHasToken = () => {
    if (!openId && token && token != 'null') {
      return true
    }
    return false
  }

  const reToListPage = () => {
    location.href = '/#/app-list'
  }

  const updateHistoryList = async () => {
    if (isWeixinBrowser()) {
      if (!isWxScanLogin() && !isScanHasToken()) {
        return
      }
    }

    if (isWxScanLogin()) {
      return
    }
    if (isScanHasToken()) {
      Cookies.set('REDSEA-TOKEN', token, {
        expires: 30,
      })
      reToListPage()
      return
    }
    http.get('/fastdev/chatgpt/titlelist', {}).then((res) => {
      dispatch(setHistroyList(res.data || []))
    })
  }

  const needRedirectToAppList = () => {
    const hash = location.hash
    if (hash == '') {
      return true
    }
    if (hash == '#/') {
      return true
    }
    return false
  }

  //eslint-disable-next-line max-lines-per-function
  const getUserEffect = () => {
    if (isWeixinBrowser()) {
      if (!isWxScanLogin() && !isScanHasToken()) {
        return
      }
    }
    if (isWxScanLogin()) {
      return
    }

    if (isScanHasToken()) {
      Cookies.set('REDSEA-TOKEN', token, {
        expires: 30,
      })
      reToListPage()
      return
    }
    if (somePageNoNeedUserInfo()) {
      return
    }
    if (isPublicOut) {
      const needRedirtTo = needRedirectToAppList()
      if (needRedirtTo) {
        reToListPage()
        getUserByForPublicOut()
      } else {
        getUserByForPublicOut()
      }
    } else {
      getUserInfo()
    }
  }

  useEffect(() => {
    getUserEffect()
  }, [])

  return {
    updateHistoryList,
    isWxScanLogin,
    isScanHasToken,
  }
}

export default useWxScan
