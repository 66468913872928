import React, { memo, useEffect, useRef, useState } from 'react'
import { message, Button, Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import './style.css'
import useRouter from '@/hooks/router/useRouter'
import { getRobotLog, getCurrent, logout } from '@/apis/wxlogin/wxlogin'

let getLogTimer = null as any

//eslint-disable-next-line max-lines-per-function
const RobotLog = () => {
  const { history, query, historyPush } = useRouter()

  const [userInfo, setUserInfo] = useState(null as any)
  const onGetCurrent = async () => {
    const res = await getCurrent()
    setUserInfo(res.data)
  }

  const [logs, setLogs] = useState([] as any)
  const logsRef = useRef([] as any)

  const onGetLog = async () => {
    const res = await getRobotLog()
    if (res.data.loginStatus) {
      if (res.data.logs.length) {
        logsRef.current = [...logsRef.current, ...res.data.logs]
        setLogs(logsRef.current)
      }
    } else {
      getLogTimer && clearInterval(getLogTimer)
      message.error('登录超时，请重新扫码登录', 2, () => historyPush(`/wxlogin`, {}))
    }
  }
  useEffect(() => {
    onGetCurrent()
    getLogTimer = setInterval(() => {
      onGetLog()
    }, 1000)
    return () => {
      getLogTimer && clearInterval(getLogTimer)
    }
  }, [])

  useEffect(() => {
    const logPage = document.getElementById('logPage')
    logPage && logPage.scrollTo(0, logPage.scrollHeight)
  }, [logs])

  const logHeaderMenu: MenuProps['items'] = [
    {
      key: 'logout',
      label: '退出登录',
    },
  ]

  const logHeaderMenuOnClick: MenuProps['onClick'] = ({ key }) => {
    if (key == 'logout') {
      onlogout()
    }
  }

  const onlogout = async () => {
    const res = await logout()
    if (res.code == 200) {
      getLogTimer && clearInterval(getLogTimer)
      historyPush(`/wxlogin`, {})
    }
  }

  return (
    <div className="chatgpt-wx-login-log">
      <div className="chatgpt-wx-login-log-header">
        <div className="chatgpt-wx-login-log-title">日志</div>
        {userInfo && (
          <Dropdown menu={{ items: logHeaderMenu, onClick: logHeaderMenuOnClick }} arrow>
            <Button type="link">{userInfo.nickName}</Button>
          </Dropdown>
        )}
      </div>
      <div className="chatgpt-wx-login-log-list self-def-scroll-style" id="logPage">
        {logs.map((log, index) => {
          return (
            <div className="chatgpt-wx-login-log-items" key={index}>
              {log}
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default RobotLog
