import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setVersion } from '../../../store/actions/other'
import { Select } from 'antd'

import './Version.css'

//eslint-disable-next-line max-lines-per-function
function VersionComp() {
  const dispatch = useDispatch()

  const {
    version,
    versionAuth,
    isThemeLight,
    versionList: versionOptions,
  } = useSelector((state: any) => state.other)
  const { userInfo } = useSelector((state: any) => state.user)

  const isDev = window.location.hostname == 'localhost'

  const hasAuth = userInfo && versionAuth.indexOf(userInfo.userId) !== -1
  // const hasAuth = false

  const [versionVal, setVersionVal] = useState(version)
  const selectChange = (val) => {
    setVersionVal(val)
    dispatch(setVersion(val))
  }

  useEffect(() => {
    setVersionVal(version)
  }, [version])

  return (
    hasAuth && (
      <div className="version-box">
        {/* <span className="title">模型：</span> */}
        <Select
          popupClassName={isThemeLight ? '' : 'ant-select-dropdown-dark'}
          size="small"
          value={versionVal}
          onChange={selectChange}
          options={versionOptions}
        />
      </div>
    )
  )
}

export default VersionComp
