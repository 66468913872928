import http from '../../http/fetch'
import { getUserInfoFromRedTeams } from '../common/index'

const vworkUrl = 'https://vw.hr-soft.cn'

const getEncodeUser = async () => {
  const user = await getUserInfoFromRedTeams()
  const { encodeUserId, encodeUserName } = user
  const _encodeUserId = encodeURIComponent(encodeUserId)
  const _encodeUserName = encodeURIComponent(encodeUserName)
  return {
    _encodeUserId,
    _encodeUserName,
  }
}

const filterHideItem = (list) => {
  return list.map((item) => {
    const newChildren = item.children.filter((subItem) => {
      return subItem.hide != true
    })
    item.children = newChildren
    return item
  })
}

export const getSelfMenuList = async () => {
  const { _encodeUserName, _encodeUserId } = await getEncodeUser()

  const url = `${vworkUrl}/redapi/sp/tech/userChatgptTemplateOrderSelect?userId=${_encodeUserId}&userName=${_encodeUserName}`

  const res = (await http.post(url, {})) as any
  if (res.code == 200) {
    const data = res.data
    if (data && data.length > 0) {
      const message = data[0]?.message
      if (message) {
        const msg = JSON.parse(message)

        return msg
      }
      return []
    }
  }

  return []
}

export const saveSelfMenuList = async (tree) => {
  const { _encodeUserName, _encodeUserId } = await getEncodeUser()
  const url = `${vworkUrl}/redapi/sp/tech/userChatgptTemplateOrderSave`
  // console.log('tree', tree);
  const params = {
    userId: _encodeUserId,
    userName: _encodeUserName,
    message: JSON.stringify(tree),
  }
  const res = await http.post(url, params, {
    dataType: 'urlencoded',
  })
  return res
}

export const getGptMenuList = async () => {
  const { _encodeUserName, _encodeUserId } = await getEncodeUser()

  const url = `${vworkUrl}/fastdev/gptapi/typeList?classType=common&user_id=${_encodeUserId}&user_name=${_encodeUserName}`

  const res = await http.get(url, {})
  return res
}
