import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSourceLibrary, setVersion } from '@/store/actions/other'
import { Select, Space } from 'antd'
import http from '@/http/fetch'
import './SourceLibrary.css'
const isPublicOut = process.env.REACT_APP_FOR_OUT == '1'
//@ts-ignore
import openAIlogo from '@/assets/img/OpenAI.svg'
// const openAIlogo = `https://oa.hr-soft.cn/RedseaPlatform/jsp/outwork/workbench/img/teamwork-icon/chatgpt.png`

const deafaultOptions = [
  { label: 'RedGPT', options: [{ icon: 'gpt', value: 'OpenAI', label: 'GPT' }] },
  {
    label: '知识库',
    options: [
      // { value: '内容平台', label: '内容平台' },
      // { value: '客服', label: '客服' },
      // { value: '咨询', label: '咨询' },
      // { value: '知识库', label: '知识库' },
      // { value: '配置', label: '配置' },
    ],
  },
]

//eslint-disable-next-line max-lines-per-function
function SourceLibraryComp() {
  const dispatch = useDispatch()
  const { sourceLibrary, isThemeLight, versionAuth } = useSelector((state: any) => state.other)
  const { userInfo } = useSelector((state: any) => state.user)
  const [options, setOptions] = useState(deafaultOptions as any)

  const hasVersionAuth = userInfo && versionAuth.indexOf(userInfo.userId) !== -1

  const [sourceLibraryVal, setSourceLibraryVal] = useState(sourceLibrary)
  const selectChange = (value) => {
    let _val = value
    const length = value.length
    if (length > 1) {
      if (value[length - 1] == 'OpenAI') {
        _val = ['OpenAI']
        if (!hasVersionAuth) {
          dispatch(setVersion('gpt-3.5-turbo-0125'))
        }
      } else {
        _val = value.filter((item) => {
          return item != 'OpenAI'
        })
        dispatch(setVersion('gpt-4-0125-preview'))
      }
    }
    if (!value.length) return
    setSourceLibraryVal(_val)
    dispatch(setSourceLibrary(_val))
  }

  const handleResOptions = (list) => {
    const redseaOpts = list.map((item) => {
      return {
        ...item,
        value: item.name,
        label: item.name,
      }
    })
    const copyOpts = JSON.parse(JSON.stringify(deafaultOptions))
    copyOpts[1].options = redseaOpts
    return copyOpts
  }

  const getCategorysIcons = async () => {
    if (isPublicOut) {
      return
    }
    try {
      const res: any = await http.post(
        '/redapi/sp/tech/chatgpt/vector/v1vector/getCategorysIcons',
        {},
        { hideErr: true }
      )
      if (res.code == 200) {
        const list = res.data
        const _list = handleResOptions(list)
        setOptions(_list)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getCategorysIcons()
  }, [])

  const rednerLabelIcon = (option) => {
    const { icon } = option.data || {}
    if (icon == 'gpt') {
      return <img src={openAIlogo} alt="" className="source-library-opt-gpt-logo" />
    }
    return <i className={`source-library-opt-icon redsea-icon-font ${icon}`} />
  }

  return (
    <div className="source-library-box">
      <span className="title">来源：</span>
      <Select
        className={sourceLibraryVal != 'OpenAI' ? 'active-select' : ''}
        popupClassName={isThemeLight ? '' : 'ant-select-dropdown-dark'}
        mode="multiple"
        maxTagCount="responsive"
        size="small"
        value={sourceLibraryVal}
        onChange={selectChange}
        options={options}
        showSearch={false}
        optionRender={(option) => (
          <Space>
            <span>{rednerLabelIcon(option)}</span>
            <span>{option.label}</span>
          </Space>
        )}
      ></Select>
    </div>
  )
}

export default SourceLibraryComp
