import md5 from 'md5-js'
import securityBase64 from './securityBase64'

const SECRET = 'l%p^e&n*g%$#@!_tech_*.mb'

export const getUserIdAndName = () => {
  let userId = 'su'
  let userName = 'suName'
  if (window.electron) {
    const userInfo = window.electron.getUserInfo()
    userId = userInfo?.userId
    userName = userInfo.userName
  }
  return {
    userId,
    userName,
  }
}

export const sortObjKey = function (unordered) {
  const ordered = {}
  Object.keys(unordered || {})
    .sort()
    .forEach((key) => {
      ordered[key] = unordered[key]
    })
  return ordered
}

export const setRedApiAuthentication = (config, timestamp) => {
  if (config.method == 'post') {
    const authentication = setRedApiAuthenticationForPost(config, timestamp)
    return authentication
  }
  const dataObj = config.auth

  const _data = sortObjKey(dataObj)

  let plan_text = ''
  Object.keys(_data).forEach((key) => {
    let value = _data[key]
    if (isObject(value)) {
      value = JSON.stringify(value)
    }
    plan_text += `${key}=${value}&`
  })
  const _authentication = `${plan_text}timestamp=${timestamp}&secret=${SECRET}`

  const authentication = md5(_authentication)

  return authentication
}

export const setConfigPath = (path, config) => {
  const { userId, userName } = getUserIdAndName()

  const user_id = securityBase64.encode(userId)
  const user_name = securityBase64.encode(userName)
  const url = `${path}?user_id=${encodeURIComponent(user_id)}&user_name=${encodeURIComponent(
    user_name
  )}`
  return url
}

export const setRedApiAuthenticationForPost = (config, timestamp) => {
  // const userId = 'su';
  // const userName = 'suName';

  // const user_id = securityBase64.encode(userId);
  // const user_name = securityBase64.encode(userName);

  const paramsStr = JSON.stringify(config.auth)
  const _authentication = `${paramsStr}&timestamp=${timestamp}&secret=${SECRET}`

  const authentication = md5(_authentication)
  return authentication
}

const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export const transToKeyValue = (data, path) => {
  if (!data) {
    return data
  }
  const _data = data
  let text = ''
  const copyData = JSON.parse(JSON.stringify(_data))

  Object.keys(copyData).forEach((key) => {
    let value = copyData[key]
    if (isObject(value)) {
      value = encodeURIComponent(JSON.stringify(value))
      value = encodeURIComponent(value)
    }

    text += `&${key}=${value}`
  })
  const str = text.substring(1, text.length)
  return str
}

const setStringToObj = (auth, path) => {
  const extraInfo = {}

  return {
    ...auth,
    ...extraInfo,
  }
}

const setUrlStringToObj = (path) => {
  const parmsStr = path.split('?')[1]
  const arr = parmsStr.split('&')
  const pramObj = {}
  arr.forEach((element) => {
    const [key, value] = element.split('=')
    pramObj[key] = value
  })
  return pramObj
}

const paramsNeedDecode = (authKeyNeedDecodes, dataObj) => {
  authKeyNeedDecodes.forEach((key) => {
    const preVal = dataObj[key]
    dataObj[key] = decodeURIComponent(preVal)
  })
  return dataObj
}

export const setAuthentication = (config) => {
  let dataObj = {}
  if (config.auth && config.auth.passAuth) {
    return config.auth.passAuth
  } else if (config.onlySignUrlParams) {
    dataObj = setUrlStringToObj(config.url)
    if (config.authKeyNeedDecode) {
      dataObj = paramsNeedDecode(config.authKeyNeedDecode, dataObj)
    }
  } else if (!config.noExtraInfo) {
    dataObj = setStringToObj(config.auth, config.url)
  } else if (config.onlyTimestampSign) {
    // onlyTimestampSign 只签时间戳
    dataObj = {
      timestamp: config.auth.timestamp || config.timestamp || '',
    }
  } else {
    dataObj = config.auth
  }

  return getAuthStrByobj(dataObj)
}

const getAuthStrByobj = (dataObj, config?) => {
  const _dataObj = JSON.parse(JSON.stringify(dataObj))

  const _data: any = sortObjKey(_dataObj)

  _data.secret = SECRET

  let plan_text = ''
  Object.keys(_data).forEach((key) => {
    let value = _data[key]
    if (isObject(value)) {
      if (config.noEncodeTwice) {
        value = JSON.stringify(value)
      } else {
        value = JSON.stringify(value)
        value = encodeURIComponent(value)
      }
    }
    if (_data.authKeyNeedDecode?.includes(key)) {
      value = decodeURIComponent(value)
    }
    plan_text += `&${key}=${value}`
  })

  const str = plan_text.substring(1, plan_text.length)
  // console.log('md5前：', str);
  const sign = md5(str)

  return JSON.stringify({ sign: sign })
}

export const getAuthSign = (authPrams) => {
  const dataObj = authPrams

  return getAuthStrByobj(dataObj)
}

export const addBaseUrl = (url) => {
  const isPublicOut = process.env.REACT_APP_FOR_OUT == '1'
  if (!isPublicOut) {
    return url
  }
  let baseUrl = ''
  if (url.startsWith('/redapi')) {
    baseUrl = 'https://vw.hr-soft.cn'
  }

  return baseUrl + url
}
