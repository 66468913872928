import React, { memo } from 'react'
import errPng from '@/assets/img/login/err.png'
import './style.css'

const ErrTip = (props) => {
  const { errTip } = props
  return (
    <div className="cp-err-tip">
      {errTip && (
        <>
          <img src={errPng} alt="" className="err-img" />
          <span>{errTip}</span>
        </>
      )}
    </div>
  )
}

export default ErrTip
