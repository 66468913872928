import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { toast } from '@chatui/core'
import http from '@/http/fetch'
import TemplateModal from '@/component/tools/temp/modal/TemplateModal'

//eslint-disable-next-line max-lines-per-function
export default function useTempl(props) {
  const { setQuestion, handleSend } = props
  const { isThemeLight } = useSelector((state: any) => state.other)
  const [dialogShow, setDialogShow] = useState(true)
  // 切换主题色时，要重新渲染markdown，改变markdown主题色
  useEffect(() => {
    setDialogShow(false)
    setTimeout(() => {
      setDialogShow(true)
    }, 0)
  }, [isThemeLight])
  // ----------------------------------调用模板 start -----------------------
  // 当前模板内容
  const [activeContext, setActiveContext] = useState('')
  // 是否显示模板弹窗
  const [templateDialogVisit, setTemplateDialogVisit] = useState(false)
  // 模本类型（send，确认时直接发送消息）
  const [templateDialogType, setTemplateDialogType] = useState('')
  // 显示模板弹窗
  const showTemplateDialog = (context) => {
    setActiveContext(context)
    setTemplateDialogVisit(true)
  }
  // 关闭模板弹窗
  const closeTemplateDialog = () => {
    setActiveTemplate(null)
    setActiveContext('')
    setTemplateDialogVisit(false)
    setTemplateDialogType('')
  }
  // 输入框对象
  const inputRef = useRef() as any
  // 模板选项列表对象
  const templateListRef = useRef() as any
  // 获取输入框光标定位
  const getCaretPosition = () => {
    const ref = inputRef.current as any
    const caretPosition = ref.selectionStart
    const elementStyle = window.getComputedStyle(ref)
    const fontSize = parseInt(elementStyle.getPropertyValue('font-size'))
    const fontWidth = fontSize / 2 // 一个字符的宽度为字体大小的一半
    return caretPosition * fontWidth // 光标在input内的位置
  }
  // 获取模板选项列表定位
  const getListPosition = () => {
    const inputRefCurrent = inputRef.current as any
    if (!inputRefCurrent) return
    const inputRect = inputRefCurrent.getBoundingClientRect()
    const offsetTop = inputRect.top
    const offsetLeft = inputRect.left
    const caretPosition = getCaretPosition()
    return {
      bottom: window.innerHeight - offsetTop + 5,
      left: offsetLeft + caretPosition,
    }
  }
  // 模板选项列表定位
  const listPosition = getListPosition() as any
  // 防抖定时器
  const debounceTimer = useRef(null as any)
  const [templateList, setTemplateList] = useState([] as any)
  const [templateListShow, setTemplateListShow] = useState(false)
  const [activeTemplate, setActiveTemplate] = useState(null as any)
  const [hasClickEsc, setHasClickEsc] = useState(false)
  // 输入框输入事件
  //eslint-disable-next-line max-lines-per-function
  const onInputChange = (val) => {
    setQuestion(val)
    // 增加防抖，避免过于频繁地发送请求
    clearTimeout(debounceTimer.current)
    debounceTimer.current = setTimeout(() => {
      if (!val) {
        setHasClickEsc(false)
        setTemplateListShow(false)
        setTemplateList([])
      }
      if (hasClickEsc) {
        return
      }
      if (val.indexOf('\n') > -1) {
        setTemplateList([])
        setTemplateListShow(false)
      } else {
        // “/”显示触发模板选项
        if (val.startsWith('/')) {
          const keyword = val.split('/').pop()
          // 如果输入了空格，则隐藏搜索结果列表
          if (keyword.indexOf(' ') > -1) {
            setTemplateList([])
            setTemplateListShow(false)
          } else {
            searchTemplate(keyword)
          }
        } else {
          setTemplateList([])
          setTemplateListShow(false)
        }
      }
    }, 300)
  }
  // 阻止输入框上下键默认事件
  const onInputKeyDown = (e) => {
    if (templateListShow && !hasClickEsc && (e.keyCode == 40 || e.keyCode == 38)) {
      e.preventDefault()
      return false
    }
  }
  // 模板选项快捷键
  function onTemplateKeyDown(e) {
    // 显示模板选项时，按esc隐藏模板，不再触发
    if (templateList.length && e.keyCode == 27) {
      setHasClickEsc(true)
    }
    if (templateListShow && !hasClickEsc) {
      if (e.keyCode == 38) {
        handleArrowSetTemplate('up')
      } else if (e.keyCode == 40) {
        handleArrowSetTemplate('down')
      } else if (e.keyCode == 13 && activeTemplate) {
        getTemplateDetails(activeTemplate)
        e.preventDefault()
        return false
      }
    }
  }
  // 上下键快捷键选择模板
  const handleArrowSetTemplate = (type) => {
    const lastIndex = templateList.length - 1
    const activeIndex = activeTemplate
      ? templateList.findIndex((item: any) => {
          return item.id == activeTemplate.id
        })
      : -1
    if (type == 'up') {
      if (activeIndex == 0 || activeIndex == -1) {
        setActiveTemplate(templateList[lastIndex])
      } else {
        setActiveTemplate(templateList[activeIndex - 1])
      }
    } else if (type == 'down') {
      if (activeIndex == lastIndex || activeIndex == -1) {
        setActiveTemplate(templateList[0])
      } else {
        setActiveTemplate(templateList[activeIndex + 1])
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onTemplateKeyDown) // 添加全局事件
    return () => {
      window.removeEventListener('keydown', onTemplateKeyDown) // 销毁
    }
  }, [templateListShow, activeTemplate, templateList])

  // 搜索模板选项列表
  const searchTemplate = (keyword) => {
    http
      .get('/fastdev/chatgpt/templatePageList', {
        title: keyword,
        pageSize: 8,
        pageNo: 1,
      })
      .then((res) => {
        setTemplateList(res.data.records)
        if (res.data.records.length) {
          setActiveTemplate(res.data.records[0])
        } else {
          setActiveTemplate(null)
        }
        setTemplateListShow(true)
      })
  }
  // 选中模板，获取详情
  const getTemplateDetails = (item) => {
    setActiveTemplate(item)
    setTemplateDialogType('send')
    setTemplateDialogVisit(true)
    setTemplateListShow(false)
  }
  // 跳转模板管理
  const toTemplate = () => {
    setTemplateDialogVisit(true)
    setTemplateListShow(false)
  }

  // ----------------------------------调用模板 end -----------------------
  //eslint-disable-next-line max-lines-per-function
  const renderTemplComps = () => {
    return (
      <>
        {
          // 模板弹窗
          dialogShow && (
            <TemplateModal
              templateDialogVisit={templateDialogVisit}
              onCloseDialog={closeTemplateDialog}
              activeContext={activeContext}
              activeTemplate={activeTemplate}
              dialogType={templateDialogType}
              handleSend={(text) => handleSend({ text })}
            />
          )
        }
        {
          // 模板选项
          templateListShow && !hasClickEsc && (
            <div
              className="maglev-template-list"
              ref={templateListRef}
              style={{
                bottom: listPosition.bottom,
                left: listPosition.left,
              }}
            >
              {!!templateList.length &&
                templateList.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={`maglev-template-items ${
                        activeTemplate && activeTemplate.id == item.id ? 'active' : ''
                      }`}
                      onClick={() => getTemplateDetails(item)}
                    >
                      <span className="title">{item.title}</span>
                      <span className="template">{item.template}</span>
                    </div>
                  )
                })}
              {!templateList.length && (
                <div onClick={toTemplate} className="maglev-template-nodata">
                  暂无可用模板，立即添加
                </div>
              )}
            </div>
          )
        }
      </>
    )
  }

  return {
    inputRef,
    onInputKeyDown,
    showTemplateDialog,
    onInputChange,
    renderTemplComps,
  }
}
