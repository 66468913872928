import { toast } from '@chatui/core'

// 复制文本
export const copyFn = (str) => {
  const textarea = document.createElement('textarea') // 创建input对象
  textarea.value = str // 设置复制内容
  document.body.appendChild(textarea) // 添加临时实例
  textarea.select() // 选择实例内容
  document.execCommand('Copy') // 执行复制
  document.body.removeChild(textarea) // 删除临时实例
  toast.success('复制成功！')
}

export const sleep = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

const addNewLineAfterCSS = (inputString) => {
  const pattern =
    /(```(css|javascript|html|bash|sql|python|ruby|typescript|json|yaml|haskell|swift|go|kotlin|rust|markdown))(?!\n)/g
  let replacedString = inputString.replace(pattern, '$1\n')
  if (replacedString.indexOf('```java') > -1 && replacedString.indexOf('```javascript') === -1) {
    const javaPattern = /(```java)(?!\n)/g
    replacedString = replacedString.replace(javaPattern, '$1\n')
  }
  if (replacedString != inputString) {
    console.log(inputString)
  }

  return replacedString
}

export const filterContext = (text, picContent?) => {
  let inputText = picContent || text
  if (Array.isArray(picContent)) {
    inputText = text
  }
  // 解决md不识别 `:**`的问题
  let _text = inputText

  //高亮识别```css.ellipsis,在css后面加上换行,除了css,还有其他一系列语言
  _text.replace(/\*\*(.*?)\*\*/g, '**$1** ')
  _text = addNewLineAfterCSS(_text)

  return _text
}

export const extractImageUrl = (str) => {
  //eslint-disable-next-line
  const imageUrlRegex = /\(([^ \)]+)/g
  const matches = str.match(imageUrlRegex)
  return matches ? matches.map((match) => match.substring(1)) : []
}

export const filterImageText = (inputString) => {
  // 使用正则表达式过滤图片链接
  //eslint-disable-next-line
  const result = inputString.replace(/\!\[.*?\]\(.*?\)/g, '')
  return result.trim() // 去除首尾空格并返回结果
}

export const getPkValue = (url) => {
  const index = url.indexOf('pk=')
  if (index !== -1) {
    return url.substring(index + 3)
  } else {
    return ''
  }
}
