import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import VersionComp from '../version/Version'
import ThemeComp from '../theme/Theme'
import ExportToMdComp from '../../tools/export-md/ExportToMd'
import GlobalSearchComp from '../../tools/search/GlobalSearch'

import SourceLibrary from '../source-list/SourceLibrary'
import { Dropdown } from 'antd'
import { EllipsisOutlined, SearchOutlined, DownOutlined } from '@ant-design/icons'
import { setIsThemeLight } from '@/store/actions/other'
import ChatUI, { Avatar } from '@chatui/core'
//@ts-ignore
import userLogoSvg from '@/assets/img/user.svg'
import useRouter from '@/hooks/router/useRouter'
import http from '@/http/fetch'
import Cookies from 'js-cookie'
import './NavBar.css'

//eslint-disable-next-line max-lines-per-function
function NavBarComp() {
  const dispatch = useDispatch()
  const { history, query, historyPush } = useRouter()
  // 用户信息
  const { userInfo } = useSelector((state: any) => state.user)

  const { isThemeLight } = useSelector((state: any) => state.other)
  const dropdownMenu = [
    {
      key: 'search',
      label: '全局搜索',
      icon: <SearchOutlined />,
    },
    {
      key: 'theme',
      label: '切换主题',
      icon: (
        <i
          className={`redsea-icon-font ${
            isThemeLight ? 'redsea-icon-theme-light' : 'redsea-icon-theme-dark'
          }`}
        ></i>
      ),
    },
    {
      key: 'export',
      label: '导出到MD',
      icon: (
        <i
          className={`redsea-icon-font ${
            isThemeLight ? 'redsea-icon-export-light' : 'redsea-icon-export-dark'
          }`}
        ></i>
      ),
    },
  ]
  const switchTheme = () => {
    window.localStorage.setItem('chatgpt-theme-light', JSON.stringify(!isThemeLight))
    dispatch(setIsThemeLight(!isThemeLight))
  }
  const [showExportModal, setShowExportModal] = useState(false)
  const [showSearchModal, setShowSearchModal] = useState(false)
  const onDropdownClick = ({ key }) => {
    switch (key) {
      case 'export':
        setShowExportModal(true)
        break
      case 'theme':
        switchTheme()
        break
      case 'search':
        setShowSearchModal(true)
        break
    }
  }

  const doLogOut = async () => {
    const url = `/api/system/logout`
    const params = {}
    const res = await http.post(url, params)
    Cookies.set('REDSEA-TOKEN', '')
    historyPush('/login')
  }

  const onAvatarDropdownClick = ({ key }) => {
    switch (key) {
      case 'logout':
        doLogOut()
        break
      case 'setting':
        doPersonSetting()
        break
    }
  }

  const doPersonSetting = () => {
    historyPush('/user/setting?from=/redgpt')
  }

  const isPublicOut = process.env.REACT_APP_FOR_OUT == '1'
  const isPublicHuman = process.env.REACT_APP_FOR_HUMAN == '1'
  const avatarItems = [
    {
      key: 'setting',
      label: '个人设置',
    },
    {
      key: 'logout',
      label: '退出登录',
    },
  ]

  const avatarImg = userInfo?.user?.avatar

  const renderNavRight = () => {
    if (isPublicHuman) {
      return null
    }
    if (isPublicOut) {
      return (
        <div className="header-avatar-box">
          <Dropdown
            menu={{
              items: avatarItems,
              onClick: onAvatarDropdownClick,
            }}
            overlayClassName={isThemeLight ? '' : 'ant-dropdown-dark'}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Avatar src={avatarImg} size="sm"></Avatar>
              <DownOutlined />
            </a>
          </Dropdown>
        </div>
      )
    }
    return (
      <div className="navbar-items pc">
        <ExportToMdComp showModal={showExportModal} onHideModal={() => setShowExportModal(false)} />
      </div>
    )
  }

  return (
    <div className="navbar-box">
      <div className="navbar-items mobile" style={{ height: '20px' }}>
        <Dropdown
          overlayClassName={isThemeLight ? '' : 'ant-dropdown-dark'}
          menu={{
            items: dropdownMenu,
            onClick: onDropdownClick,
          }}
        >
          <div className="chatflow-switch-theme">
            <EllipsisOutlined />
          </div>
        </Dropdown>
      </div>
      {renderNavRight()}

      <div className="navbar-items pc">
        <ThemeComp />
      </div>
      {isPublicHuman ? null : (
        <div className="navbar-items pc">
          <GlobalSearchComp
            showModal={showSearchModal}
            onHideModal={() => setShowSearchModal(false)}
          />
        </div>
      )}

      <div className="navbar-items">
        <VersionComp />
      </div>
      {!isPublicOut && (
        <div className="navbar-items">
          <SourceLibrary />
        </div>
      )}
    </div>
  )
}

export default NavBarComp
