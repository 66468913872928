import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Divider, Spin } from 'antd'
import { Modal, toast } from '@chatui/core'
import http from '@/http/fetch'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import TemplateModal from '@/component/tools/temp/modal/TemplateModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import ThemeComp from '@/component/header/theme/Theme'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { filterContext } from '@/utils/common'

import './template.css'

//eslint-disable-next-line max-lines-per-function
function Template() {
  document.title = 'RedGPT模板管理'
  const { userInfo } = useSelector((state: any) => state.user)
  const { isThemeLight } = useSelector((state: any) => state.other)
  const userPhoto = userInfo?.userPhoto
    ? `https://vw.hr-soft.cn/uploadfile${userInfo.userPhoto}`
    : ''

  const [total, setTotal] = useState(0)
  const [list, setList] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [loading, setLoading] = useState(false)

  const initPage = () => {
    if (pageNo == 1) {
      getTemplate('init')
    } else {
      setPageNo(1)
    }
  }
  const nextPage = () => {
    setPageNo(pageNo + 1)
  }
  const getTemplate = (isInit?) => {
    if (loading) {
      return
    }
    setLoading(true)
    http
      .get('/fastdev/chatgpt/templatePageList', {
        pageNo: pageNo,
        pageSize: 20,
        title: '',
      })
      .then((res) => {
        setTotal(res.data.total)
        if (isInit) {
          setList(res.data.records)
        } else {
          const _list = [...list, ...res.data.records]
          //@ts-ignore
          setList(_list)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (pageNo == 1) {
      getTemplate('init')
    } else {
      getTemplate()
    }
  }, [pageNo])

  const [activeTemplate, setActiveTemplate] = useState('')
  const [templateDialogVisit, setTemplateDialogVisit] = useState(false)
  const showTemplateDialog = (item) => {
    setActiveTemplate(item)
    setTemplateDialogVisit(true)
  }
  const closeTemplateDialog = () => {
    setActiveTemplate('')
    setTemplateDialogVisit(false)
    getTemplate('init')
  }

  const [delConfirmOpen, setDelConfirmOpen] = useState(false)
  const showDelTemplateDialog = (item) => {
    setActiveTemplate(item)
    setDelConfirmOpen(true)
  }
  const delTemplate = () => {
    setDelConfirmOpen(false)
    http
      //@ts-ignore
      .get('/fastdev/chatgpt/templateDelete?id=' + activeTemplate.id)
      .then((res) => {
        toast.success('删除成功')
        initPage()
      })
  }

  return (
    <div
      id="scrollableDiv"
      className={`template-page self-def-scroll-style ${
        isThemeLight ? 'chatgpt-theme-light' : 'chatgpt-theme-dark'
      }`}
    >
      <div className="template-page-header">
        <div className="template-page-title">RedGPT模板管理</div>
        <div className="user-box">
          {userPhoto && <img className="user-photo" src={userPhoto} alt="" />}
        </div>
      </div>
      {
        <div style={{ display: 'none' }}>
          <ThemeComp />
        </div>
      }
      <div className="template-add-btn" onClick={() => setTemplateDialogVisit(true)}>
        <PlusOutlined />
      </div>
      <InfiniteScroll
        dataLength={list.length}
        next={nextPage}
        hasMore={list.length < total}
        loader={
          <Divider plain>
            <Spin />
          </Divider>
        }
        endMessage={total > 8 && <Divider plain>没有更多了~</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <div className="template-list">
          {list.map((item: any) => {
            return (
              <div className="template-items" key={item.id}>
                <div className="template-items-title" title={item.title}>
                  {item.title}
                </div>
                <div className="template-items-context Message-content">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {filterContext(item.template)}
                  </ReactMarkdown>
                </div>
                <div className="template-items-btn-box">
                  <EditOutlined title="编辑" onClick={() => showTemplateDialog(item)} />
                  <DeleteOutlined title="删除" onClick={() => showDelTemplateDialog(item)} />
                </div>
              </div>
            )
          })}
        </div>
      </InfiniteScroll>
      <TemplateModal
        templateDialogVisit={templateDialogVisit}
        onCloseDialog={closeTemplateDialog}
        activeTemplate={activeTemplate}
      />
      <Modal
        active={delConfirmOpen}
        title="是否删除该模板"
        showClose={false}
        onClose={() => setDelConfirmOpen(false)}
        vertical={false}
        btnVariant="outline"
        actions={[
          {
            label: '确定',
            color: 'primary',
            onClick: delTemplate,
          },
          {
            label: '取消',
            onClick: () => setDelConfirmOpen(false),
          },
        ]}
      ></Modal>
    </div>
  )
}

export default Template
