export const SET_VERSION = 'SET_VERSION'
export const SET_IS_THEME_LIGHT = 'SET_IS_THEME_LIGHT'
export const SET_SOURCE_LIBRARY = 'SET_SOURCE_LIBRARY'
export const SET_TEMPERATURE = 'SET_TEMPERATURE'
export const SET_MAXTOKENS = 'SET_MAXTOKENS'
export const SET_VERSION_LIST = 'SET_VERSION_LIST'
export const SET_SOURCE_AI_TYPE = 'SET_SOURCE_AI_TYPE'

export const AI_SOURCE_MAP = {
  OpenAI: 'OpenAI',
  Baidu: 'Baidu',
  Zhipu: 'Zhipu',
}

export const gptOptions = [
  { value: 'GLM3', label: 'GLM-3' },
  { value: 'gpt-3.5-turbo-0125', label: 'GPT-3.5' },
  { value: 'gpt-4-0125-preview', label: 'GPT-4' },
  { value: 'gpt-4-vision-preview', label: 'GPT-4V' },
]

export const baiduOptions = [{ value: 'completions_pro', label: 'EB-4' }]

export const zhipuOptions = [
  { value: 'glm-3-turbo', label: 'GLM-3' }, //ZHIPU_GLM3
  { value: 'glm-4', label: ' GLM-4' }, //ZHIPU_GLM4
  { value: 'glm-4v', label: 'GLM-4V' }, //ZHIPU_GLM4V
]

export const setVersion = (payload) => ({
  type: SET_VERSION,
  payload,
})

export const setIsThemeLight = (payload) => ({
  type: SET_IS_THEME_LIGHT,
  payload,
})

export const setSourceLibrary = (payload) => ({
  type: SET_SOURCE_LIBRARY,
  payload,
})

export const setTemperature = (payload) => ({
  type: SET_TEMPERATURE,
  payload,
})

export const setMaxTokens = (payload) => ({
  type: SET_MAXTOKENS,
  payload,
})

export const setVersionList = (payload) => ({
  type: SET_VERSION_LIST,
  payload,
})

export const setSourceAiType = (payload) => ({
  type: SET_SOURCE_AI_TYPE,
  payload,
})
