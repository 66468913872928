/* eslint-disable */ 
import { Base64 } from 'js-base64';
import md5 from 'md5-js';

export const getOrderAuthorization = (config) => {
  const { param: encodeStr } = config?.auth;
  const SIGN_KEY = 'workorder_#%(/AG'; // 单独找谢东丽要签名
  const timestamp = new Date().getTime() + '';
  const str = encodeStr + '&' + timestamp + '&' + SIGN_KEY; // encodeStr=param
  const md5value = md5(str);

  
  const sign = Base64.encode(md5value);
  const auth = {
    sign,
    timestamp,
  };
  return JSON.stringify(auth);
};
