import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tokenizerFn, MAXTOKENS, DEFAULT_MODEL } from '@/utils/chatgpt'
import { base64Decode, decodeBase64 } from '@/component/uts'
import { aiAvatar, redAvatar, initialMessages, paragraphsRegex } from '@/component/chat/constants'
import { echartSystemContext } from '@/component/echarts/systemContext'
import useUpdateActiveHistory from '@/hooks/useUpdateActiveHistory'
import http from '@/http/fetch'
// http请求终止控制器
let controller = null as any

//eslint-disable-next-line max-lines-per-function
const useChatGptFunc = (props: any) => {
  const { version, temperature, maxTokens, versionAuth, isThemeLight, sourceLibrary } = useSelector(
    (state: any) => state.other
  )
  // 会话列表
  const { activeHistory, historyList } = useSelector((state: any) => state.histroy)
  const {
    setParagraphs,
    setQueryContext,
    isKnowlogAI,
    appendMsg,
    setProxy,
    queryContext,
    question,
    messages,
    activeHistoryId,
    proxy,
    paragraphs,
    updateMsg,
    needCreateTitle,
    stopBtnShow,
    setStopBtnShow,
  } = props

  const [questionLink, setQuestionLink] = useState([] as any)
  // 等待AI回复
  const [loading, setLoading] = useState(false)

  //更新会话
  const { updateActiveHistory, updateHistory } = useUpdateActiveHistory({
    activeHistoryId,
    questionLink,
    proxy,
  })

  // 接口响应流
  const dataRef = useRef({ reader: null } as any)

  useEffect(() => {
    if (!dataRef.current.reader) {
      return
    }
    if (stopBtnShow) {
      console.log('开始请求数据！')
    } else {
      requestDone()
    }
  }, [stopBtnShow])

  useEffect(() => {
    handleDataPushOneByOne(messages)
  }, [messages])
  //eslint-disable-next-line max-lines-per-function
  const requestDone = async () => {
    dataRef.current.reader = null
    // console.log('请求完成！', messages)
    // console.log('activeHistoryId', activeHistoryId.current)
    // console.log('historyList', historyList)
    updateMsg(lastId, {
      ...saveMsgItem,
      questionLink,
    })
    updateActiveHistory({
      selfMsg,
      question: saveMsgItem.content.text,
    })
    if (proxy && proxy.agentId != 'chartTool') {
      setProxy(null)
    }
    setLastId('')
  }

  async function getQuestionReprompt(trimValue) {
    return http.post(
      '/redapi/sp/tech/chatgpt/vector/questionReprompt',
      {
        params: JSON.stringify({
          question: trimValue,
          question_type: 'DocumentSearch',
          category: sourceLibrary.join(','),
          top_k: 20,
        }),
        messages: queryContext,
      },
      {
        dataType: 'json',
        noAuth: true,
      }
    )
  }

  //百度输入的msg数组必须是奇数
  const handleForBaiduAi = (_queryContext, version) => {
    if (version != 'completions_pro') {
      return _queryContext
    }
    const arrLen = _queryContext.length
    if (arrLen % 2 == 0) {
      //偶数
      return _queryContext.filter((it, index) => {
        return index > 0
      })
    }
    return _queryContext
  }
  //处理图片后面后缀，多余的字符串
  const handleGpt4vImage = (queryContext_) => {
    if (version != 'gpt-4-vision-preview') {
      return queryContext_
    }
    const newList = queryContext_?.map((it) => {
      if (Array.isArray(it.content) && it.content.length > 0) {
        it.content?.map((v2) => {
          if (v2.image_url && v2.image_url.url) {
            v2.image_url.url = v2.image_url.url.replace(/(\.png|\.jpg|\.jpeg).*$/, '$1')
          }
        })
      }
      return it
    })
    return newList
  }

  // 保存当前问题
  const [selfMsg, setSelfMsg] = useState({} as any)
  // 流式输出
  //eslint-disable-next-line max-lines-per-function
  async function querySendByStream(_queryContext, _selfMsg, otherData = {}) {
    setSelfMsg(_selfMsg)
    controller = new AbortController()
    const signal = controller.signal

    setStopBtnShow(true)
    let queryContext_ = handleForBaiduAi(_queryContext, version)
    queryContext_ = handleGpt4vImage(queryContext_)

    // queryContext_ = [
    //   {
    //     // role: 'system',
    //     role: 'user',
    //     content:
    //       '请你充当一名资深的AI智能客服。请以简介清晰自然口语的方式与用户对话（切记不要啰嗦重复），不要让用户发觉你是AI。你所在的公司为“广州红海云计算股份有限公司”，简称“红海云”，你的任务是提供产品售前咨询，回答用户咨询关于我们公司软件产品（eHR）或者公司的相关问题。如果用户提到与产品或公司无关的信息，请自然的回避，并引导到公司和产品的内容上。\n注意：\n1. 如果用户的问题没有可用于参考的资料，请提醒用户：“暂时无法回答您这个问题，您可以留下您电话联系方式，稍后我们将安排我们产品经理与您一对一电话沟通，解答您的问题”。\n2. 如果你不知道，请参考第1点回答。',
    //   },
    //   {
    //     role: 'user',
    //     content: '系统怎么收费',
    //   },
    //   {
    //     role: 'assistant',
    //     content:
    //       '我们的收费主要是根据功能需求及实际业务场景来评估的。请问您公司的需求是怎样的？目前想要上线哪些模块呢？这样我可以为您提供更精确的信息。',
    //   },
    //   {
    //     role: 'user',
    //     content: `请按要求回答问题，但只能依据我们前几次对话记录里的知识回答（不要使用你自己的知识），如果你无法回答，请调用以下工具作为回答：\nsearch: [{"name":"Search-Engine","description":"此工具可以搜索任何知识","parameters":{"type":"string","properties":{"keyword":{"description":"需要搜索的关键字"}},"required":["keyword"]}}]\n以下是回答的思考示例：\n\nQuestion: xxx\nThought: 前几次对话记录里的知识能够直接回答这个问题。\nAction: Direct answer\nAnswer: xxxx\n\n\nQuestion: xxx\nThought: 前几次对话记录里知识不充分，无法直接回答，需要调用工具。\nAction: Tool invocation\nAnswer: [{\"name\": \"Search-Engine\", \"keyword\": \"xxxx\"}...]\n\n现在我们开始吧，让我一步一步的思考。\nQuestion: 系统支持英文吗\nThought:`,
    //   },
    // ]
    try {
      const postBody = {
        messages: queryContext_,
        model: version,
        // id: activeHistory.id,
        chatStream: '1',
        ...otherData,
      }

      if (proxy && proxy.agentId) {
        if (proxy.agentId == 'chartTool') {
          postBody['messages'].unshift({
            role: 'system',
            content: echartSystemContext,
          })
        } else {
          postBody['agentMode'] = 'enable'
          postBody['agentId'] = proxy.agentId
        }
      }
      const response = (await fetch('/fastdev/chatgpt/answer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Accept': 'text/event-stream; charset=utf-8', // 添加Accept头，确保接受UTF-8编码
        },
        body: JSON.stringify(postBody),
        signal,
      })) as any

      setLoading(false)

      if (!response.ok) {
        setStopBtnShow(false)
        if (response.status !== 500) {
          appendErrorMsg('接口错误，请重试。', _selfMsg)
          return
        }
        try {
          // console.log('response.json()', response.text());
          response.json().then((res) => {
            if (!res.result) {
              appendErrorMsg('接口错误，请重试。', _selfMsg)
              return
            }
            const _err = decodeBase64(res.result)
            appendErrorMsg(_err, _selfMsg)
          })
          return
        } catch (error) {
          appendErrorMsg('接口错误，请重试。', _selfMsg)
          return
        }
      }

      const msgItem = {
        type: 'html',
        content: { text: '' },
      }
      appendMsg({
        ...msgItem,
        user: isKnowlogAI ? redAvatar : aiAvatar,
      })

      // console.log('response ... ', response)
      dataRef.current.reader = response.body.getReader()
    } catch (error) {
      // setLoading(false)
      setStopBtnShow(false)

      console.log('Error in POST request:', error)
    } finally {
      setLoading(false)
    }

    return controller
  }
  // 记录AI输出的内容，用于中断时，可保存消息
  const [saveMsgItem, setSaveMsgItem] = useState(null as any)
  // AI流式输出时，用于记录最后一条会话消息id，实时更新最后一条消息
  const [lastId, setLastId] = useState('')

  // 流式输出更新消息
  //eslint-disable-next-line max-lines-per-function
  const handleDataPushOneByOne = async (messages) => {
    const reader = dataRef.current.reader
    if (!reader) {
      return
    }
    // 记录最后一条会话消息id
    const lastIndex = messages.length - 1
    const _msg = messages[lastIndex]
    const _lastId = _msg._id
    setLastId(_lastId)
    const decoder = new TextDecoder('utf-8')
    const prefix = 'data: '
    try {
      let chunk = await reader.read()
      while (!chunk.done && chunk.value) {
        const chunkData = decoder.decode(chunk.value, { stream: true })
        if (!chunkData.startsWith(prefix)) {
          const errorInfo = JSON.parse(chunkData)
          console.error(errorInfo.result)
          appendErrorMsg('接口错误，请重试。', selfMsg)
          setStopBtnShow(false)
          setLoading(false)
          break
        }
        const lines = chunkData.split('\n')
        for (const line of lines) {
          const [label, base64Str] = line.split(': ')
          if (label === 'data') {
            const decodedStr = base64Decode(base64Str) as any
            if (decodedStr.includes('DONE')) {
              const htmlStr = _msg.content.text
              _msg.content.text = htmlStr
              _msg.needAudio = selfMsg.needAudio
              _msg.agentId = proxy && proxy.agentId
              _msg.agentLoading = false
              setQueryContext([
                ...queryContext,
                {
                  role: 'assistant',
                  content: htmlStr,
                },
              ])
              setStopBtnShow(false)
              // 心流模式根据paragraphsRegex剪切段落
              if (selfMsg.needAudio) {
                const _paragraphs = _msg.content.text
                  .split(paragraphsRegex)
                  .filter((item) => item != '')
                // AI输出时，不读最后一个段落。所以完成后，需要插入一个空格符，才会读最后一个段落
                _paragraphs.push('')
                setParagraphs(_paragraphs)
              }
            } else if (decodedStr) {
              _msg.content.text += decodedStr
              _msg.agentId = proxy && proxy.agentId
              _msg.agentLoading = true
              // 心流模式根据paragraphsRegex剪切段落
              if (selfMsg.needAudio) {
                if (paragraphsRegex.test(_msg.content.text)) {
                  const _paragraphs = _msg.content.text
                    .split(paragraphsRegex)
                    .filter((item) => item != '')
                  // AI输出时，最后一个段落还未完成，不需要阅读
                  if (_paragraphs.length - paragraphs.length > 1) {
                    setParagraphs(_paragraphs)
                  }
                }
              }
            }
            updateMsg(_lastId, _msg)
            setSaveMsgItem(_msg)
          }
        }
        chunk = await reader.read() // 读取下一个数据块
      }
    } catch (error) {
      setStopBtnShow(false)
      setLoading(false)
    }
  }

  // 添加错误信息
  function appendErrorMsg(text, _selfMsg) {
    const _msgItem = {
      type: 'error',
      content: { text },
    }
    appendMsg({
      ..._msgItem,
      user: isKnowlogAI ? redAvatar : aiAvatar,
    })

    const _context = activeHistory.context || '[]'
    const _contextList = JSON.parse(_context)
    _contextList.push(_selfMsg)
    _contextList.push(_msgItem)
    // console.log('appendErrorMsg', _contextList);

    updateHistory({
      id: activeHistory.id,
      context: JSON.stringify(_contextList),
    })
  }

  // 生成标题
  //eslint-disable-next-line max-lines-per-function
  function createChatTitle(postData) {
    // console.log('createChatTitle', JSON.parse(postData.context || '[]'))
    try {
      const _firstMsg = JSON.parse(postData.context).filter((item) => item.type == 'self_msg')[0]
      const _msgContent = _firstMsg.picContent || _firstMsg.content.text
      const _msgSlice = _msgContent.slice(0, 2000)
      http
        .post(
          '/fastdev/chatgpt/answer',
          {
            messages: [
              {
                role: 'user',
                content:
                  '请将下列文本生成一个主题，字符串长度控制在20以内，不含标点符号，不含空格和换行符\n\n' +
                  _msgSlice,
              },
            ],
            model: DEFAULT_MODEL,
          },
          {
            hideErr: true,
            dataType: 'json',
          }
        )
        .then((res: any) => {
          if ((res.status === 200 && res.data && res.data.code == 'ok') || res.code == 'ok') {
            const result = (res.data && res.data.result) || res.result
            const _activeHistory = {
              id: postData.id,
              title: result.replace(/\r|\n/gi, '').substring(0, 20),
            }
            updateHistory(_activeHistory)
          }
        })
        .finally(() => {
          needCreateTitle.current = false
        })
    } catch (err) {
      console.log(err)
    }
  }

  return {
    controller,
    createChatTitle,
    setLoading,
    setQuestionLink,
    loading,
    getQuestionReprompt,
    querySendByStream,
  }
}

export default useChatGptFunc
