const substring = 'substring'
const split = 'split'
const reverse = 'reverse'
const join = 'join'
const _toString = 'toString'
const substr = 'substr'
const replace = 'replace'

const enKey = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
const deKey = [
  -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,
  52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1, -1, 26, 27,
  28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
  -1, -1, -1, -1, -1,
]

interface Base64 {
  enKey: any
  deKey: any
}

class Base64 {
  constructor() {
    this.enKey = enKey
    this.deKey = deKey
  }

  getHex(str) {
    return {
      str: str[substring](4),
      hex: str[substring](0, 4)[split]('')[reverse]()[join](''),
    }
  }
  getDec(str) {
    str = parseInt(str, 16)[_toString]()
    return {
      pre: str[substring](0, 2)[split](''),
      tail: str[substring](2)[split](''),
    }
  }
  delStr(str, pos) {
    const s = str[substring](0, pos[0])
    const del = str[substr](pos[0], pos[1])
    return s + str[substring](pos[0])[replace](del, '')
  }
  getPos(str, pos) {
    return [str.length - pos[0] - pos[1], pos[1]]
  }
  decode(str) {
    const sh = this.getHex(str)
    const pos = this.getDec(sh.hex)
    let d = this.delStr(sh.str, pos.pre)
    d = this.delStr(d, this.getPos(d, pos.tail))
    return decodeURIComponent(escape(this.atob(d)))
  }
  encode(str) {
    let base64 = this.btoa(unescape(encodeURIComponent(str)))
    const random: any = this.getRanNum(base64)
    const pos = this.getDec(random)
    base64 = this.addStr(base64, pos)
    return random[_toString]()[split]('')[reverse]()[join]('') + base64
  }
  addStr(str, pos) {
    const r1 = this.getRanStr(pos.pre[1])
    const r2 = this.getRanStr(pos.tail[1])
    const pre = this.insertStr(str, r1, pos.pre[0])
    const tail = pre.length - pos.tail[0]
    str = this.insertStr(pre, r2, tail)
    return str
  }
  //eslint-disable-next-line
  atob(src) {
    const str: any = []
    let ch1, ch2, ch3, ch4
    let pos = 0
    //eslint-disable-next-line
    src = src.replace(/[^A-Za-z0-9\+\/]/g, "");
    while (pos + 4 <= src.length) {
      ch1 = this.deKey[src.charCodeAt(pos++)]
      ch2 = this.deKey[src.charCodeAt(pos++)]
      ch3 = this.deKey[src.charCodeAt(pos++)]
      ch4 = this.deKey[src.charCodeAt(pos++)]
      str.push(
        String.fromCharCode(
          ((ch1 << 2) & 255) + (ch2 >> 4),
          ((ch2 << 4) & 255) + (ch3 >> 2),
          ((ch3 << 6) & 255) + ch4
        )
      )
    }
    if (pos + 1 < src.length) {
      ch1 = this.deKey[src.charCodeAt(pos++)]
      ch2 = this.deKey[src.charCodeAt(pos++)]
      if (pos < src.length) {
        ch3 = this.deKey[src.charCodeAt(pos)]
        str.push(
          String.fromCharCode(((ch1 << 2) & 255) + (ch2 >> 4), ((ch2 << 4) & 255) + (ch3 >> 2))
        )
      } else {
        str.push(String.fromCharCode(((ch1 << 2) & 255) + (ch2 >> 4)))
      }
    }
    return str.join('')
  }
  btoa(src) {
    const str: any = []
    let ch1, ch2, ch3
    let pos = 0
    while (pos + 3 <= src.length) {
      ch1 = src.charCodeAt(pos++)
      ch2 = src.charCodeAt(pos++)
      ch3 = src.charCodeAt(pos++)
      str.push(this.enKey.charAt(ch1 >> 2), this.enKey.charAt(((ch1 << 4) + (ch2 >> 4)) & 63))
      str.push(this.enKey.charAt(((ch2 << 2) + (ch3 >> 6)) & 63), this.enKey.charAt(ch3 & 63))
    }
    if (pos < src.length) {
      ch1 = src.charCodeAt(pos++)
      str.push(this.enKey.charAt(ch1 >> 2))
      if (pos < src.length) {
        ch2 = src.charCodeAt(pos)
        str.push(this.enKey.charAt(((ch1 << 4) + (ch2 >> 4)) & 63))
        str.push(this.enKey.charAt((ch2 << 2) & 63), '=')
      } else {
        str.push(this.enKey.charAt((ch1 << 4) & 63), '==')
      }
    }
    return str.join('')
  }
  insertStr(str, addstr, pos) {
    return str[substring](0, pos) + addstr + str[substring](pos)
  }

  ranNumber(str) {
    const ranArr: any = []
    let n = ''
    const length = str.length
    for (let i = 4101; i <= 9999; i++) {
      n = i[_toString](16)
      if (length >= 8 && !(Math.floor(i / 100) % 10 === 0 || i % 10 === 0) && n.length === 4) {
        if (Math.floor(i / 1000) <= length / 2 && Math.floor((i % 100) / 10) <= length / 2) {
          ranArr.push(n)
        }
      } else {
        if (i % 100 === 0 && n.length === 4) {
          if (Math.floor(i / 1000) <= length) {
            ranArr.push(n)
          }
        }
      }
    }
    return ranArr
  }

  getRanNum(str) {
    const ranArr = this.ranNumber(str)
    const length = ranArr.length
    const ran = Math.round(Math.random() * (length - 1))
    return ranArr[ran]
  }

  getRanStr(num) {
    const key = this.enKey.split('')
    const length = key.length
    let res = ''
    for (; num--; ) {
      const id = Math.round(Math.random() * (length - 1))
      res += key[id]
    }
    return res
  }
}

export default new Base64()
