export const SET_HISTROY_LIST = 'SET_HISTROY_LIST'
export const SET_ACTIVE_HISTROY = 'SET_ACTIVE_HISTROY'

export const setHistroyList = (payload) => ({
  type: SET_HISTROY_LIST,
  payload,
})

export const setActiveHistroy = (payload) => ({
  type: SET_ACTIVE_HISTROY,
  payload,
})
