import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTemperature } from '@/store/actions/other'
import { Input } from 'antd'

import './Temperature.css'

//eslint-disable-next-line max-lines-per-function
function TemperatureComp() {
  const dispatch = useDispatch()
  const { temperature, versionAuth, isThemeLight } = useSelector((state: any) => state.other)
  const { userInfo } = useSelector((state: any) => state.user)

  const hasAuth = userInfo && versionAuth.indexOf(userInfo.userId) !== -1

  const [temperatureVal, setTemperatureVal] = useState('')
  const inputChange = (e) => {
    const val = e.target.value
    setTemperatureVal(val)
    dispatch(setTemperature(val))
    window.localStorage.setItem('temperature', val)
  }

  useEffect(() => {
    setTemperatureVal(temperature)
  }, [temperature])

  useEffect(() => {
    const _temperature = window.localStorage.getItem('temperature')
    if (_temperature) {
      setTemperatureVal(_temperature)
      dispatch(setTemperature(_temperature))
    }
  }, [])

  return (
    hasAuth && (
      <div className="multiwindow-temperature-box">
        <span className="title">temperature：</span>
        <Input size="small" placeholder="请输入" value={temperatureVal} onChange={inputChange} />
      </div>
    )
  )
}

export default TemperatureComp
