import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './store/index'
import '@chatui/core/es/styles/index.less'
import '@chatui/core/dist/index.css'
import './index.css'
import './assets/css/theme.css'
import './assets/iconfont/iconfont.css'

//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
