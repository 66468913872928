const StopBtn = (props) => {
  const { stopResponse } = props
  return (
    <button className="stopBtn" onClick={stopResponse}>
      <div className="gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M6 7v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1z"
          />
        </svg>
        停止生成
      </div>
    </button>
  )
}

export default StopBtn
