// AI头像
export const aiAvatar = { avatar: './ai.png' }
// 红海logo
export const redAvatar = { avatar: './logo.png' }
// 初始问候语
export const initialMessages = [
  {
    isInit: true,
    type: 'html',
    content: { text: '' },
    user: aiAvatar,
    createdAt: new Date().getTime(),
    hasTime: true,
  },
]

// 文本符号分割，分段阅读
export const paragraphsRegex = /[，。！？,.!?、：:\n]/
