import { getSelfMenuList, saveSelfMenuList, getGptMenuList } from '../../apis/chatflow/menu-list'

const getCommonList = async () => {
  const res = (await getGptMenuList()) as any
  if (res.code == 200) {
    const data = res.data
    return data
  }
  return []
}

const getMyCreateAiList = async () => {
  const lists = await getCommonList()
  const _list = lists?.filter((item) => {
    return item.is_public == '0'
  })
  return _list
}

const isInAiRoots = (item, aiRoots) => {
  const findOne = aiRoots.find((it) => {
    return it.code_no == item.code_no
  })
  if (findOne) {
    return findOne
  }
  return false
}

const isInmyAiLists = (item, myAiLists) => {
  return isInAiRoots(item, myAiLists)
}

const compareListAndAi = (aiRoots, myAiLists) => {
  const newList = [] as any
  myAiLists.map((item) => {
    const findOne = isInAiRoots(item, aiRoots)
    if (findOne) {
      //修改
      const _item = {
        ...findOne,
        ...item,
      }
      newList.push(_item)
    } else {
      newList.push(item)
    }
  })
  return newList
}

const isMayDeleteItem = (aiRoots, myAiLists) => {
  const newList = [] as any
  aiRoots.map((item) => {
    const findOne = isInmyAiLists(item, myAiLists) //找不到表示被删除了
    if (findOne) {
      newList.push(item)
    }
  })
  return newList
}

const getAiRoots = (list) => {
  const findOne = list.find((it) => {
    return it.code_no == 'ai_self_root'
  })
  if (findOne) {
    return findOne.children
  }
  return []
}

const getFastRoots = (list) => {
  const findOne = list.find((it) => {
    return it.code_no == 'ai_fast_root'
  })
  if (findOne) {
    return findOne.children
  }
  return []
}

const isHasAISelfRoot = () => {}

export const compareCurSelfAndCommonSelf = (list, commonList) => {
  const curSelf = list.find((it) => {
    return it.code_no == 'ai_self_root'
  })
  const commonSelfList = commonList.filter((it) => {
    return it.is_public == 0
  })
  const curSelfList = curSelf?.children || []

  const newSelfList = replaceElements(curSelfList, commonSelfList)

  const hasSelfRoot = list.find((it) => {
    return it.code_no == 'ai_self_root'
  })

  let _list = []
  if (hasSelfRoot) {
    _list = replaceSelfList(list, newSelfList)
  } else {
    _list = pushSelfList(list, newSelfList)
  }
  return _list
}

const pushSelfList = (list, newSelfList) => {
  const selfItem = {
    code_no: 'ai_self_root',
    code_text: '我的AI',
    model: 'gpt-3.5-turbo-0125',
    right_menu: 1,
    ask: 0,
    parent_code_no: '-1',
    template_chat: 1,
    order_no: 0,
    input_required: 1,
    md_url: null,
    placeholder: null,
    is_public: 0,
    children: newSelfList,
  }
  list.splice(1, 0, selfItem)
  return list
}

const replaceSelfList = (list, newList) => {
  return list.map((item) => {
    if (item.code_no == 'ai_self_root') {
      item.children = newList
    }
    return item
  })
}

function replaceElements(a, b) {
  if (a.length === 0) {
    return b
  }
  if (b.length === 0) {
    return []
  }
  // 遍历b数组
  for (let i = 0; i < b.length; i++) {
    const index = a.findIndex((item) => item.code_no === b[i].code_no)
    // 如果a中存在b中的元素，就将a中对应位置的元素替换为b中的元素
    if (index !== -1) {
      a[index] = {
        ...a[index],
        ...b[i],
      }
    } else {
      // 如果a中没有b中的元素，就将b中的元素插入a
      a.push(b[i])
    }
  }

  // 遍历a数组
  for (let i = 0; i < a.length; i++) {
    const index = b.findIndex((item) => item.code_no === a[i].code_no)
    // 如果a中的元素在b中不存在，就将a中的元素删除
    if (index === -1) {
      a.splice(i, 1)
      i-- // 因为删除了元素，需要将i减1
    }
  }

  return a
}

export const doSetMyMenuList = async () => {
  const res = await getSelfMenuList()
  //有保存过菜单，要同步
  if (res && res.length > 0) {
    const commonList = await getCommonList()
    const myAiLists = commonList?.filter((item) => {
      return item.is_public == '0'
    })
    const _list = compareCurSelfAndCommonSelf(res, commonList)

    const list_ = replaceFastRootChildren(_list, myAiLists)

    await saveSelfMenuList(list_)
  }
}

const syncFastMenuByMyAI = (_list) => {
  const fastItems = getFastRoots(_list)
  const myAiItems = getAiRoots(_list)
  const _fastItems = fastItems.map((item) => {
    const findOne = myAiItems.find((it) => {
      return it.code_no == item.code_no
    })
    if (findOne) {
      return findOne
    }
    return item
  })
  return _fastItems
}

const isMayDeleteFastItem = (fastItems, myAiLists) => {
  const newList = [] as any
  fastItems.map((item) => {
    if (item.parent_code_no != 'ai_self_root') {
      newList.push(item)
    } else {
      const findOne = isInmyAiLists(item, myAiLists)
      if (findOne) {
        newList.push(item)
      }
    }
  })
  return newList
}

const replaceFastRootChildren = (_list, myAiLists) => {
  const newFastItems = syncFastMenuByMyAI(_list)
  const _newList = isMayDeleteFastItem(newFastItems, myAiLists)
  const newlist = _list.map((it) => {
    if (it.code_no == 'ai_fast_root') {
      it.children = _newList
    }
    return it
  })
  return newlist
}

const repalceAiRoots = (menuList, newList) => {
  const _list = menuList.map((it) => {
    if (it.code_no == 'ai_self_root') {
      it.children = newList
    }
    return it
  })
  return _list
}
