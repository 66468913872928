import React, { memo, useRef, useState } from 'react'
import UserName from '@/pages/comps/userName'
import VerifyCode from '@/pages/comps/verifyCode'
import UserPassword from '@/pages/comps/userPassword'
import LoginBtn from '@/pages/comps/loginBtn'
import ErrorTip from '@/pages/comps/errorTip'
import rlogoPng from '@/assets/img/login/rlogo.png'
import './style.css'
import useVerifyRegister from '@/hooks/verify/useVerifyRegister'
import useRouter from '@/hooks/router/useRouter'
import { register, getInviteCode } from '@/apis/login/login'
import { registerBindUser, bindLoginApi } from '@/apis/wx-bind/index'
import Cookies from 'js-cookie'

//eslint-disable-next-line max-lines-per-function
const Register = () => {
  const {
    phone,
    code,
    isChecked,
    errTip,
    isBtnActive,
    onChangePwd,
    onChangePwd2,
    verifyInputOk,
    verifyBindLogin,
    onChangePhone,
    getMsgCodeTip,
    getRegMsgCode,
    onChangeCode,
    setReadState,
    pwd,
    pwd2,
    invitCode,
    onChangeInviteCode,
  } = useVerifyRegister()
  const { history, query, historyPush } = useRouter()
  const [hasAccount, setHasAccount] = useState(false)

  const isBindWx = location.hash.includes('wx-bind')

  const isWxBindHasAccount = hasAccount && isBindWx

  const openIdTest = 'o-fid6Uc7iX1G30gRcxUAb-swvg4'
  const openId = query.openId

  const toBindWx = async () => {
    if (isWxBindHasAccount) {
      bindAndLogin()
    } else {
      registerAndBind()
    }
  }

  const registerAndBind = async () => {
    if (!verifyInputOk()) {
      return
    }

    const res = await registerBindUser({
      phone,
      code,
      pwd,
      pwd2,
      invitCode,
      openId: openId,
    })
    const token = res.data.token
    Cookies.set('REDSEA-TOKEN', token, {
      expires: 30,
    })
    goIndex()
  }

  const bindAndLogin = async () => {
    if (!verifyBindLogin()) {
      return
    }

    const res = await bindLoginApi({
      phone,
      pwd,
      openId: openId,
    })
    const token = res.data.token
    Cookies.set('REDSEA-TOKEN', token, {
      expires: 30,
    })
    goIndex()
  }

  const toRegister = async () => {
    if (isBindWx) {
      toBindWx()
      return
    }
    if (!verifyInputOk()) {
      return
    }
    await register({
      phone,
      code,
      pwd,
      pwd2,
      invitCode,
    })
    goLogin()
  }

  const goLogin = () => {
    historyPush(`/login`, {})
  }

  const goIndex = () => {
    historyPush(`/`, {})
  }

  /**
   * 微信绑定两种模式，已有账号直接绑定并登录
   * 未有账号，注册，绑定，并登录
   * 默认为“没有账号”
   */
  const toggleToBindLogin = () => {
    setHasAccount(!hasAccount)
  }

  const renderFooter = () => {
    if (isBindWx) {
      if (hasAccount) {
        return (
          <div className="cp-to-login">
            <span>没有账号？</span>
            <span className="do-login" onClick={toggleToBindLogin}>
              注册并绑定
            </span>
          </div>
        )
      }
      return (
        <div className="cp-to-login">
          <span>已有账号？</span>
          <span className="do-login" onClick={toggleToBindLogin}>
            绑定并登录
          </span>
        </div>
      )
    }
    return (
      <div className="cp-to-login">
        <span>已有账号？</span>
        <span className="do-login" onClick={goLogin}>
          直接登录
        </span>
      </div>
    )
  }

  const getPageTitle = () => {
    if (isBindWx) {
      if (hasAccount) {
        return '绑定并登录'
      }
      return '注册并绑定'
    }
    return '注册账号'
  }

  return (
    <div className="chatgpt-login chatgpt-register">
      <div className="chatgpt-login-top">
        <div className="chatgpt-title-nav">
          <img src={rlogoPng} alt="" />
          <span>红海云AI平台</span>
        </div>
      </div>
      <div className="chatgpt-login-main">
        <div className="chatgpt-login-welcome">欢迎来到红海云AI平台</div>
        <div className="chatgpt-login-con">
          <div className={'register-top'}>{getPageTitle()}</div>
          {isWxBindHasAccount ? null : (
            <div className={'mt-20'}>
              <UserName placeholder="邀请码" onChange={onChangeInviteCode} maxLength={11} />
            </div>
          )}

          <div className={'mt-20'}>
            <UserName placeholder="手机号码" onChange={onChangePhone} maxLength={11} />
          </div>
          <div className={'mt-20'}>
            <UserPassword placeholder="输入密码" onChange={onChangePwd} maxLength={12} />
          </div>
          {isWxBindHasAccount ? null : (
            <div className={'mt-20'}>
              <UserPassword placeholder="再次输入密码" onChange={onChangePwd2} maxLength={12} />
            </div>
          )}
          {isWxBindHasAccount ? null : (
            <div className={'mt-20'}>
              <VerifyCode
                placeholder="验证码"
                phone={phone}
                callbackTip={getMsgCodeTip}
                getMsgCode={getRegMsgCode}
                onChange={onChangeCode}
                maxLength={6}
              />
            </div>
          )}

          <div className="mt-20">
            <LoginBtn
              onClick={toRegister}
              title={getPageTitle()}
              isBtnActive={isBtnActive}
            ></LoginBtn>
          </div>
          <div className="h-30">{errTip ? <ErrorTip errTip={errTip} /> : ''}</div>
          {renderFooter()}
        </div>
      </div>
    </div>
  )
}
export default Register
