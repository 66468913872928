import React, { memo, useRef, useState } from 'react'
import UserName from '@/pages/comps/userName'
import VerifyCode from '@/pages/comps/verifyCode'
import UserPassword from '@/pages/comps/userPassword'
import LoginBtn from '@/pages/comps/loginBtn'
import ErrorTip from '@/pages/comps/errorTip'
import rlogoPng from '@/assets/img/login/rlogo.png'
import './style.css'
import useVerifyRegister from '@/hooks/verify/useVerifyRegister'
import useRouter from '@/hooks/router/useRouter'
import { wxUniBindLogin, ssoRedirect } from '@/apis/wx-bind/index'

//eslint-disable-next-line max-lines-per-function
const WxUniBind = () => {
  const {
    code,
    phone,
    errTip,
    isBtnActive,
    verifyBindLogin,
    onChangePhone,
    getMsgCodeTip,
    getRegMsgCode,
    onChangeCode,
    verifyUniBindLogin,
    getWxBindMsgCode,
  } = useVerifyRegister()
  const { history, query, historyPush } = useRouter()

  const openId = query.openId

  const bindAndLogin = async () => {
    if (!verifyUniBindLogin()) {
      return
    }

    const res = await wxUniBindLogin({
      username: phone,
      smsCode: code,
      openId: openId,
    })
    const token = res.data.token

    doRedirect(token)
  }

  const doRedirect = async (token) => {
    const href = `https://ai.hr-soft.cn/api/system/sso/redirect?state=${query.state}&token=${token}`
    location.href = href
  }

  /**
   * 微信绑定两种模式，已有账号直接绑定并登录
   * 未有账号，注册，绑定，并登录
   * 默认为“没有账号”
   */

  return (
    <div className="chatgpt-login wx-uni-bind">
      <div className="chatgpt-login-top">
        <div className="chatgpt-title-nav">
          <img src={rlogoPng} alt="" />
          <span>红海云AI平台</span>
        </div>
      </div>
      <div className="chatgpt-login-main">
        <div className="chatgpt-login-welcome">欢迎来到红海云AI平台</div>
        <div className="chatgpt-login-con">
          <div className={'register-top'}>绑定手机号</div>

          <div className={'mt-20'}>
            <UserName placeholder="手机号码" onChange={onChangePhone} maxLength={11} />
          </div>
          <div className={'mt-20'}>
            <VerifyCode
              placeholder="验证码"
              phone={phone}
              callbackTip={getMsgCodeTip}
              getMsgCode={getWxBindMsgCode}
              onChange={onChangeCode}
              maxLength={6}
            />
          </div>

          <div className="mt-20">
            <LoginBtn onClick={bindAndLogin} title={'绑定'} isBtnActive={isBtnActive}></LoginBtn>
          </div>
          <div className="h-30">{errTip ? <ErrorTip errTip={errTip} /> : ''}</div>
        </div>
      </div>
    </div>
  )
}
export default WxUniBind
