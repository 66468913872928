import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { toast } from '@chatui/core'
import { getOrderAuthorization } from './orderSign'
import {
  transToKeyValue,
  setAuthentication,
  sortObjKey,
  setRedApiAuthentication,
  addBaseUrl,
} from './utils'
import Cookies from 'js-cookie'
const isPublicOut = process.env.REACT_APP_FOR_OUT == '1'
const isPublicHuman = process.env.REACT_APP_FOR_HUMAN == '1'

const getAuthentication = (config, timestamp) => {
  let auth = ''

  if (config.hasPassAuth) {
    auth = config.hasPassAuth
  } else if (config.needPassAuthorization) {
    // workorder
    auth = getOrderAuthorization(config)
  } else {
    // redapi,auth
    if (config.isRedAuthApi) {
      auth = setRedApiAuthentication(config, timestamp)
    } else {
      auth = setAuthentication(config)
    }
  }
  return auth
}

const axiosInstance = axios.create({
  baseURL: '',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json;',
  },
})

axiosInstance.interceptors.request.use(
  async (config: any) => {
    // toast.loading("loading...");
    const timestamp = new Date().getTime() + ''

    config.headers['timestamp'] = timestamp
    const redseatoken = Cookies.get('REDSEA-TOKEN')

    if (isPublicHuman) {
      config.headers['REDSEA-TOKEN'] = redseatoken
    } else if (isPublicOut) {
      // config.headers['REDSEA-TOKEN'] = redseatoken
    }

    if (config.dataType == 'urlencoded') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    } else if (config.dataType == 'formData') {
      config.headers['Content-Type'] = 'multipart/form-data'
    }

    if (!config.noAuth) {
      config.headers['authentication'] = getAuthentication(config, timestamp)
    }
    config.url = addBaseUrl(config.url)

    return config
  },
  (error) => Promise.reject(error)
)

const jumpToLoginTimeStore = {
  last: 0,
}

axiosInstance.interceptors.response.use(
  //eslint-disable-next-line max-lines-per-function
  (response) => {
    const { config, request, headers } = response
    const res = response.data
    if (request?.aegisUrl?.includes('workorder/PtFjk.mob?method=upload')) {
      if (res?.id) {
        return res
      }
    }
    if (request?.responseURL?.includes('workorder/PtFjk.mob?method=upload')) {
      if (res?.id) {
        return res
      }
    }
    if (request?.responseURL?.includes('PtUsers.mc?method=getCurUserInfo')) {
      return res
    }
    if (request?.responseURL?.includes('uploadfile/normalfolder')) {
      return res
    }
    if (request?.responseURL?.includes('fastdev/third/ptfjk/viewByPk')) {
      return response
    }
    if (res && (res.state == '1' || res.code == 200 || res.code == 'ok')) {
      return res
    }

    //@ts-ignore
    if (!config.hideErr) {
      const err = res.meg || res.tipMsg || res.message || res.result
      let err_url = err
      if (err_url && err_url.includes('接口服务异常')) {
        err_url = `${err},接口地址:${request.responseURL}`
      }

      const errStr = JSON.stringify(res)
      if (
        errStr.includes(`<!DOCTYPE html>`) &&
        request?.responseURL?.includes('RedseaPlatform/index')
      ) {
        //登录过期
        const now = new Date().getTime()
        if (now - jumpToLoginTimeStore.last > 2000) {
          const loginUrl =
            'https://oa.hr-soft.cn/RedseaPlatform/jsp/outwork/workbench/login_outwork.jsp'
          const isLocal = location.href.includes('localhost')
          if (!isLocal) {
            window.open(loginUrl)
          }
          jumpToLoginTimeStore.last = new Date().getTime()
        }
      } else {
        const resMsg = res.msg || res.message || res.meg
        if (res && res.code && resMsg && res.code != 200) {
          if (res.code == 332 || res.code == 333) {
            //token为空
            window.location.href = '#/login'
          } else {
            resMsg && toast.fail(resMsg)
          }
        } else {
          // fastdev/chatgpt/titlelist
          if (request?.responseURL.includes('fastdev/chatgpt/titlelist')) {
            // console.log('')
          } else {
            toast.fail(err_url || errStr)
          }
        }
      }
    }

    return Promise.reject(res)
  },
  //eslint-disable-next-line max-lines-per-function
  (error) => {
    if (error && error.response) {
      const {
        status,
        config: { url, hideErr },
      } = error.response

      switch (+status) {
        case 401:
          toast.fail(`401,鉴权认证失败,接口地址${url}`)
          break
        case 403:
          toast.fail(`403,服务器禁止访问,接口地址${url}`)
          break
        case 404:
          toast.fail(`404,找不到服务器接口,接口地址${url}`)
          break
        case 500:
          toast.fail(`500,服务器开小差了,接口地址${url}`)
          break
        case 502:
          if (!hideErr) {
            toast.fail(`502,服务器开小差了,接口地址${url}`)
          }

          break
        case 503:
          toast.fail(`503,服务器开小差了,接口地址${url}`)
          break
        default:
          break
      }
    }
    return Promise.reject(error)
  }
)

const excludeSomePrams = (data) => {
  if (!data) {
    return {}
  }
  const _data = {}
  Object.keys(data)?.forEach((key) => {
    if (key != 'authKeyNeedDecode' && key != 'passAuth') {
      _data[key] = data[key]
    }
  })
  return _data
}

const fetch = {
  get(path, data, config = {}) {
    const _data = excludeSomePrams(data)

    const res = axiosInstance.get(path, {
      params: _data,
      ...config,
      auth: data,
    })
    return res
  },

  post: (path, data, config: any = { dataType: 'json' }) => {
    let _data = data
    if (config.dataType != 'json' && config.dataType != 'formData') {
      _data = transToKeyValue(data, path)
    }
    return axiosInstance.post(path, _data, {
      ...config,
      auth: data,
    })
  },

  postJson: (path: string, data: any, config = {} as any) => {
    const _data = data
    config = {
      ...config,
      dataType: 'json',
    }
    return axiosInstance.post(path, _data, {
      ...config,
      auth: data,
    })
    // return res as any;
  },

  uploadFileFastDev(file) {
    const formData = new FormData()
    formData.append('fj_file', file)

    const res = axiosInstance.post('/fastdev/tech/ptfjk/upload', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    return res
  },
}

export default fetch
