import http from '../../http/fetch'

export const getQRCode = async () => {
  const url = '/api/wechat/login'
  const params = {}
  const res = await http.get(url, params)
  return res as any
}

export const getCodeStatus = async () => {
  const url = '/api/wechat/status'
  const params = {}
  const res = await http.get(url, params)
  return res as any
}

export const getRobotLog = async () => {
  const url = '/api/wechat/log'
  const params = {}
  const res = await http.get(url, params)
  return res as any
}

export const getCurrent = async () => {
  const url = '/api/wechat/current'
  const params = {}
  const res = await http.get(url, params)
  return res as any
}

export const logout = async () => {
  const url = '/api/wechat/logout'
  const params = {}
  const res = await http.get(url, params)
  return res as any
}
