import { log } from 'console'

//MilkDown
const Uts = {
  callback: () => {},
}

export const createVueTempl = (config: any) => {
  const vueWrapper = document.querySelector(config.vueWrapper) as any
  vueWrapper.innerHTML = `<rs-milkdown v-model="markdown" v-bind="options" ref="milkdown" @change="getMarkdown" ></rs-milkdown>`
}

export const createScript = (charset = 'utf-8') => {
  const script = document.createElement('script')
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('charset', charset)

  return script
}

const getMarkdown = (val) => {
  //@ts-ignore
  Uts.callback && Uts.callback(val)
}

/*eslint-disable*/
function escapeString(str) {
  return str.replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t').replace(/\"/g, '\\\"').replace(/\'/g, "\\\'")
}


const allToolBars=[
  "Heading", //字体
  "undo", //回退
  "Redo", //前进
  "bold", //加粗
  "italic", //斜体
  "strikeThrough", //中划线
  "bulletList", // 无序排列
  "orderedList", // 有序排序
  "taskList", // 可选排序
  "liftList", // 左对齐
  "sinkList", //右对齐
  "image", //上传图片
  "table", // 表格
  "code", //代码
  "quote", // 引入
  "divider" //分割线
]

const simpleToolBars=[
  "image", //上传图片
]

const getOptions=(config:any)=>{
  const toolBars = config.isSimple ? simpleToolBars:allToolBars
  const opt:any= {
    readOnly: false, //只读模式，默认是false
    enableMenu: true, //是否显示工具菜单,默认是true，
    enableTooltip: true, //添加选择工具条,默认是false
    enableSlash: true, //添加斜线指令,默认是false
    enablePrism: true, //添加 [prism]用于支持代码块高亮,默认是false
    enableEmoji: true, //添加表情符号支持,默认是false
    enableMermaid: false, //是否支持图表
    themeStyle: config.theme ,//light-亮色主题；dark-暗色主题，默认是light
    uploadConfig: "remote", //auto-默认，文件大小来判断使用base64位还是远程;base64: 所有图片使用base64位显示;remote: 所有图片上传远程
    imgLimit: 10 ,//可配置使用base64位的图片大小限制，当设置upload-config=“auto”时生效,单位kb;默认是10kb.
     //toolBars工具栏配置项，不传或者设置为空数组，则工具默认显示
     toolBars:toolBars,
  }
  if(config.action){
    opt.action=config.action
  }
  if(config.platformMode){
    opt.platformMode=config.platformMode
  }
  if (config.uploadImgUrlKey) {
    opt.uploadImgUrlKey = config.uploadImgUrlKey
  }
  return opt;
}

export const initVueScript = (config: any) => {
  // console.log(config)
  window.getMarkdown = getMarkdown
  const script = createScript()

  const options=getOptions(config)
  window.milkdownOptions=options;
  
  script.innerHTML = `window.milkDownVue = new Vue({
    el: "${config.vueWrapper}",
    components: { rsMilkdown: redsea.componentLoader("Business/Milkdown/index") },
    data() {
      return {
        markdown: "${escapeString(config.value || '')}", // Markdown 语法的字符串
        options:window.milkdownOptions
      };
    },
    methods: {
      // 事件获取编辑器内容
      getMarkdown: function(val) {
        window.getMarkdown(val)
      },
      setValue: function(val) {
        this.$refs.milkdown.setValue(val);
      }
    }
  })`
  document.body.appendChild(script)
}

export const redDataSelectVueRun = (config: any) => {
  Uts.callback = config?.callback
  createVueTempl(config)
  setTimeout(() => {
    initVueScript(config)
  }, 100)
}
