import React, { memo, useEffect, useRef, useState } from 'react'
import { Dropdown } from 'antd'
import ChatUI, { Avatar } from '@chatui/core'
import { useDispatch, useSelector } from 'react-redux'
import useRouter from '@/hooks/router/useRouter'
import rlogoPng from '@/assets/img/login/rlogo.png'
import { getUserByApi } from '@/apis/user/index'
import './style.css'
//@ts-ignore
import redgptPng from '@/assets/img/list/ai.png'
import redgptsPng from '@/assets/img/list/redgpts.png'
import { EllipsisOutlined, SearchOutlined, DownOutlined } from '@ant-design/icons'
import http from '@/http/fetch'
import { setUserInfo } from '@/store/actions/user'
import Cookies from 'js-cookie'

//eslint-disable-next-line max-lines-per-function
const List = (props: any) => {
  const { userInfo } = useSelector((state: any) => state.user)
  const dispatch = useDispatch()
  const { history, query, historyPush } = useRouter()
  const appLists = [
    {
      id: 'RedGPT',
      name: 'RedGPT',
      icon: redgptPng,
    },
    {
      id: 'RedGPTs',
      name: 'RedGPTs',
      icon: redgptsPng,
    },
  ]

  const onClick = (item) => {
    const { id } = item
    if (id == 'RedGPT') {
      location.href = '/#/redgpt'
    } else if (id == 'RedGPTs') {
      location.href = '/redgpts/index.html'
    }
  }

  const avatarItems = [
    {
      key: 'setting',
      label: '个人设置',
    },
    {
      key: 'logout',
      label: '退出登录',
    },
  ]

  const doLogOut = async () => {
    const url = `/api/system/logout`
    const params = {}
    const res = await http.post(url, params)
    Cookies.set('REDSEA-TOKEN', '')
    historyPush('/login')
  }

  const doPersonSetting = () => {
    historyPush(`/user/setting?from=/app-list`)
  }

  const onAvatarDropdownClick = ({ key }) => {
    switch (key) {
      case 'logout':
        doLogOut()
        break
      case 'setting':
        doPersonSetting()
        break
    }
  }

  const getUserByForPublicOut = async () => {
    const res = await getUserByApi()
    dispatch(setUserInfo(res.data))
  }

  useEffect(() => {
    getUserByForPublicOut()
  }, [])

  const avatarImg = userInfo?.user?.avatar

  return (
    <div className="chatgpt-uts-list">
      <div className="home-header">
        <img src={rlogoPng} alt="" />
        <span>红海云AI平台</span>
        <div className="list-avatar-box">
          <Dropdown
            menu={{
              items: avatarItems,
              onClick: onAvatarDropdownClick,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Avatar src={avatarImg} size="sm"></Avatar>
              <DownOutlined />
            </a>
          </Dropdown>
        </div>
      </div>
      <div className="uts-list-items">
        {appLists?.map((item, index) => {
          return (
            <div className="list-item" key={index} onClick={() => onClick(item)}>
              <div className="item-center">
                <img src={item.icon} alt="" />
                <span>{item.name}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default List
