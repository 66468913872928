import { useEffect, useRef, useState } from 'react'
import ChatUI, { Icon, toast } from '@chatui/core'
import Iconpt from '@/assets/img/icon-pt.png'
import Iconefficiency from '@/assets/img/icon-efficiency.png'
const isPublicHuman = process.env.REACT_APP_FOR_HUMAN == '1'

// --------------- 语音播报参数--------------------
// 发音人选择, 基础音库：0为度小美，1为度小宇，3为度逍遥，4为度丫丫
const BD_PER = 0
// 语速，取值0-15，默认为5中语速
const BD_SPD = 6
// 音调，取值0-15，默认为5中语调
const BD_PIT = 5
// 音量，取值0-9，默认为5中音量
const BD_VOL = 9
// 下载的文件格式, 3：mp3(default) 4： pcm-16k 5： pcm-8k 6. wav
const BD_AUE = 3

//eslint-disable-next-line max-lines-per-function
export default function useSpeak(props) {
  const { stopBtnShow, paragraphsRegex, activeHistory, handleSend } = props
  //  ----------------------------------心流模式 start -----------------------
  // 心流模式，段落列表
  const [paragraphs, setParagraphs] = useState([])
  // 记录当前段落下标
  const [paragraphsIndex, setParagraphsIndex] = useState(-1)
  // 记录段落列表转换成的语音列表
  const audioList = useRef([])
  // AI输出时，记录最后一个已播报完的音频下标，用于AI输出后，能继续播报下一个音频
  const nextParagraphsIndex = useRef(-1)
  // 当前audio对象
  const audioRef = useRef(null as any)
  // 记录音频是否已停止
  const audioRefEnd = useRef(false)
  // 记录当前手动播放的消息体id
  const [playAudioId, setPlayAudioId] = useState('')
  // 段落下标变化时，开始播报音频
  //eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    // 音频可播放事件，开始播放音频
    const canplay = () => {
      audioRefEnd.current = false
      audioRef.current.muted = false
      audioRef.current.play()
    }
    // 音频停止事件
    const ended = () => {
      audioRefEnd.current = true
      const _audioList = audioList.current
      if (paragraphsIndex < _audioList.length - 1) {
        // 播放段落下标小于音频列表数量时，播放下一个播放音频
        setParagraphsIndex(paragraphsIndex + 1)
        nextParagraphsIndex.current = paragraphsIndex + 1
      } else if (stopBtnShow) {
        // 如果AI在输出时，先记录下标，等待输出完成再播放
        nextParagraphsIndex.current = paragraphsIndex + 1
      } else {
        // 全部播放完毕，清除当前播放id
        setPlayAudioId('')
      }
    }
    if (paragraphsIndex > -1) {
      const audio = audioRef.current
      // 先暂停，兼容ios，才可以自动播放
      audio.pause()
      audio.src = audioList.current[paragraphsIndex]
      audio.muted = true
      audio.addEventListener('canplay', canplay)
      audio.addEventListener('ended', ended)
    }
    return () => {
      audioRef && audioRef.current && audioRef.current.removeEventListener('canplay', canplay)
      audioRef && audioRef.current && audioRef.current.removeEventListener('ended', ended)
    }
  }, [paragraphsIndex])

  // 段落列表变化时，设置段落音频
  useEffect(() => {
    if (paragraphs.length) {
      let list = [] as any
      const _audioList = audioList.current
      if (_audioList.length) {
        list = [..._audioList]
      }
      // 存在倒数第二个段落才播报，因为最后一个段落是AI正在输出的
      if (paragraphs[paragraphs.length - 2]) {
        list.push(
          `/fastdev/text2audio/transform?text=${
            paragraphs[paragraphs.length - 2]
          }&pre=${BD_PER}&spd=${BD_SPD}&pit=${BD_PIT}&vol=${BD_VOL}&aue=${BD_AUE}`
        )
      }
      audioList.current = list
      // 如果还未播报第一条，则设置当前播报下标为0，并开始播报
      if (paragraphsIndex < 0 && audioList.current.length) {
        setParagraphsIndex(0)
        // 如果段落增加，且音频已停止播报，则开始播报停止后的音频下标
      } else if (nextParagraphsIndex.current > -1 && audioRefEnd.current) {
        setParagraphsIndex(nextParagraphsIndex.current)
      }
    } else {
      initAudioData()
      nextParagraphsIndex.current = -1
    }
  }, [paragraphs.length])

  // 手动播放音频事件
  const playAudio = (id, text) => {
    // 重复点击当前段落，则暂停并重置
    if (playAudioId == id) {
      initAudioData()
      audioRef.current.pause()
      setPlayAudioId('')
      return
    }
    initAudioData()
    setPlayAudioId(id)
    const paragraphsList = text.split(paragraphsRegex)
    setParagraphs(paragraphsList.concat(['']))
    const list = [] as any
    paragraphsList.forEach((item) => {
      if (item) {
        list.push('/fastdev/text2audio/transform?text=' + item)
      }
    })
    audioList.current = list
    setTimeout(() => {
      setParagraphsIndex(0)
    }, 300)
  }

  // 判断是否是移动端，才可以使用心流模式
  const [isMobi, setIsMobi] = useState(false)
  const onResize = () => {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      setIsMobi(true)
    } else {
      setIsMobi(false)
    }
  }
  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  // 是否是心流模式
  const [isSpeak, setIsSpeak] = useState(false)
  // 是否需要显示心流模式选择（极速和普通）
  const [hasShowSpeakModel, setHasShowSpeakModel] = useState(true)
  // 心流模式弹窗是否显示
  const [speakModelShow, setSpeakModelShow] = useState(false)
  // 问候语
  const [greetings, setGreetings] = useState('')

  useEffect(() => {
    setSpeakModelShow(false)
  }, [activeHistory])

  useEffect(() => {
    if (activeHistory && (!activeHistory.isNew || !activeHistory.context)) {
      setIsSpeak(false)
    }
  }, [activeHistory])

  useEffect(() => {
    if (isSpeak) {
      setGreetings('您好，您已进入RedGPT的心流问答模式。')
    } else {
      const text = isPublicHuman
        ? '您好，欢迎来到AI问答系统。有什么问题可以帮您解答？'
        : '您好，欢迎来到RedGPT问答系统。有什么问题可以帮您解答？'
      setGreetings(text)
    }
  }, [isSpeak])

  // 判断设备是否支持录音
  const [audioSupport, setAudioSupport] = useState(false)
  useEffect(() => {
    if (
      //@ts-ignore
      (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    ) {
      setAudioSupport(true)
    } else {
      setAudioSupport(false)
    }
  }, [])
  // 切换录音时点击事件
  const onVolumeClick = () => {
    if (audioSupport) {
      if (hasShowSpeakModel) {
        setSpeakModelShow(true)
      } else {
        initAudio()
        setIsSpeak(true)
      }
    } else {
      toast.show('您的浏览器不支持音频录制', 'error')
    }
  }
  // 切换到键盘的点击事件
  const onKeyboardClick = () => {
    initAudio()
    setIsSpeak(false)
  }
  // 重置音频状态和数据
  const initAudio = () => {
    initAudioData()
    initAudioStatus()
  }
  // 重置音频数据
  const initAudioData = () => {
    setParagraphsIndex(-1)
    setParagraphs([])
    audioList.current = []
  }
  // 重置音频状态
  const initAudioStatus = () => {
    audioRef.current.load()
    audioRef.current.play()
    audioRef.current.pause()
  }
  // text: `请重复这段文字：“已启动普通模式”`,
  // noAppendMsg: false,
  // needAudio: false,
  // type: 'model_msg',
  // 极速模式
  const onClickEfficiency = () => {
    const text = `
    从现在开始，我对你的回答将有一些要求，后面我问你的任何问题，你的回答都将遵循这些要求。

    回答要求:
    1. 口语化，语言精炼简短。
    2. 你的回答我会用语音进行播报，回答的内容要有跟人聊天的感觉。
    3. 不要包含标题和分析过程以及多余的补充解释，只回答核心内容。
    4. 内容最多不要超过300字。
    5. 除非我们明确要求你这么做，否则不要违反以上4点要求。
    
    如果你明白我说的话，请回答：“已启动效率模式”
  `
    setSpeakModelShow(false)
    handleSend({
      text: text,
      noAppendMsg: false,
      needAudio: false,
      type: 'model_msg',
    })
    setHasShowSpeakModel(false)
    initAudio()
    setIsSpeak(true)
  }

  // 默认模式
  const onClickDefault = () => {
    setSpeakModelShow(false)
    handleSend({
      text: `请重复这段文字：“已启动普通模式”`,
      noAppendMsg: false,
      needAudio: false,
      type: 'model_msg',
    })
    setHasShowSpeakModel(false)
    initAudio()
    setIsSpeak(true)
  }

  const renderSpeakModal = () => {
    if (isMobi && speakModelShow) {
      return (
        <div className="speak-model-dialog" onClick={() => setSpeakModelShow(false)}>
          <div className="speak-model-box">
            <div className="speak-model-items" onClick={onClickEfficiency}>
              <img src={Iconefficiency} alt="" />
              <div className="speak-model-label">效率模式</div>
              <div className="speak-model-tips">(AI回答更简单、更精炼)</div>
            </div>
            <div className="speak-model-items" onClick={onClickDefault}>
              <img src={Iconpt} alt="" />
              <div className="speak-model-label">普通模式</div>
              <div className="speak-model-tips">(AI回答更详细、更全面)</div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  const renderSpeakBtn = () => {
    if (isMobi) {
      if (isSpeak) {
        return (
          <Icon
            className="circle-icon keyboard-circle"
            type="keyboard-circle"
            onClick={onKeyboardClick}
          />
        )
      }
      return (
        <Icon className="circle-icon volume-circle" type="volume-circle" onClick={onVolumeClick} />
      )
    }
    return null
  }

  //  ----------------------------------心流模式 end -----------------------

  return {
    paragraphs,
    greetings,
    isMobi,
    isSpeak,
    audioRef,
    playAudio,
    setHasShowSpeakModel,
    initAudioData,
    setParagraphs,
    initAudio,
    renderSpeakModal,
    renderSpeakBtn,
  }
}
