import { AppstoreOutlined } from '@ant-design/icons'

import './BtnList.css'

function BtnListComp() {
  const toTemplate = () => {
    window.open('#/template')
  }
  return (
    <div className="btn-list-box">
      <div className="btn-items" onClick={toTemplate}>
        <AppstoreOutlined />
        <div className="btn-items-label">模板管理</div>
      </div>
    </div>
  )
}

export default BtnListComp
