import { HashRouter, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import Chatgpt from '@/pages/chatgpt/chatgpt'
import Template from '@/pages/template/template'
import ChatFlowTemplate from '@/pages/chatflow/template'
import RecordButton from '@/pages/test/test'
import Login from '@/pages/login/login'
import List from '@/pages/list/index'
import UserSetting from '@/pages/user/setting'
import Register from '@/pages/register/index'
import WxUniBind from '@/pages/wx-uni-bind/index'
import Multiwindow from '@/pages/multiwindow'
import WxLogin from '@/pages/robot/index'
import RobotLog from '@/pages/robotLog/index'
import Test from '@/pages/test/test'
import http from '@/http/fetch'
import { getUserByApi } from '@/apis/user/index'
import { setUserInfo } from '@/store/actions/user'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN'
const isPublicOut = process.env.REACT_APP_FOR_OUT == '1'

//eslint-disable-next-line max-lines-per-function
function App() {
  useEffect(() => {
    setFavicon()
  }, [])
  const setFavicon = () => {
    const ele = document.getElementById('favicon')

    if (ele && !isPublicOut) {
      const href = '/fastdev/appweb/RedGPT/ai.png'
      ele.setAttribute('href', href)
    }
  }

  return (
    <ConfigProvider locale={zhCN}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Chatgpt />} />
          <Route path="/redgpt" element={<Chatgpt />} />
          <Route path="/template" element={<Template />} />
          <Route path="/chatflow/template" element={<ChatFlowTemplate />} />
          <Route path="/record" element={<RecordButton />} />
          <Route path="/login" element={<Login />} />
          <Route path="/app-list" element={<List />} />
          <Route path="/user/setting" element={<UserSetting />} />
          <Route path="/register" element={<Register />} />
          <Route path="/wx-bind" element={<Register />} />
          <Route path="/wx-uni-bind" element={<WxUniBind />} />
          <Route path="/multiwindow" element={<Multiwindow />} />
          {/* 机器人扫码登录功能，不是用户的微信二维码扫码登录 */}
          <Route path="/wxlogin" element={<WxLogin />} />
          <Route path="/robotLog" element={<RobotLog />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </HashRouter>
    </ConfigProvider>
  )
}

export default App
