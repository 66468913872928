import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Input, Button, Spin } from 'antd'
import { MilkDown } from '../../../MilkDown'
import { toast } from '@chatui/core'
import http from '../../../../http/fetch'
import './TemplateModal.css'

//eslint-disable-next-line max-lines-per-function
function TemplateModal(props) {
  const [form] = Form.useForm()
  const {
    templateDialogVisit,
    onCloseDialog,
    activeContext,
    activeTemplate,
    dialogType,
    handleSend,
  } = props
  const { isThemeLight } = useSelector((state: any) => state.other)
  const [dialogVisit, setDialogVisit] = useState(false)
  const [mdIsInit, setMdIsInit] = useState(true)
  const mdIsInitTimer = useRef<any>(null)
  const onCloseModal = () => {
    setDialogVisit(false)
    onCloseDialog()
  }
  const saveTemplate = async () => {
    try {
      const values = await form.validateFields()
      const postData = values
      if (activeTemplate) {
        postData.id = activeTemplate.id
      }
      http.post('/fastdev/chatgpt/templateSaveOrUpdate', postData).then((res) => {
        toast.success('提交成功')
        onCloseModal()
      })
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }

  const sendTemplate = async () => {
    const values = await form.validateFields()
    handleSend(values.template)
    onCloseModal()
  }
  let ModalTitle = ''
  const initialValues: any = ''
  const ModalFooter = [
    <Button key="cancel" onClick={onCloseModal}>
      取消
    </Button>,
  ]
  if (dialogType == 'send') {
    ModalTitle = activeTemplate.title
    ModalFooter.push(
      <Button key="submit" type="primary" onClick={sendTemplate}>
        发送
      </Button>
    )
  } else {
    if (activeTemplate) {
      ModalTitle = '模板详情'
    } else {
      ModalTitle = '新增模板'
    }
    ModalFooter.push(
      <Button key="submit" type="primary" onClick={saveTemplate}>
        确定
      </Button>
    )
  }

  useEffect(() => {
    const context = activeTemplate?.template || activeContext || ''
    const title = activeTemplate?.title || ''
    form.setFieldsValue({
      template: context,
      title: title,
    })
    window.milkDownVue && window.milkDownVue.$refs.milkdown.setValue(context)
    setDialogVisit(templateDialogVisit)
    if (templateDialogVisit && mdIsInit) {
      mdIsInitTimer.current = setInterval(() => {
        if (document.querySelector('.milkdown .editor')) {
          setMdIsInit(false)
        }
      }, 500)
    }
  }, [templateDialogVisit])

  useEffect(() => {
    if (dialogVisit) {
      document.documentElement.style.fontSize = '10px'
    } else {
      document.documentElement.style.fontSize = '16px'
    }
  }, [dialogVisit])

  return (
    <Modal
      wrapClassName={isThemeLight ? 'light-modal-wrap' : 'dark-modal-wrap'}
      title={ModalTitle}
      width="1000px"
      open={dialogVisit}
      onCancel={onCloseModal}
      footer={ModalFooter}
    >
      <Spin spinning={mdIsInit}>
        <Form layout="vertical" form={form} initialValues={initialValues} autoComplete="off">
          {dialogType != 'send' && (
            <Form.Item
              label="标题"
              name="title"
              rules={[{ required: true, message: '请输入标题!' }]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          )}
          <Form.Item
            label="模板内容"
            name="template"
            rules={[{ required: true, message: '请输入模板内容!' }]}
          >
            <MilkDown theme={isThemeLight ? 'light' : 'dark'}></MilkDown>
            {/* <TextArea
            spellCheck="false"
            className="self-def-scroll-style"
            placeholder="请输入"
            autoSize={{ minRows: 12, maxRows: dialogType == 'send' ? 16 : 12 }}
          /> */}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default TemplateModal
