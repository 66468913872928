import React, { useEffect } from 'react'
import { redDataSelectVueRun } from './uts'

export const MilkDown = (props: any) => {
  const milkDownId = props.milkDownId || 'MilkDown'

  useEffect(() => {
    redDataSelectVueRun({
      ...props,
      vueWrapper: `#${milkDownId}`,
      callback: (res: any) => {
        props.onChange(res)
      },
    })
  }, [])

  return <div id={milkDownId}></div>
}
