import React, { useEffect, useState, useRef } from 'react'
import { echartKeyword } from '@/component/echarts/systemContext'
import Charts from '@/component/echarts/comps/charts'

//eslint-disable-next-line max-lines-per-function
const Echarts = (props: any) => {
  const { content, chartId } = props
  const [contentArr, setContentArr] = useState([])

  useEffect(() => {
    const list = content.split('```')
    const arr = [] as any
    list.forEach((text) => {
      // 如果是图表数据，等到数据完成后才更新
      if (text.trim().startsWith(echartKeyword)) {
        if (text.trim().endsWith('})')) {
          arr.push(text)
        }
      } else {
        text.trim() && arr.push(text)
      }
    })
    setContentArr(arr)
  }, [content])

  return (
    <div className="chat-echart-comp">
      {contentArr.map((content: string, index) => {
        if (content.trim().startsWith(echartKeyword)) {
          return <Charts chartContent={content} chartId={chartId + '' + index} key={index}></Charts>
        } else {
          return <div key={index}>{content}</div>
        }
      })}
    </div>
  )
}

export default Echarts
