import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import http from '@/http/fetch'
import useProxyList from '@/hooks/useProxyList'
import useTempl from '@/hooks/useTempl'
import useKeyEvent from '@/hooks/useKeyEvent'
import InputItem from '@/component/input/InputItem'
import NavBar from './comps/header/nav-bar/NavBar'
import ChatComp from './comps/chat/Chat'
import { Input } from 'antd'
import { setUserInfo } from '@/store/actions/user'

const { TextArea } = Input
import './index.css'

//eslint-disable-next-line max-lines-per-function
const Multiwindow = () => {
  const dispatch = useDispatch()
  // 版本信息
  const { version, sourceLibrary } = useSelector((state: any) => state.other)
  const isOpenAI = sourceLibrary.join(',') == 'OpenAI'
  // 设置问题
  const [question, setQuestion] = useState('')
  const [sendQuestion, setSendQuestion] = useState('')
  const [systemContext, setSystemContext] = useState('')

  // 是否显示停止生成按钮
  const [stopBtnShow, setStopBtnShow] = useState(false)
  //聊天会话方法抽离
  const [loading, setLoading] = useState(false)

  function getUserInfo() {
    http.post('/RedseaPlatform/PtUsers.mc?method=getCurUserInfo', {}).then((res) => {
      dispatch(setUserInfo(res))
    })
  }

  useEffect(() => {
    getUserInfo()
  }, [])

  const handleSend = (opt: any) => {
    const { text } = opt || {}
    const val = text || question
    const trimValue = val.trim()
    setSendQuestion(trimValue)
    setTimeout(() => {
      setSendQuestion('')
      setQuestion('')
    })
  }
  const systemContextChange = (e) => {
    const val = e.target.value
    setSystemContext(val)
  }

  //代理
  const { renderProxyList, proxy, setProxy, getActiveProxy } = useProxyList({
    stopBtnShow,
  })
  // //模板新增，删除
  const { inputRef, onInputKeyDown, showTemplateDialog, onInputChange, renderTemplComps } =
    useTempl({
      setQuestion,
      handleSend,
    })

  useKeyEvent({ question, handleSend, stopBtnShow })

  const multiwindowList = [1, 2, 3, 4]

  return (
    <div className="multiwindow-page">
      <NavBar></NavBar>
      <div className="multiwindow-box">
        <div className="main-chat-box">
          <TextArea
            placeholder="SYSTEM"
            autoSize
            value={systemContext}
            onChange={systemContextChange}
          />
          <ChatComp question={sendQuestion} systemContext={systemContext} />
          <div className={`ChatFooter ${!isOpenAI && 'sourceLibrary'}`}>
            <div className={`Composer`}>
              <div className={`Composer-right`}>
                {/* {renderProxyList()} */}
                <InputItem
                  onInputKeyDown={onInputKeyDown}
                  question={question}
                  inputRef={inputRef}
                  onInputChange={onInputChange}
                  loading={loading}
                  stopBtnShow={stopBtnShow}
                  handleSend={handleSend}
                />
              </div>
            </div>
          </div>
          {renderTemplComps()}
        </div>
        <div className="multiwindow-list">
          {multiwindowList.map((item) => {
            return <ChatComp key={item} question={sendQuestion} systemContext={systemContext} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Multiwindow
