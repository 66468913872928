import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChatUI, { Input, Button, useMessages, Icon, toast, Modal } from '@chatui/core'
import { DeleteOutlined, LinkOutlined, EllipsisOutlined } from '@ant-design/icons'

const useScrolltoBottom = () => {
  // 会话列表
  const { activeHistory, historyList } = useSelector((state: any) => state.histroy)
  // 自动滚到底部
  useEffect(() => {
    setTimeout(() => {
      const messagesRef = document.getElementsByClassName('PullToRefresh')
      if (messagesRef[0]) {
        messagesRef[0].scrollTop = messagesRef[0].scrollHeight
      }
    }, 100)
  }, [activeHistory])
}

export default useScrolltoBottom
