import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '@chatui/core'
import { notification, Button } from 'antd'

import './ExportToMd.css'

import http from '@/http/fetch'
import dayjs from 'dayjs'
import securityBase64 from '@/http/securityBase64'

//eslint-disable-next-line max-lines-per-function
function ExportToMdComp(props) {
  const { isThemeLight } = useSelector((state: any) => state.other)
  const { activeHistory } = useSelector((state: any) => state.histroy)
  const [aiLists, setAiLists] = useState([])
  const [aiTitle, setAiTitle] = useState('新增会话')
  const [exportConfirmOpen, setExportConfirmOpen] = useState(false)

  useEffect(() => {
    const list = activeHistory && activeHistory.context ? JSON.parse(activeHistory.context) : []
    setAiLists(list)
    setAiTitle(activeHistory ? activeHistory.title : '新增会话')
  }, [activeHistory])

  useEffect(() => {
    setExportConfirmOpen(props.showModal)
  }, [props.showModal])

  useEffect(() => {
    if (!exportConfirmOpen) {
      props.onHideModal()
    }
  }, [exportConfirmOpen])

  const _formatDate = function (date, fmt) {
    const o = {
      'M+': date.getMonth() + 1, //月份
      'd+': date.getDate(), //日
      'h+': date.getHours(), //小时
      'm+': date.getMinutes(), //分
      's+': date.getSeconds(), //秒
      'q+': Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    }
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (const k in o)
      if (new RegExp('(' + k + ')').test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        )
    return fmt
  }

  const getContent = () => {
    let str = ``
    aiLists.forEach((it: any) => {
      if (it.type == 'self_msg') {
        str += `<span style="color:#999">${it.content.text}</span>\n`
      } else {
        str += `>${it.content.text}\n`
      }
    })
    return str
  }

  const getOfficeMDContents = () => {
    // const
    const exportTime = _formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
    const title = `${aiTitle}-${exportTime}`
    const content = getContent()
    return {
      title: title,
      content: content,
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [])

  const [userInfo, setUserInfo] = useState(null)

  const getUserInfo = () => {
    http.post('/RedseaPlatform/PtUsers.mc?method=getCurUserInfo', {}).then((res) => {
      //@ts-ignore
      setUserInfo(res)
    })
  }
  const [api] = notification.useNotification()

  const exportEditClick = (res) => {
    api.destroy()
    const url = `https://workorder.hr-soft.cn/workorder/vwoffice/vwoffice_md_edit.html?officeId=${res?.result?.officeId}`
    window.open(url)
  }

  //eslint-disable-next-line max-lines-per-function
  const doExportToMd = async () => {
    setExportConfirmOpen(false)
    const url = `https://workorder.hr-soft.cn/workorder/hrProjectVwofficeMd/saveMD.mob`

    const { title, content } = getOfficeMDContents()

    //@ts-ignore
    const { userId, userName } = userInfo

    const nextYearDate = dayjs().add(1, 'year')

    const oriParams = [
      {
        mdTitle: title,
        mdContent: content,
        officeId: '',
        userName: userName || 'suName',
        userId: userId || 'su',
        parentId: '',
        isShare: '1',
        shareDate: nextYearDate.format('YYYY-MM-DD'),
      },
    ]

    const str = JSON.stringify(oriParams)

    const base64Str = securityBase64.encode(str)

    const _params = {
      param: base64Str,
    }
    const config = {
      needPassAuthorization: true,
      dataType: 'urlencoded',
      hideErr: true,
    }
    http
      .post(url, _params, config)
      .then((res) => {
        notification.success({
          message: '导出成功',
          description: (
            <Button type="link" size="small" onClick={() => exportEditClick(res)}>
              点击编辑
            </Button>
          ),
        })
      })
      .catch((err) => {
        notification.error({
          message: '导出失败',
          description: err.meg,
        })
      })
  }

  return (
    <div className="export-to-md-box">
      {
        <>
          <div className="btns-box-switch-theme" title="导出到MD">
            <div
              className="chatflow-switch-theme"
              onClick={() => {
                setExportConfirmOpen(true)
              }}
            >
              <i
                className={`redsea-icon-font ${
                  isThemeLight ? 'redsea-icon-export-light' : 'redsea-icon-export-dark'
                }`}
              ></i>
            </div>
          </div>
          <Modal
            active={exportConfirmOpen}
            title="是否将内容导出到markdown?"
            showClose={false}
            onClose={() => setExportConfirmOpen(false)}
            vertical={false}
            btnVariant="outline"
            actions={[
              {
                label: '确定',
                color: 'primary',
                onClick: doExportToMd,
              },
              {
                label: '取消',
                onClick: () => setExportConfirmOpen(false),
              },
            ]}
          ></Modal>
        </>
      }
    </div>
  )
}

export default ExportToMdComp
