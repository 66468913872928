import http from '../../http/fetch'

export const registerBindUser = async ({ phone, code, pwd, pwd2, invitCode, openId }) => {
  const url = '/api/system/bindUser'
  const params = {
    username: phone,
    password: pwd,
    passwordConfirm: pwd2,
    smsCode: code,
    invitationCode: invitCode,
    openId: openId,
  }

  const res = await http.postJson(url, params)
  return res as any
}

export const bindLoginApi = async ({ phone, pwd, openId }) => {
  const url = '/api/system/bindLogin'
  const params = {
    username: phone,
    password: pwd,
    openId: openId,
  }

  const res = await http.postJson(url, params)
  return res as any
}

export const getWxAccess = async () => {
  const url = '/api/system/access'
  const params = {}

  const res = await http.get(url, params)
  return res as any
}

export const wxUniBindLogin = async ({ username, smsCode, openId }) => {
  const url = '/api/system/sso/bindUser'
  const params = {
    username,
    smsCode,
    openId,
  }

  const res = await http.postJson(url, params)
  return res as any
}

export const ssoRedirect = async ({ state, token }) => {
  const url = '/api/system/sso/redirect'
  const params = {
    state,
    token,
  }

  const res = await http.get(url, params)
  return res as any
}
