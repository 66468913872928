import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMaxTokens } from '@/store/actions/other'
import { Input } from 'antd'

import './Maxtokens.css'

//eslint-disable-next-line max-lines-per-function
function MaxtokensComp() {
  const dispatch = useDispatch()
  const { maxTokens, versionAuth } = useSelector((state: any) => state.other)
  const { userInfo } = useSelector((state: any) => state.user)

  const hasAuth = userInfo && versionAuth.indexOf(userInfo.userId) !== -1

  const [maxtokensVal, setMaxtokensVal] = useState('')
  const inputChange = (e) => {
    const val = e.target.value
    setMaxtokensVal(val)
    dispatch(setMaxTokens(val))
    window.localStorage.setItem('maxTokens', val)
  }

  useEffect(() => {
    setMaxtokensVal(maxTokens)
  }, [maxTokens])

  useEffect(() => {
    const _maxTokens = window.localStorage.getItem('maxTokens')
    if (_maxTokens) {
      setMaxtokensVal(_maxTokens)
      dispatch(setMaxTokens(_maxTokens))
    }
  }, [])

  return (
    hasAuth && (
      <div className="maxtokens-box">
        <span className="title">maxTokens：</span>
        <Input size="small" placeholder="请输入" value={maxtokensVal} onChange={inputChange} />
      </div>
    )
  )
}

export default MaxtokensComp
