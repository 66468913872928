import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setVersion } from '@/store/actions/other'
import { AutoComplete } from 'antd'

import './Version.css'

const versionOptions = [
  { value: 'GLM3', label: 'GLM3' },
  { value: 'gpt-3.5-turbo-0125', label: 'gpt-3.5-turbo-0125' },
  { value: 'gpt-4-0125-preview', label: 'gpt-4-0125-preview' },
]

function VersionComp() {
  const dispatch = useDispatch()
  const { version, versionAuth, isThemeLight } = useSelector((state: any) => state.other)
  const { userInfo } = useSelector((state: any) => state.user)

  const isDev = window.location.hostname == 'localhost'

  const hasAuth = userInfo && versionAuth.indexOf(userInfo.userId) !== -1

  const [versionVal, setVersionVal] = useState(version)
  const selectChange = (val) => {
    setVersionVal(val)
    dispatch(setVersion(val))
  }

  useEffect(() => {
    setVersionVal(version)
  }, [version])

  return (
    hasAuth && (
      <div className="multiwindow-version-box">
        <span className="title">模型：</span>
        <AutoComplete
          popupClassName={isThemeLight ? '' : 'ant-select-dropdown-dark'}
          size="small"
          value={versionVal}
          onChange={selectChange}
          options={versionOptions}
        />
      </div>
    )
  )
}

export default VersionComp
