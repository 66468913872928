import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Spin } from 'antd'
import ChatComp from '@/component/chat/Chat'
import HistoryListComp from '@/component/history/HistoryList'
import BtnListComp from '@/component/tools/temp/BtnList'
import NavBar from '@/component/header/nav-bar/NavBar'
import useWxScan from './hooks/useWxScan'
import useWxBrowserLogin from './hooks/useWxBrowserLogin'

import './chatgpt.css'

//eslint-disable-next-line max-lines-per-function
function Chatgpt() {
  document.title = 'RedGPT'

  const { isThemeLight } = useSelector((state: any) => state.other)
  const { updateHistoryList } = useWxScan()
  const { showSpin } = useWxBrowserLogin()

  return (
    <div className={`app-box ${isThemeLight ? 'chatgpt-theme-light' : 'chatgpt-theme-dark'}`}>
      <div className="nav-box">
        <HistoryListComp />
        <BtnListComp />
      </div>
      <ChatComp updateHistoryList={updateHistoryList} />
      <NavBar />
      {showSpin && (
        <Spin tip="Loading">
          <div className="content" />
        </Spin>
      )}
    </div>
  )
}

export default Chatgpt
