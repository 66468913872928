import http from '../../http/fetch'

export const getUserInfo = async () => {
  const url = '/RedseaPlatform/PtUsers.mc?method=getCurUserInfo'
  const res = await http.post(url, {}, { hideLoading: true })

  return res
}

const storedUserInfo = {
  data: null,
}

export const getUserInfoFromRedTeams = async () => {
  if (storedUserInfo.data) {
    return storedUserInfo.data
  }
  const url = `http://127.0.0.1:5003/api/redteams/userinfo`
  const params = {}
  const res: any = await http.get(url, params, { hideLoading: true })
  if (res.code != 200) {
    return {}
  }

  const { userId, userName, encodeUserId, encodeUserName, pictureFile } = res.data
  const userinfo: any = {
    userId,
    userName,
    encodeUserId,
    encodeUserName,
    pictureFile,
  }
  storedUserInfo.data = userinfo

  return userinfo
}
