import React, { memo, useState } from 'react'
import { Input } from 'antd'
import './style.css'

const AccountName = (props) => {
  const { placeholder = '请输入', onChange, maxLength = 20, disabled, value } = props

  const change = (e) => {
    onChange?.(e)
  }
  return (
    <div className="cp-account-name-input">
      <Input
        placeholder={placeholder}
        onChange={change}
        maxLength={maxLength}
        disabled={disabled}
        value={value}
      />
    </div>
  )
}

export default AccountName
