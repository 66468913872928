import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import http from '@/http/fetch'
import axios from 'axios'
import PubSub from 'pubsub-js'

let axiosCancel: any = null
//eslint-disable-next-line max-lines-per-function
export default function useGlmSuggestion(props) {
  const { activeHistory, messages, handleSend } = props
  const [glmSuggetion, setGlmSuggetion] = useState([] as any)
  const [hasSetSuggestions, setHasSetSuggestions] = useState(false)

  useEffect(() => {
    const token = PubSub.subscribe('resetSuggstionSetting', () => {
      resetSuggstionSetting()
    })
    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  const resetSuggstionSetting = () => {
    setGlmSuggetion([])
    setHasSetSuggestions(false)
    axiosCancel && axiosCancel()
  }

  const isFirstRowMsg = (msg) => {
    const copyMsg = messages

    let isFirst = false
    copyMsg.forEach((it, idx) => {
      if (it._id == msg._id) {
        if (idx == 1) {
          isFirst = true
        }
      }
    })
    return isFirst
  }

  const getGlmSuggetionLists = async (trimValue) => {
    if (hasSetSuggestions) {
      return
    }
    const url = `/fastdev/chatgpt/improveAsk`
    const params = {
      ask: trimValue,
      count: 3,
    }
    const CancelToken = axios.CancelToken
    try {
      const res: any = await http.post(url, params, {
        dataType: 'urlencoded',
        noAuth: true,
        cancelToken: new CancelToken(function executor(c) {
          // 保存 cancel 函数
          axiosCancel = c
        }),
      })
      // console.log(res)
      if (res.code == 200) {
        const data = res.data.length > 0 ? res.data : []
        setGlmSuggetion(data)
        setHasSetSuggestions(true)
      }
    } catch (err) {
      // setGlmSuggetion(textArr)
    }
  }

  const onClickGLM3Item = (item) => {
    handleSend({
      text: item,
      noAppendMsg: false,
      needAudio: false,
    })
  }

  //eslint-disable-next-line max-lines-per-function
  const renderGLM3Text = (msg) => {
    const isFirst = isFirstRowMsg(msg)
    // const isFirst = true

    if (glmSuggetion.length == 0) {
      return null
    }
    if (isFirst) {
      return (
        <div className="first-suggestion-box suggest-fade-in">
          <div className="first-suggestion-title">
            <div className="title-icon">
              <svg
                //@ts-ignore
                t="1700706354335"
                className="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="1537"
                width="200"
                height="200"
              >
                <path
                  d="M682.666667 170.666667a170.666667 170.666667 0 0 1 170.666666 170.666666v341.333334a170.666667 170.666667 0 0 1-170.666666 170.666666H341.333333a170.666667 170.666667 0 0 1-170.666666-170.666666V341.333333a170.666667 170.666667 0 0 1 170.666666-170.666666h341.333334z m-137.045334 167.765333H482.133333L341.333333 704h59.392l34.304-94.208h157.184l34.304 94.208h59.904l-140.8-365.568z m-30.72 59.904l60.416 165.376h-123.392l60.928-165.376h2.048z"
                  fill="#777"
                  p-id="1538"
                ></path>
              </svg>
            </div>

            <span>为您推荐:</span>
          </div>
          <div className="first-suggestion-list">
            {glmSuggetion.map((item, index) => {
              return (
                <div
                  className="first-suggestion-item"
                  key={index}
                  onClick={() => onClickGLM3Item(item)}
                >
                  <div className="text-suggest" title={item}>
                    {item}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }
    return null
  }

  return {
    renderGLM3Text,
    getGlmSuggetionLists,
  }
}
