import { useEffect, useRef, useState } from 'react'
import PubSub from 'pubsub-js'

export default function useKeyEvent(props) {
  const { handleSend, question, stopBtnShow } = props
  function onKeyDown(e) {
    if ((e.keyCode === 83 && e.altKey) || (e.keyCode === 13 && e.ctrlKey)) {
      if (!stopBtnShow) {
        fastSend()
      }
    }
  }
  const fastSend = () => {
    if (global.isPicModalShow) {
      //在图片弹窗中
      PubSub.publish('fastSendMsgInPicModal')
    } else {
      handleSend({})
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown) // 添加全局事件
    return () => {
      window.removeEventListener('keydown', onKeyDown) // 销毁
    }
  }, [question, stopBtnShow])

  return {}
}
