import React, { memo, useEffect, useState } from 'react'
import { Input } from 'antd'
import './style.css'
import { verifyPhone } from '@/hooks/verify/verify'

let timerId = null as any

//eslint-disable-next-line max-lines-per-function
const UserName = (props) => {
  const codeDefaultText = '发送验证码'
  const [codeText, setCodeText] = useState(codeDefaultText)

  const { placeholder = '请输入', onChange, getMsgCode, callbackTip, phone, maxLength } = props

  const getSecText = (secs) => {
    return `重新发送${secs}s`
  }

  const startTimer = () => {
    if (codeText != codeDefaultText) {
      return
    }
    timerId && clearInterval(timerId)
    let secs = 59
    setCodeText(getSecText(secs))
    timerId = setInterval(() => {
      if (secs <= 1) {
        clearInterval(timerId)
        setCodeText(codeDefaultText)
        return
      }
      secs--
      setCodeText(getSecText(secs))
    }, 1000)
    getMsgCode()
  }

  const handleClick = () => {
    const tip = verifyPhone(phone)
    if (tip) {
      callbackTip?.(tip)
    } else {
      startTimer()
    }
  }

  useEffect(() => {
    return () => {
      timerId && clearInterval(timerId)
    }
  }, [])

  return (
    <div className="cp-verify-code">
      <Input placeholder={placeholder} onChange={onChange} maxLength={8} />
      <div className="send-code" onClick={handleClick}>
        {codeText}
      </div>
    </div>
  )
}

export default UserName
