import { createStore, combineReducers } from 'redux'
import histroy from './reucers/histroy'
import user from './reucers/user'
import other from './reucers/other'

const appReducer = combineReducers({
  histroy,
  user,
  other,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

const store = createStore(rootReducer)

export default store
