import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Icon, toast } from '@chatui/core'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'

import Recorder from '@/assets/js/recorder'

import './SpeechConversion.css'

const isDev = window.location.hostname == 'localhost'

//eslint-disable-next-line max-lines-per-function
function SpeechConversion(props) {
  const [touching, setTouching] = useState(false)
  const [moveInSpeech, setMoveInSpeech] = useState(true)
  const [moveInEdit, setMoveInEdit] = useState(false)
  const [moveEndSpeech, setMoveEndSpeech] = useState(false)
  const [hasResult, setHasResult] = useState(false)

  const speechAreaRef = useRef(null)
  const editSpeechAreaRef = useRef(null)

  const handleTouchMove = (event) => {
    if (!hasAudioAuth || props.stopBtnShow) {
      return
    }
    const child: any = speechAreaRef.current
    const editChild: any = editSpeechAreaRef.current
    const touch = event.changedTouches[0]
    const x = touch.clientX
    const y = touch.clientY
    const target = document.elementFromPoint(x, y)
    if (child && child.contains(target)) {
      setMoveInSpeech(true)
      setMoveInEdit(false)
    } else if (editChild && editChild.contains(target)) {
      setMoveInSpeech(true)
      setMoveInEdit(true)
    } else {
      setMoveInSpeech(false)
      setMoveInEdit(false)
    }
  }

  const handleTouchStart = (event) => {
    if (props.stopBtnShow) {
      toast.show('请先停止生成再试', 'error')
      return
    }
    if (!hasAudioAuth) {
      toast.show('请检查录音权限', 'error')
      return
    }
    props.initAudio()
    setBdResultStr('')
    setMoveInSpeech(true)
    setMoveEndSpeech(false)
    setMoveInEdit(false)
    setHasResult(false)
    setTouching(true)
    handleStartRecording()
    event.stopPropagation()
    event.preventDefault()
    return false
  }

  const bdResultStrRef = useRef('')
  const handleTouchEnd = (event) => {
    if (!hasAudioAuth || props.stopBtnShow) {
      return
    }
    recorderRef.current.stop()
    recorderStatus.current = 0
    if (moveInEdit) {
      setMoveEndSpeech(true)
      if (bdResultStr) {
        setHasResult(true)
      } else {
        setHasResult(false)
      }
    } else if (moveInSpeech) {
      setTimeout(() => {
        handleSend()
      }, 1000)
    } else {
      setTouching(false)
    }
  }

  const recorderRef: any = useRef(null)
  const recorderStatus = useRef(0) // 0 未开始录音 1 录音中
  const streamRef = useRef(null)
  const wsRef: any = useRef(null)
  const [hasAudioAuth, setAasAudioAuth] = useState(false)

  useEffect(() => {
    getAudioSupport()
  }, [])

  const getAudioAuthSuccess = (stream) => {
    streamRef.current = stream
    setAasAudioAuth(true)
    recorderRef.current = new Recorder(stream, (data) => {
      wsRef.current.send(data)
    })
  }

  const getAudioAuthError = (err) => {
    setAasAudioAuth(false)
    toast.show('请检查录音权限', 'error')
  }

  const getAudioSupport = () => {
    console.log('navigator.mediaDevices', navigator.mediaDevices)
    console.log('navigator.getUserMedia', navigator.getUserMedia)
    console.log('navigator.webkitGetUserMedia', navigator.webkitGetUserMedia)
    console.log('navigator.mozGetUserMedia', navigator.mozGetUserMedia)
    if (
      //@ts-ignore
      (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    ) {
      getAudioAuth({ audio: true }) // 调用用户媒体设备，访问摄像头、录音、
    } else {
      console.log('你的浏览器不支持访问用户媒体设备')
      toast.show('你的浏览器不支持访问用户媒体设备', 'error')
    }
  }

  //eslint-disable-next-line max-lines-per-function
  const getAudioAuth = (constrains) => {
    if (navigator.mediaDevices.getUserMedia) {
      // 最新标准API、
      navigator.mediaDevices
        .getUserMedia(constrains)
        .then((stream) => getAudioAuthSuccess(stream))
        .catch((err) => getAudioAuthError(err))
    } else if (navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
      // webkit内核浏览器
      if (navigator.mediaDevices === undefined) {
        //@ts-ignore
        navigator.mediaDevices = {}
      }

      // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
      // 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先，如果有getUserMedia的话，就获得它
          const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia

          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
          }

          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      navigator.mediaDevices
        .getUserMedia(constrains)
        .then((stream) => {
          getAudioAuthSuccess(stream)
        })
        .catch((err) => {
          getAudioAuthError(err)
        })
    } else if (navigator.getUserMedia) {
      // 旧版API
      navigator
        .getUserMedia(constrains)
        .then((stream) => {
          getAudioAuthSuccess(stream)
        })
        .catch((err) => {
          getAudioAuthError(err)
        })
    }
  }

  const [bdResultStr, setBdResultStr] = useState('')

  const resultInputChange = (val) => {
    setBdResultStr(val)
    bdResultStrRef.current = val
  }

  const [isFocus, setIsFocus] = useState(false)

  const resultInputFocus = () => {
    setIsFocus(true)
  }

  const resultInputBlur = () => {
    setIsFocus(false)
  }

  //eslint-disable-next-line max-lines-per-function
  const handleStartRecording = async () => {
    const resultList: any = []
    let midResult = ''
    // const wsUrl = `wss://vop.baidu.com/realtime_asr?sn="abcd-efg2"`;
    // const wsUrl = `wss://vw.hr-soft.cn/fastdev/ws/audio2text`;
    const wsUrl = isDev
      ? 'ws://10.20.20.66:8085/fastdev/ws/audio2text'
      : 'wss://vw.hr-soft.cn/fastdev/ws/audio2text'
    const ws = new WebSocket(wsUrl)
    wsRef.current = ws
    ws.binaryType = 'arraybuffer'
    ws.onopen = () => {
      // ws.send(JSON.stringify({"data":{"cuid":"self_defined_server_id_like_mac_address","dev_pid":15372,"appid":BAIDU_APP_ID,"format":"pcm","appkey":BAIDU_APP_KEY,"sample":16000},"type":"START"}))
      ws.send(JSON.stringify({ type: 'START' }))
      recorderRef.current.start()
      recorderStatus.current = 1
    }
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.err_no === 0) {
        if (data.type == 'MID_TEXT') {
          midResult = resultList.join('') + data.result
        } else if (data.type == 'FIN_TEXT') {
          midResult = data.result
          resultList.push(data.result)
        }
        const resultStr = resultList.length ? resultList.join('') : midResult
        bdResultStrRef.current = resultStr
        setBdResultStr(resultStr)
      } else {
        console.log('Error:', data.err_no, data.err_msg)
      }
      if (recorderStatus.current == 0) {
        ws.send(JSON.stringify({ type: 'FINISH' }))
        ws.close()
      }
    }
  }

  const handleSend = () => {
    setTouching(false)
    props.onSendMsg(bdResultStrRef.current)
  }

  return (
    <>
      <div
        className="speak-btn"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        按住说话
      </div>
      <div
        className={`speech-conversion-box ${moveInSpeech ? 'move-speech' : 'cancel-speech'} ${
          moveInEdit ? 'edit-speech' : ''
        } ${isFocus ? 'is-focus' : ''}`}
        style={{ display: touching ? 'block' : 'none' }}
      >
        <div className={`result-area ${!hasResult && moveEndSpeech ? 'no-result' : ''}`}>
          {!hasResult && moveEndSpeech ? (
            <div className="no-result-tips">
              <Icon type="warning-circle-fill" />
              未识别到文字
            </div>
          ) : (
            <Input
              autoSize
              className="result-area-text"
              value={bdResultStr}
              onChange={resultInputChange}
              onFocus={resultInputFocus}
              onBlur={resultInputBlur}
            />
          )}
          {!moveEndSpeech && (
            <span className="start-taste-line">
              <hr className="hr1" />
              <hr className="hr2" />
              <hr className="hr3" />
              <hr className="hr4" />
              <hr className="hr5" />
              <hr className="hr6" />
              <hr className="hr7" />
              <hr className="hr8" />
              <hr className="hr9" />
              <hr className="hr10" />
            </span>
          )}
        </div>
        <div className="btns-area">
          {moveEndSpeech ? (
            <>
              <div className="speech-cancel-btn">
                <Icon type="cancel" onClick={() => setTouching(false)} />
                <div>取消</div>
              </div>
              {bdResultStr ? (
                <div className="speech-check-btn" onClick={handleSend}>
                  <Icon type="check" />
                </div>
              ) : (
                <div className="speech-check-btn disabled">
                  <Icon type="check" />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="speech-btn-box">
                <div className="speech-close-btn">
                  <div className="speech-close-text" onClick={() => setTouching(false)}>
                    松开取消
                  </div>
                  <CloseOutlined />
                </div>
              </div>
              <div className="speech-send-text">松开发送</div>
              <div className="speech-btn-box speech-edit-btn-box" ref={editSpeechAreaRef}>
                <div className={`speech-edit-btn ${moveInEdit ? 'active' : ''}`}>
                  <div className="speech-edit-text" onClick={() => setTouching(false)}>
                    编辑文字
                  </div>
                  <EditOutlined />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          ref={speechAreaRef}
          className="speech-area"
          style={{ display: moveEndSpeech ? 'none' : 'block' }}
        >
          <div className="bullhorn-icon"></div>
        </div>
      </div>
    </>
  )
}

export default SpeechConversion
