import React, { useState, useEffect, useRef } from 'react'
import { register, smsSend } from '@/apis/login/login'

import {
  verifyRegisterStep1,
  verifyRegisterRowInput,
  verifyPhone,
  verifyBind,
  verifyUniBind,
  verifyPassword,
  verifyPwdSame,
  verifyNewPassword,
  verifyNewConfirmPassword,
  verifyInputCurrPassword,
} from './verify'

//eslint-disable-next-line max-lines-per-function
export default function useVerifyUserSetting() {
  const [avatar, setAvatar] = useState('')
  const [username, setUsername] = useState('')
  const [nickname, setNickname] = useState('')
  const [currPassword, setCurrPassword] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const inputDataRef = useRef({
    avatar: '',
    username: '',
    nickname: '',
    currPassword: '',
    password: '',
    passwordConfirm: '',
  })
  const [errTip, setErrTip] = useState('')

  const verifyInputOk = () => {
    const { avatar, nickname, currPassword, password, passwordConfirm } = inputDataRef.current
    let tip = verifyInputCurrPassword(currPassword, password, passwordConfirm)
    if (!tip) {
      tip = verifyNewPassword(password, passwordConfirm)
    }
    if (!tip) {
      tip = verifyNewConfirmPassword(password, passwordConfirm)
    }
    if (!tip) {
      tip = verifyPwdSame(password, passwordConfirm)
    }

    if (tip) {
      setErrTip(tip)
      return false
    }
    setErrTip('')
    return true
  }

  const onChangeAvatar = (e) => {
    const avatar = e.target.value
    inputDataRef.current.avatar = avatar
    setAvatar(avatar)
  }

  const onChangeNickName = (e) => {
    const value = e.target.value
    inputDataRef.current.nickname = value
    setNickname(value)
  }

  const onChangeCurrPassword = (e) => {
    const value = e.target.value
    inputDataRef.current.currPassword = value
    setCurrPassword(value)
  }

  const onChangePassword = (e) => {
    const value = e.target.value
    inputDataRef.current.password = value
    setPassword(value)
  }

  const onChangePasswordConfirm = (e) => {
    const value = e.target.value
    inputDataRef.current.passwordConfirm = value
    setPasswordConfirm(value)
  }

  const setUserFromApi = (userInfo) => {
    const { avatar, username, nickname } = userInfo
    setAvatar(avatar)
    setNickname(nickname)
    setUsername(username)
    inputDataRef.current.avatar = avatar
    inputDataRef.current.username = username
    inputDataRef.current.nickname = nickname
  }

  return {
    onChangeAvatar,
    onChangeNickName,
    onChangeCurrPassword,
    onChangePassword,
    onChangePasswordConfirm,
    inputDataRef,
    errTip,
    verifyInputOk,
    avatar,
    username,
    nickname,
    setUserFromApi,
    setAvatar,
  }
}
